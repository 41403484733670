export const getTimeToClose = (currentOpenTime, currentCloseTime) => {
  const now = new Date();

  // Convert time strings (hh:mm:ss) to Date objects
  const [openHour, openMinute] = currentOpenTime.split(":").map(Number);
  const [closeHour, closeMinute] = currentCloseTime.split(":").map(Number);

  // Set open and close times based on current date
  const openDate = new Date(now.setHours(openHour, openMinute, 0, 0)); // Today's open time
  const closeDate = new Date(now.setHours(closeHour, closeMinute, 0, 0)); // Today's close time

  // If current close time is earlier than open time, we assume it closes on the next day
  if (closeDate <= openDate) {
    closeDate.setDate(closeDate.getDate() + 1); // Move close time to the next day
  }

  // Calculate the duration until close time
  const timeDifference = closeDate - new Date();

  if (timeDifference <= 0) {
    //return "Store is already closed";
    return "";
  }

  // Calculate hours and minutes until close
  const hours = Math.floor(timeDifference / 1000 / 60 / 60);
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / 1000 / 60);

  return hours > 1 ? `${hours}H` : `${minutes}m`;
};

/* // Test with example times
const currentOpenTime = "02:34:00";
const currentCloseTime = "00:34:00";
const result = getTimeToClose(currentOpenTime, currentCloseTime);

console.log(result); // Outputs the time remaining until the store closes
 */
