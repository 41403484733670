import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useGetAllCategoriesQuery } from "../../auth/services/categoryService";
import { useGetSubCategoriesQuery } from "../../auth/services/subcategoryService";

const SearchFilter = ({ onFilterChange, estoreId }) => {
  const [name, setname] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);

  const { data: categories, isLoading: categoriesLoading } =
    useGetAllCategoriesQuery();
  const { data: subCategories, isLoading: subCategoriesLoading } =
    useGetSubCategoriesQuery(
      selectedCategory?.categoryId
        ? { categoryId: selectedCategory.categoryId }
        : null,
      { skip: !selectedCategory?.categoryId }
    );

  // Handle category selection
  const handleCategoryChange = (e) => {
    const category = categories?.find(
      (cat) => parseInt(e.target.value) === parseInt(cat.categoryId)
    );
    setSelectedCategory(category || null);
    setSelectedSubcategory(null); // Reset subcategory
  };
  //http://localhost:8080/api/v1/public/auth/product/estore/103?categoryId=1&subcategoryId=56
  // Handle subcategory selection
  const handleSubcategoryChange = (e) => {
    const subcategory = subCategories?.find(
      (sub) => parseInt(sub.subCategoryId) === parseInt(e.target.value)
    );
    setSelectedSubcategory(subcategory || null);
  };

  // Notify parent component when filters change
  useEffect(() => {
    onFilterChange({
      name,
      categoryId: selectedCategory?.categoryId || undefined,
      subcategoryId: selectedSubcategory?.subCategoryId || undefined,
      estoreId,
    });
  }, [name, selectedCategory, selectedSubcategory, estoreId, onFilterChange]);

  if (categoriesLoading || subCategoriesLoading)
    return <p>Loading filters...</p>;

  return (
    <div className="flex items-center space-x-2 flex-wrap">
      {/* Search Input */}
      <input
        type="text"
        className="border p-2 mr-2 w-40"
        placeholder="Search by Product Name..."
        value={name}
        onChange={(e) => setname(e.target.value)}
      />

      {/* Category Select */}
      <select className="border p-2 mr-2" onChange={handleCategoryChange}>
        <option value="">All Categories</option>
        {categories?.map((category) => (
          <option key={category.categoryId} value={category.categoryId}>
            {category.name}
          </option>
        ))}
      </select>

      {/* Subcategory Select */}
      <select
        className="border p-2 mr-2"
        onChange={handleSubcategoryChange}
        disabled={!selectedCategory}
      >
        <option value="">All Subcategories</option>
        {subCategories?.map((subcategory) => (
          <option
            key={subcategory.subCategoryId}
            value={subcategory.subCategoryId}
          >
            {subcategory.name}
          </option>
        ))}
      </select>

      {/* Search Button */}
      <button
        className="custom-button"
        onClick={() =>
          onFilterChange({
            name,
            categoryId: selectedCategory?.categoryId,
            subcategoryId: selectedSubcategory?.subCategoryId,
            estoreId,
          })
        }
      >
        <FontAwesomeIcon icon={faSearch} />
      </button>
    </div>
  );
};

export default SearchFilter;
