import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { FaEye, FaEyeSlash, FaEdit, FaBoxOpen } from "react-icons/fa";
import { useLazyGetAllFoodItemsAndProductsQuery } from "../../../auth/services/foodItemService";
import "./index.css";
import SearchFilter from "../../utils/SearchFilter";
import { useSelector } from "react-redux";
import { decodeToken } from "../../../utils/decodeToken";

const ProductList = () => {
  const token = useSelector((state) => state.auth.userToken);
  const estoreId = decodeToken(token).store;
  const id = estoreId;
  const [filterOptions, setFilterOptions] = useState({
    name: "",
    categoryId: undefined,
    subcategoryId: undefined,
    estoreId: estoreId || id, // Optional estoreId
  });

  const [fetchAllItems, { data, error }] =
    useLazyGetAllFoodItemsAndProductsQuery({ id });

  // Fetch products when filters change
  const fetchProducts = useCallback(() => {
    //alert(filterOptions?.name);
    fetchAllItems(filterOptions);
  }, [filterOptions, fetchAllItems]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  if (error) return <p>Error loading data: {error.message}</p>;

  return (
    <div className="product-list-wrapper shadow mx-1 px-1 bg-light rounded-3 w-100">
      {/* Search Filter Component */}
      <SearchFilter onFilterChange={setFilterOptions} estoreId={estoreId} />

      {/* Product List */}
      <div className="product-container">
        <ul className="product-list">
          {[...(data?.foodItems || []), ...(data?.products || [])].map(
            (item, index) => (
              <li
                key={index}
                className="product-item d-flex align-items-center justify-content-between p-2 border-bottom"
              >
                <Link to={`product/${item.foodItemId || item.productId}`}>
                  <img
                    src={item.image}
                    alt={item.name}
                    className="product-img"
                  />
                </Link>
                <div className="product-details flex-grow-1 px-2">
                  <Link
                    to={`product/${item.foodItemId || item.productId}`}
                    className="product-name"
                  >
                    {item.name}
                  </Link>
                  <div className="text-muted">${item.price?.basePrice}</div>
                  <div>{item?.subCategory?.name}</div>
                </div>
                <div className="product-actions d-flex">
                  <span
                    className={`visibility-icon text-${
                      item.visibility?.toLowerCase() === "public"
                        ? "green"
                        : "orange"
                    }`}
                  >
                    {item.visibility?.toLowerCase() === "public" ? (
                      <FaEye />
                    ) : (
                      <FaEyeSlash />
                    )}
                  </span>
                  <Link
                    to={`product/edit${
                      item.foodItemId ? "fooditem" : "product"
                    }/${item.foodItemId || item.productId}`}
                    className="px-2"
                  >
                    <FaEdit className="text-primary" />
                  </Link>
                  {item.visibility?.toLowerCase() === "private" && (
                    <FaBoxOpen className="text-danger" />
                  )}
                </div>
              </li>
            )
          )}
        </ul>
      </div>
    </div>
  );
};

export default ProductList;
