import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetSubCategoriesQuery,
  useDeleteSubCategoryMutation,
} from "../../../auth/services/subcategoryService"; // Update the path according to where your API slice is located

const SubCategory = () => {
  const { categoryId } = useParams();
  const navigate = useNavigate();

  // Query to get subcategories for the category
  const {
    data: subCategories,
    error,
    isLoading,
  } = useGetSubCategoriesQuery({ categoryId }, { skip: !categoryId });

  // Mutation hook to delete a subcategory
  const [deleteSubCategory] = useDeleteSubCategoryMutation();

  // Error handling
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (error) {
      setErrorMessage("Error fetching subcategories");
    }
  }, [error]);

  const handleDelete = async (id) => {
    try {
      await deleteSubCategory({ id }).unwrap(); // Using unwrap for success/error handling
    } catch (err) {
      setErrorMessage("Error deleting subcategory");
    }
  };

  if (isLoading) return <p>Loading...</p>;

  return (
    <div>
      <h1>SubCategory Management </h1>
      <button
        onClick={() =>
          navigate(`/dashboard/product/categories/subcategories/new`)
        }
      >
        Add New SubCategory
      </button>
      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {subCategories &&
            subCategories.map((subCategory, idx) => (
              <tr key={subCategory.subCategoryId}>
                <td>{idx + 1}</td>
                <td>{subCategory.name}</td>
                <td className="action-column">
                  <button
                    onClick={() =>
                      navigate(
                        `/dashboard/product/categories/${categoryId}/subcategories/${subCategory.subCategoryId}`
                      )
                    }
                  >
                    Edit
                  </button>
                  <button onClick={() => handleDelete(subCategory.id)}>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default SubCategory;
