// estoreSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiClient from "../../utils/apiClient"; // Assuming apiClient is set up correctly
import { getCookie } from "../../utils/cookie";
import { decodeTokenStore } from "../../utils/decodeToken";
//http://localhost:8080/api/v1/public/auth/estore/null
//http://localhost:8080/api/v1/public/auth/user/estore

const storeId = decodeTokenStore(getCookie("userToken")?.userTokens)?.store;
export const fetchEstoreList = createAsyncThunk(
  "auth/estoreProfileList",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.get("/user/estore");
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const fetchEstoreById = createAsyncThunk(
  "auth/estoreProfileById",
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.get(`/estore/${storeId || id}`);
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const updateEstore = createAsyncThunk(
  "auth/estoreUpdate",
  async (estoreRequest, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.put("/estore", estoreRequest);
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const updateEstoreLogo = createAsyncThunk(
  "auth/estoreUpdateLogo",
  async ({ id, file }, { rejectWithValue }) => {
    if (!file) {
      return rejectWithValue("No file provided for uploading.");
    }
    try {
      const formData = new FormData();
      formData.append("file", file);
      const { data } = await apiClient.patch(
        `/estore/${storeId || id}/logo`,
        formData
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const createEstore = createAsyncThunk(
  "auth/estoreCreate",
  async (estoreRequest, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.post("/estore", estoreRequest);
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const initialState = {
  isLoading: false,
  isSuccess: false,
  estore: {},
  estoreList: [],
  error: null,
};

const estoreSlice = createSlice({
  name: "estore",
  initialState,
  reducers: {
    resetEstore: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.estore = {};
      state.estoreList = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEstoreList.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.error = null;
      })
      .addCase(fetchEstoreList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.estoreList = action.payload;
      })
      .addCase(fetchEstoreList.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.error = action.payload;
      })
      .addCase(fetchEstoreById.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.error = null;
      })
      .addCase(fetchEstoreById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.estore = action.payload;
      })
      .addCase(fetchEstoreById.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.error = action.payload;
      })
      .addCase(updateEstore.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.error = null;
      })
      .addCase(updateEstore.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const index = state.estoreList.findIndex(
          (estore) => estore.estoreId === action.payload.estoreId
        );
        if (index !== -1) {
          state.estoreList[index] = action.payload;
        }
      })
      .addCase(updateEstore.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.error = action.payload;
      })
      .addCase(createEstore.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.error = null;
      })
      .addCase(createEstore.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.estore = action.payload;
        state.estoreList.push(action.payload);
      })
      .addCase(createEstore.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.error = action.payload;
      });
  },
});

export const { resetEstore } = estoreSlice.actions;
export default estoreSlice.reducer;
