import React, { useEffect, useState } from "react";
import {
  useGetAllergyInfoQuery,
  useUpdateAllergyDetailsMutation,
} from "../../auth/services/allergyService";
import { useParams } from "react-router-dom";
const EditAllergy = () => {
  const { id } = useParams(); //use params
  const { data: allergy, isLoading: isFetching } = useGetAllergyInfoQuery(id);
  const [updateAllergy, { isLoading, error }] =
    useUpdateAllergyDetailsMutation();
  const [formData, setFormData] = useState({ name: "", description: "" });

  useEffect(() => {
    if (allergy) {
      setFormData({ name: allergy.name, description: allergy.description });
    }
  }, [allergy]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateAllergy({ id, data: formData }).unwrap();
      // alert("Allergy updated successfully!");
    } catch (err) {
      console.error("Error updating allergy:", err);
    }
  };

  if (isFetching) return <p>Loading allergy details...</p>;

  return (
    <form onSubmit={handleSubmit}>
      <h2>Edit Allergy{id}</h2>
      <input
        type="text"
        name="name"
        value={formData.name}
        onChange={handleChange}
        placeholder="Allergy Name"
        required
      />
      <input
        type="text"
        name="description"
        value={formData.description}
        onChange={handleChange}
        placeholder="Description"
        required
      />
      <button type="submit" disabled={isLoading}>
        {isLoading ? "Updating..." : "Update Allergy"}
      </button>
      {error && <p>Error: {error.message}</p>}
    </form>
  );
};

export default EditAllergy;
