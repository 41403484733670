export function updateCustomizationMap(
  customizationMap,
  category,
  items,
  updates
) {
  // Iterate over the list of items
  items.forEach((item) => {
    if (customizationMap.has(item)) {
      const currentItemDetails = customizationMap.get(item);

      // Check if the category matches
      if (currentItemDetails.category === category) {
        // Update only the fields provided in the `updates` object
        if (updates.qty !== undefined && updates.qty !== null) {
          currentItemDetails.qty = updates.qty.toString(); // Update quantity
        }
        if (updates.side !== undefined && updates.side !== null) {
          currentItemDetails.side = updates.side; // Update side
        }
      }
    } else {
      // If item doesn't exist, add it to the map with defaults or updates
      const itemDetails = {
        category: category,
        qty: updates.qty ? updates.qty.toString() : "none", // Default or updated qty
        side: updates.side !== undefined ? updates.side : false, // Default or updated side
      };
      customizationMap.set(item, itemDetails);
    }
  });

  return customizationMap;
}
