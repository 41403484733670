import React, { useState } from "react";
import "./index.css";

const CheckboxWithRadio = ({
  label,
  initialQty = "norm",
  initialSide = false,
  onRadioChange,
  onCheckboxChange,
}) => {
  const [isChecked, setIsChecked] = useState(initialSide);
  const [selectedRadio, setSelectedRadio] = useState(initialQty);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
    onCheckboxChange(e.target.checked); // Notify parent of checkbox state
  };

  const handleRadioChange = (e) => {
    setSelectedRadio(e.target.value);
    onRadioChange(e.target.value); // Notify parent of selected radio value
  };
  return (
    <div className="checkbox-with-radio">
      {/* <h4>{label}</h4> */}
      <div className="radio-options">
        {["none", "a bit", "norm", "extra"].map((value) => (
          <label key={value}>
            <input
              type="radio"
              name={`options-${label}`}
              value={value}
              checked={selectedRadio === value}
              onChange={handleRadioChange}
            />
            {value}
          </label>
        ))}
      </div>
      <div className="checkbox-container">
        <label>
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          side
        </label>
      </div>
    </div>
  );
};

export default CheckboxWithRadio;
