import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  useGetSpecialEventsByEstoreIdQuery,
  useCreateSpecialEventMutation,
  useUpdateSpecialEventMutation,
  useDeleteSpecialEventMutation,
} from "../../../auth/services/specialEventService";

import SpecialEventForm from "../SpecialEventForm";
import { SpecialEventList } from "../SpecialEventList";
import SpecialEventFormEdit from "../SpecialEventFormEdit";
import "./index.css";
import { useNavigate } from "react-router-dom";
const SpecialEvents = () => {
  const navigate = useNavigate();
  const estoreId = useSelector((state) => state.auth.userInfo.defaultEstore);
  const [form, setForm] = useState({
    id: null,
    name: "",
    description: "",
    eventDate: "",
    startTime: "",
    endTime: "",
    price: {
      basePrice: "",
      discount: "",
      currency: "",
    },
    image: "",
    estoreId,
  });

  // Update form when `estoreId` changes
  useEffect(() => {
    setForm((prev) => ({ ...prev, estoreId }));
  }, [estoreId]);

  const {
    data: specialEvents = [],
    refetch,
    isLoading,
  } = useGetSpecialEventsByEstoreIdQuery();

  const [createSpecialEvent] = useCreateSpecialEventMutation();
  const [updateSpecialEvent] = useUpdateSpecialEventMutation();
  const [deleteSpecialEvent] = useDeleteSpecialEventMutation();

  const handleSubmit = async (form) => {
    try {
      if (form.id) {
        await updateSpecialEvent({
          id: form.id,
          specialEventRequest: form,
        }).unwrap();
      } else {
        await createSpecialEvent(form).unwrap();
      }
      setForm({
        id: null,
        name: "",
        description: "",
        eventDate: "",
        startTime: "",
        endTime: "",
        price: { basePrice: "", discount: "", currency: "" },
        image: "",
        estoreId: "",
      });
      refetch(); // Refresh the data after any updates
    } catch (error) {
      console.error("Error saving special event:", error);
    }
  };

  const handleEdit = (event) => {
    setForm({
      id: event.id,
      name: event.name,
      description: event.description,
      eventDate: event.eventDate,
      startTime: event.startTime || "",
      endTime: event.endTime || "",
      price: {
        basePrice: event.price?.basePrice || "",
        discount: event.price?.discount || "",
        currency: event.price?.currency || "",
      },
      image: event.image || "",
      estoreId: event.estoreId,
    });
  };
  const handleView = async (id) => {
    navigate(`${id}`);
  };
  const handleDelete = async (id) => {
    try {
      await deleteSpecialEvent({ id }).unwrap();
      refetch(); // Refresh the data after deletion
    } catch (error) {
      console.error("Error deleting special event:", error);
    }
  };

  // Function to display special events in an alert
  const showAlertWithEvents = () => {
    if (specialEvents.length === 0) {
      //alert("No special events available.");
      return;
    }

    const formattedEvents = specialEvents
      .map(
        (event, index) =>
          `${index + 1}. ${event.name} - ${event.description} on ${
            event.eventDate
          }`
      )
      .join("\n");

    //alert(`Special Events:\n\n${formattedEvents}`);
  };

  if (isLoading) return <p>Loading special events...</p>;

  return (
    <div>
      <h2>{form.id ? "Edit Special Event" : "Add New Special Event"}</h2>
      {!form.id ? (
        <SpecialEventForm form={form} onSubmit={handleSubmit} />
      ) : (
        <SpecialEventFormEdit
          form={form}
          onSubmit={handleSubmit}
          setForm={setForm}
        />
      )}
      {/* <button className="btn btn-info mb-3" onClick={showAlertWithEvents}>
        Show All Events
      </button> */}
      <SpecialEventList
        events={specialEvents}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onView={handleView}
      />
    </div>
  );
};

export default SpecialEvents;
