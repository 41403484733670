import React, { useEffect } from "react";
import Header from "../Header";
import Products from "../Products";
import ProductList from "../ProductList";
import "./index.css";
import TestSchedule from "../TestSchedule";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../../../redux/auth/userSlice";
// Assume these actions are defined in userSlice
import { useGetUserDetailsQuery } from "../../../auth/services/authService";
import { setCredentials } from "../../../redux/auth/authSlice";

const productDetails = [
  { id: 1, total: 2, text: "Out of Stock", color: "orange" },
  { id: 2, total: 5, text: "Private View", color: "green" },
  { id: 3, total: 10, text: "UnAvailable Items", color: "blue" },
];
//http://localhost:8080/api/v1/public/auth/estore/undefined

const Body = () => {
  const dispatch = useDispatch();
  const { userInfo, userToken } = useSelector((state) => state.auth);
  const { data, isFetching } = useGetUserDetailsQuery(undefined, {
    skip: !userToken,
    // perform a refetch every 15mins
    pollingInterval: 900000,
  });

  // Load user info on component mount
  useEffect(() => {
    dispatch(getCurrentUser); // Fetch user info by token
  }, [dispatch, userInfo, userToken]);
  useEffect(() => {
    if (data) dispatch(setCredentials(data));
  }, [data, dispatch]);
  if (isFetching) return <div>Loading...</div>;
  return (
    <div className="body-wrapper">
      <Header />
      <h4 className="mx-3 d-block">
        Welcome Back, {userInfo?.userProfile?.firstName}
      </h4>
      <div className="d-container products-container">
        <ProductList />
      </div>
    </div>
  );
};

export default Body;
