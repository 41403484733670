import { FaBars } from "react-icons/fa";
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";
export const Nav = styled.nav`
  background: #ffff;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.2rem calc((100vw - 1000px) / 2);
  z-index: 20;
  position: sticky;
  top: 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  opacity: 1;
`;

export const NavLink = styled(Link)`
  color: #808080;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 10px 1rem;
  width: auto;
  justify-content: center;
  cursor: pointer;
  font-size: 1rem; /* Default font size for larger screens */

  &.active {
    color: #000000;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column; /* Stack icon and text vertically if needed */
    font-size: 0.75rem; /* Smaller font size for smaller screens */
  }

  .icon {
    font-size: 1.5rem; /* Icon size */
    margin-right: 8px; /* Space between icon and text for larger screens */
  }

  .text {
    display: block;

    @media screen and (max-width: 768px) {
      display: none; /* Hide text on smaller screens */
    }
  }
`;

export const Bars = styled(FaBars)`
  display: none;
  color: #808080;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 15px;
    right: 20px;
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: flex-start;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9); /* Translucent background */
    gap: 14px; /* Add vertical spacing between items */
    padding: 10px 0; /* Add padding to the menu for better spacing */
  }
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 24px;

  @media screen and (max-width: 768px) {
    margin: 0;
    width: 100%;
    justify-content: center;
  }
`;

export const NavBtnLink = styled(Link)`
  border-radius: 4px;
  background: #ffff;
  padding: 10px 22px;
  color: #808080;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: #f7f7f7;
    color: #606060;
  }
`;
