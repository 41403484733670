import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import "./index.css";

const Header = () => {
  return (
    <div className="header-container">
      <div className="container-fluid bg-light"></div>
    </div>
  );
};

export default Header;
