import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCreateProductDetailsMutation } from "../../../auth/services/productService"; // Adjust the path if needed
import { useGetSubCategoriesQuery } from "../../../auth/services/subcategoryService"; // Adjust the path if needed
import { useGetAllCategoriesQuery } from "../../../auth/services/categoryService";
import "./index.css";

const AddProduct = () => {
  const navigate = useNavigate();
  const estoreId = useSelector((state) => state.auth.userInfo.defaultEstore);

  // use the mutation hook for creating product
  const [createProductDetails] = useCreateProductDetailsMutation();

  // Fetch categories from backend
  const { data: categories = [], isLoading: loadingCategories } =
    useGetAllCategoriesQuery();
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  // Fetch subcategories based on selected category
  const { data: subcategories = [], isLoading: loadingSubcategories } =
    useGetSubCategoriesQuery(
      {
        categoryId: selectedCategoryId
          ? parseInt(selectedCategoryId)
          : undefined,
      },
      { skip: !selectedCategoryId }
    );

  // State to capture form data
  const [productData, setProductData] = useState({
    name: "",
    description: "", // Optional field
    category: "",
    subCategoryId: null, // Stores selected subcategory ID
    calories: 0,
    price: {
      basePrice: 0,
      discount: 0.0,
      currency: "USD",
    },
    estoreId,
  });

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "basePrice") {
      setProductData((prevData) => ({
        ...prevData,
        price: { ...prevData.price, basePrice: parseFloat(value) }, // Ensure it's a number
      }));
    } else if (name === "discount") {
      setProductData((prevData) => ({
        ...prevData,
        price: { ...prevData.price, discount: parseFloat(value) }, // Ensure it's a number
      }));
    } else if (name === "calories") {
      setProductData((prevData) => ({
        ...prevData,
        calories: parseFloat(value), // Ensure it's a number
      }));
    } else if (name === "subCategoryId") {
      setProductData((prevData) => ({
        ...prevData,
        subCategoryId: parseInt(value),
      }));
    } else {
      setProductData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleCategoryChange = (e) => {
    const categoryId = parseInt(e.target.value);
    setSelectedCategoryId(categoryId);
    setProductData((prevData) => ({
      ...prevData,
      category: categoryId,
      subCategoryId: null, // Reset subcategory when category changes
    }));
  };

  const handleBackClick = () => {
    navigate("/dashboard");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Validate and make API call to create product
      alert(JSON.stringify(productData));
      await createProductDetails(productData).unwrap();
      console.log("Product added successfully!");
      navigate("/dashboard"); // Redirect after adding
    } catch (error) {
      console.error("Error adding product:", error);
    }
  };

  return (
    <div className="add-product-container">
      <button className="btn btn-secondary mb-3" onClick={handleBackClick}>
        Back to Dashboard
      </button>

      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="productCategory">Product Category</label>
          <select
            id="productCategory"
            className="form-control"
            value={productData.category || ""}
            onChange={handleCategoryChange}
            required
          >
            <option value="">Select Category</option>
            {loadingCategories ? (
              <option disabled>Loading...</option>
            ) : (
              categories.map((cat) => (
                <option key={cat.categoryId} value={cat.categoryId}>
                  {cat.name}
                </option>
              ))
            )}
          </select>
        </div>
        {selectedCategoryId && (
          <div className="form-group">
            <label htmlFor="productSubcategory">Product Subcategory</label>
            <select
              id="productSubcategory"
              className="form-control"
              name="subCategoryId"
              value={productData.subCategoryId || ""}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Sub Category</option>
              {loadingSubcategories ? (
                <option disabled>Loading...</option>
              ) : (
                subcategories.map((subcat) => (
                  <option
                    key={subcat.subCategoryId}
                    value={subcat.subCategoryId}
                  >
                    {subcat.name}
                  </option>
                ))
              )}
            </select>
          </div>
        )}
        <div className="form-group">
          <label htmlFor="productName">Product Name</label>
          <input
            type="text"
            id="productName"
            className="form-control"
            name="name"
            value={productData.name}
            onChange={handleInputChange}
            aria-labelledby="productNameLabel"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="productDescription">Product Description</label>
          <textarea
            id="productDescription"
            className="form-control"
            rows="3"
            name="description"
            value={productData.description}
            onChange={handleInputChange}
          ></textarea>
        </div>
        <div className="form-group">
          <label htmlFor="calories">Product Calories</label>
          <input
            id="calories"
            type="number"
            className="form-control"
            name="calories"
            value={productData.calories}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="basePrice">Product Price</label>
          <input
            id="basePrice"
            type="number"
            className="form-control"
            name="basePrice"
            value={productData.price.basePrice}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="discount">Discount</label>
          <input
            id="discount"
            type="number"
            className="form-control"
            name="discount"
            value={productData.price.discount}
            onChange={handleInputChange}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Add Product
        </button>
      </form>
    </div>
  );
};

export default AddProduct;
