import React, { useState, useEffect, useCallback, useMemo } from "react";
import { getAllFoodItemsAndProducts } from "../auth/services/homeService"; // Assuming you have a product API service
import { useDispatch, useSelector } from "react-redux";
import { addToProductCart } from "../redux/auth/productCartSlice"; // Import cart actions
import { addToFoodItemCart } from "../redux/auth/foodItemCartSlice"; // Import cart actions
import "./index.css";
import FoodItem from "./components/FoodItem";
import Product from "./components/Product";
import SpecialEvent from "./components/SpecialEvent";
import ErrorDisplay from "./components/ErrorDisplay";
import SearchBar from "../components/utils/SearchBar";
const Home = () => {
  const dispatch = useDispatch();
  const { foodItemCart } = useSelector((state) => state.foodItemCart);
  const { productCart } = useSelector((state) => state.productCart);

  // State for combined data and loading/error management
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [combinedData, setCombinedData] = useState({
    foodItems: [],
    products: [],
    specialEvents: [],
  });
  useEffect(() => {
    // Fetch data from the API
    getAllFoodItemsAndProducts(null)
      .then((response) => {
        if (response.error) {
          setError(response.error);
        } else {
          //console.log("API Response:", response.data.foodItems.content[0].name); // Debug API response
          setCombinedData(response.data);
        }
      })
      .catch((err) => setError(err.message))
      .finally(() => setLoading(false));
  }, []);
  // Helper function to check if the product is already in the cart
  const isItemInFoodItemCart = (productId) => {
    return foodItemCart.some((cartItem) => cartItem.foodItemId === productId);
  };
  // Helper function to check if the product is already in the cart
  const isItemInProductCart = (productId) => {
    return productCart.some((cartItem) => cartItem.productId === productId);
  };
  // Add product foodItem to cart handler
  const handleAddToFoodItemCart = (product) => {
    dispatch(addToFoodItemCart(product)); // Dispatch the action to add product to cart
  };
  // Add product to cart handler
  const handleAddToProductCart = (product) => {
    dispatch(addToProductCart(product)); // Dispatch the action to add product to cart
  };
  if (loading) return <div>Loading products...</div>;
  if (error) return <ErrorDisplay error={error} />;
  if (
    !combinedData ||
    (combinedData?.foodItems?.content.length === 0 &&
      combinedData?.products?.content.length === 0)
  ) {
    return <div>No products available.</div>;
  }
  return (
    <div className="product-container">
      <div className="flex items-center space-x-2 w-full">
        <SearchBar />
      </div>

      {combinedData?.foodItems?.content?.map((foodItem) => (
        <FoodItem
          key={`${foodItem.foodItemId}-${foodItem.name}`}
          foodItem={foodItem}
          handleAddToFoodItemCart={handleAddToFoodItemCart}
          isItemInFoodItemCart={isItemInFoodItemCart}
        />
      ))}
      {combinedData?.products?.content?.map((product) => (
        <Product
          key={`${product.productId}-${product.name}`}
          product={product}
          handleAddToProductCart={handleAddToProductCart}
          isItemInProductCart={isItemInProductCart}
        />
      ))}
      {combinedData?.specialEvents?.content?.map((event, idx) => (
        <SpecialEvent
          key={`${event?.specialEventId || idx + Date.now}-${event.name}`}
          event={event}
        />
      ))}
    </div>
  );
};

export default Home;
