import React, { useState, useEffect } from "react";

const SpecialEventForm = ({ form, onSubmit }) => {
  const [localForm, setLocalForm] = useState(form);

  // Update localForm when the form prop changes
  useEffect(() => {
    setLocalForm(form);
  }, [form]);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLocalForm({ ...localForm, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(localForm); // Pass the local form state to the parent onSubmit
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label>Name</label>
        <input
          type="text"
          name="name"
          value={localForm.name}
          onChange={handleInputChange}
          className="form-control"
          required
        />
      </div>
      <div className="form-group">
        <label>Description</label>
        <textarea
          name="description"
          value={localForm.description}
          onChange={handleInputChange}
          className="form-control"
          required
        ></textarea>
      </div>
      <div className="form-group">
        <label>Date</label>
        <input
          type="date"
          name="eventDate"
          value={localForm.eventDate}
          onChange={handleInputChange}
          className="form-control"
          required
        />
      </div>
      <button type="submit" className="btn btn-primary">
        {localForm.id ? "Update Event" : "Add Event"}
      </button>
    </form>
  );
};

export default SpecialEventForm;
