// apiClient.js
import axios from "axios";
import { refreshAccessToken, isTokenExpired } from "./decodeToken";
import { baseURL } from "../components/utils/constants";
import { getCookie, setCookieJson } from "./cookie";

const apiClient = axios.create({
  baseURL: `${baseURL}`, // Set your base API URL here
});
//http://localhost:8080/api/v1/public/auth/estore

// Axios request interceptor to handle token refresh logic
apiClient.interceptors.request.use(
  async (config) => {
    const userToken = getCookie("userToken");
    /* console.log("🔍 Cookie Data:", userToken); */

    const token = JSON.parse(userToken)?.userTokens;
    const refreshToken = JSON.parse(userToken)?.refreshToken; // Fixed typo

    /* console.log("📌 Extracted Token:", token);
    console.log("📌 Extracted Refresh Token:", refreshToken); */

    if (token && isTokenExpired(token) && refreshToken) {
      /* console.log("🔄 Token expired. Refreshing..."); */
      const newToken = await refreshAccessToken(refreshToken);

      setCookieJson("userToken", {
        userTokens: newToken.accessToken,
        refreshToken: newToken.refreshToken,
      });

      config.headers.Authorization = `Bearer ${newToken.accessToken}`;
      /* console.log("✅ New Token Set:", newToken.accessToken); */
    } else if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      /* console.log("✅ Using existing token."); */
    } else {
      console.warn(
        "⚠️ No token available. Request will be sent without Authorization."
      );
    }

    /* console.log("📡 Final Request Headers:", config.headers); */
    return config;
  },
  (error) => {
    /* console.error("❌ Interceptor Error:", error); */
    return Promise.reject(error);
  }
);

export default apiClient;
