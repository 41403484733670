import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages";
import Login from "./pages/Login";
import SignUp from "./pages/Signup";
import Footer from "./components/Footer";
import UserProfile from "./pages/user/UserProfile";
import ProfileScreen from "./pages/user/ProfileScreen";
import ProfileEditScreen from "./pages/user/ProfileEditScreen";
import Profile from "./pages/Profile";
import Dashboard from "./components/Dashboard";
import NavBar from "./components/NavBar/index";
import CartPage from "./components/Dashboard/CartPage";
import CartEvent from "./components/Dashboard/CartEvent";
import CartItemDetails from "./components/Dashboard/CartItemDetails";
import CartItemEdit from "./components/Dashboard/CartItemEdit";
import AllergyAdd from "./pages/allergy/AllergyAdd";
import AllergyDetails from "./pages/allergy/AllergyDetails";
import AllergyList from "./pages/allergy/AllergyList";
import EditAllergy from "./pages/allergy/EditAllergy";
import EstoreFrontPage from "./pages/EstoreFrontPage";
import Events from "./pages/EstoreFrontPage/Events.js";
import UserOrdersPage from "./components/Dashboard/UserOrdersPage";
import CheckoutPage from "./components/Dashboard/CheckoutPage";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";

function App() {
  return (
    <Router>
      <>
        <NavBar />
        <div className="flex justify-center items-center">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />

            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/signup" element={<SignUp />} />
            {/* <Route path="/settings/:id" element={<Settings />} /> */}
            {/*  <Route path="/settings" element={<Settings />} /> */}
            <Route path="/user-profile" element={<UserProfile />} />
            <Route path="/profiles" element={<Profile />} />
            <Route path="/profile" element={<ProfileScreen />} />
            <Route path="/profile/:id" element={<ProfileEditScreen />} />
            <Route path="/dashboard/*" element={<Dashboard />} />
            <Route path="/cart" element={<CartPage />} />
            <Route path="/cartitem/:id" element={<CartItemDetails />} />
            <Route path="/cart-event/:id" element={<CartEvent />} />
            <Route path="/cart-item-edit/:id" element={<CartItemEdit />} />
            <Route path="/store" element={<EstoreFrontPage />} />
            <Route path="/store/:id/events" element={<Events />} />
            <Route path="/store/:id" element={<EstoreFrontPage />} />
            <Route path="/allergy" element={<AllergyList />} />
            <Route path="/allergy/add" element={<AllergyAdd />} />
            <Route path="/allergy/:id" element={<AllergyDetails />} />
            <Route path="/allergy/edit/:id" element={<EditAllergy />} />
            <Route path="/cart/check-out" element={<CheckoutPage />} />
            <Route path="/orders" element={<UserOrdersPage />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </>

      <Footer />
    </Router>
  );
}

export default App;
function NotFound() {
  return (
    <div>
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for does not exist.</p>
    </div>
  );
}
