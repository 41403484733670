import React, { useState, useEffect } from "react";
import "./index.css";

const CompletionDateTimePicker = ({ onSave, order, onClose }) => {
  const [selectedHour, setSelectedHour] = useState(new Date().getHours());
  const [selectedMinute, setSelectedMinute] = useState(new Date().getMinutes());
  const [minHour, setMinHour] = useState(null);
  const maxHour = 24;
  const maxMinute = 60;

  const [stepInterval, setStepInterval] = useState(5); // Default step interval of 5 minutes

  // Set minimum hour to current hour
  useEffect(() => {
    const currentHour = new Date().getHours();
    setMinHour(currentHour);
  }, []);

  // Handle changes in the hour picker
  const handleHourChange = (e) => {
    setSelectedHour(e.target.value);
  };

  // Handle changes in the minute picker
  const handleMinuteChange = (e) => {
    const minute = parseInt(e.target.value, 10);
    if (minute % stepInterval === 0 && minute < maxMinute) {
      setSelectedMinute(minute);
    }
  };

  // Adjust the step interval (multiply or divide by 5)
  const changeStepInterval = (multiplier) => {
    setStepInterval((prevStep) => {
      const newStep = prevStep * multiplier;
      return newStep >= 1 ? Math.round(newStep) : 1; // Ensure stepInterval is never less than 1
    });
  };

  // Increment hour and minute
  const incrementHour = () => {
    setSelectedHour((prev) => (prev < maxHour - 1 ? prev + 1 : prev));
  };

  const decrementHour = () => {
    setSelectedHour((prev) => (prev > minHour ? prev - 1 : prev));
  };

  const incrementMinute = () => {
    setSelectedMinute((prev) =>
      prev + stepInterval < maxMinute ? prev + stepInterval : prev
    );
  };

  const decrementMinute = () => {
    setSelectedMinute((prev) =>
      prev - stepInterval >= 0 ? prev - stepInterval : prev
    );
  };

  const handleSave = () => {
    const now = new Date(); // Current date
    const selectedDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      selectedHour,
      selectedMinute
    );

    // Format to ISO 8601 string
    const isoDateTime = selectedDate.toISOString();

    onSave({
      selectedTime: isoDateTime,
      order,
    });
  };

  // Convert the selected hour to 12-hour format with AM/PM
  const formatTime = (hour, minute) => {
    const ampm = hour >= 12 ? "PM" : "AM";
    const hour12 = hour % 12 === 0 ? 12 : hour % 12;
    const minuteStr = minute < 10 ? `0${minute}` : minute;
    return `${hour12}:${minuteStr} ${ampm}`;
  };

  return (
    <>
      <div className="modal-content" style={{ display: "block" }}>
        {/* Display the anticipated completion time in 12-hour format */}
        <div className="anticipated-time" onClick={onClose}>
          <p>{formatTime(selectedHour, selectedMinute)}</p>
        </div>
        <div className="modal-header flex flex-wrap space-y-0">
          <h2 className="space-y-0 mb-0">Confirm Completion Time</h2>
          <p>
            current: {new Date(order?.completionDateTime).toLocaleString()}{" "}
          </p>
        </div>
        <h4>{order.clientOrder?.name}</h4>
        <div className="modal-body">
          <div className="number-picker-container">
            <div className="time-picker">
              <label htmlFor="hour-picker">Select Hour: </label>
              <div className="hour-picker">
                <button onClick={decrementHour}>-</button>
                <input
                  type="number"
                  id="hour-picker"
                  value={selectedHour}
                  onChange={handleHourChange}
                  min={minHour}
                  max={maxHour}
                  className="number-picker"
                />
                <button onClick={incrementHour}>+</button>
              </div>
            </div>

            <div className="colon-container">
              <p>:</p>
            </div>

            <div className="time-picker">
              <label htmlFor="minute-picker">Select Minute: </label>
              <div className="minute-picker">
                <button onClick={decrementMinute}>-</button>
                <input
                  type="number"
                  id="minute-picker"
                  value={selectedMinute}
                  onChange={handleMinuteChange}
                  min={0}
                  max={maxMinute - 1}
                  className="number-picker"
                />
                <button onClick={incrementMinute}>+</button>
              </div>
            </div>
          </div>
        </div>
        {/* <div>
          <p>Step Interval</p>
        </div> */}

        <div className="modal-controls">
          <button
            onClick={() => changeStepInterval(0.5)}
            className="btn btn-primary"
          >
            Halve Step
          </button>
          <div className="step-container">
            <p>Step: {stepInterval} min</p>
          </div>
          <button
            onClick={() => changeStepInterval(2)}
            className="btn btn-primary"
          >
            Double Step
          </button>
        </div>

        <div className="modal-footer">
          <div className="footer-buttons">
            <button onClick={handleSave} className="btn btn-primary">
              Set Time
            </button>
            <button onClick={onClose} className="btn btn-secondary">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompletionDateTimePicker;
