import React from "react";
import { useParams } from "react-router-dom";
import { useGetSpecialEventByIdQuery } from "../../../auth/services/specialEventService";
import { getImageUrl } from "../../utils/constants";

const SpecialEventDetail = () => {
  const { eventId, id } = useParams();

  // Fetch the special event by ID
  const {
    data: specialEvent,
    isLoading,
    isError,
    error,
  } = useGetSpecialEventByIdQuery({ id: eventId || id });

  // Loading state
  if (isLoading) {
    return <p>Loading special event...</p>;
  }

  // Error state
  if (isError) {
    return (
      <p>
        Error {id} fetching special event:{" "}
        {error?.data?.message || error?.status}
      </p>
    );
  }

  // Render the special event details
  if (!specialEvent) {
    return <p>No special event found for the given ID.</p>;
  }

  return (
    <div>
      <h3>{specialEvent?.name}</h3>
      <p>
        <strong>Date:</strong> {specialEvent?.eventDate}
      </p>
      <p>
        <strong>Start Date & Time:</strong>{" "}
        {new Date(specialEvent?.startTime).toLocaleString()}
      </p>
      <p>
        <strong>End Date & Time:</strong>{" "}
        {new Date(specialEvent?.endTime).toLocaleString()}
      </p>
      <p>
        <strong>Description:</strong> {specialEvent?.description}
      </p>
      <p>
        <strong>Location:</strong> {specialEvent?.location}
      </p>
      <img
        style={{ width: "80vw", height: "auto" }}
        src={`${getImageUrl(specialEvent?.image, "specialEvents")}`}
        alt="poster"
      />
    </div>
  );
};

export default SpecialEventDetail;
