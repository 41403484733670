// ProductSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiClient from "../../utils/apiClient"; // Import apiClient

export const updateProductLogo = createAsyncThunk(
  "auth/productUpdateLogo",
  async ({ id, file }, { getState, rejectWithValue }) => {
    // Check if the file is present
    if (!file) {
      const errorMessage = "No file provided for uploading.";
      alert(errorMessage); // Optionally alert the user
      return rejectWithValue(errorMessage); // Return an error
    }
    try {
      // Create a FormData object to hold the image file
      const formData = new FormData();
      formData.append("file", file); // The "file" key should match the server-side expectation

      // "Content-Type": "multipart/form-data",
      // Make the PATCH request to update the logo
      const { data } = await apiClient.patch(`/product/${id}/logo`, formData);
      return data;
    } catch (error) {
      const errorMessage =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      //alert(JSON.stringify(errorMessage) + " from productSlice.js");
      return rejectWithValue(errorMessage);
    }
  }
);

const initialState = {
  isLoading: false,
  isSuccess: false,
  error: null,
};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    resetProduct: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {},
});

// Export actions and reducer
export const { resetProduct } = productSlice.actions;
export default productSlice.reducer;
