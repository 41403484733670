import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Error from "../components/Error";
import Spinner from "../components/Spinner";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { registerUser, emailExists } from "../redux/auth/authActions";
import VerifyEmail from "../components/utils/VerifyEmail";
import { isNullOrUndefined } from "@hookform/resolvers";

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
export default function Signup() {
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password")], "Passwords must match"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm(formOptions);
  const { loading, userInfo, emailStatus, error, success } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isVerified, setIsVerified] = useState(true);
  const [emailError, setEmailError] = useState("");

  const handleVerification = (status) => {
    setIsVerified(status);
  };

  useEffect(() => {
    reset();
    setEmailError("");
    if (userInfo && success) navigate("/");
  }, [navigate, userInfo, success, reset]);

  const onSubmit = async (data) => {
    if (data.email && !emailRegex.test(data.email)) {
      setEmailError("Invalid email format.");
      return;
    }
    if (data.password !== data.confirmPassword) {
      alert("Password mismatch");
    }
    data.email = data.email.toLowerCase();
    await handleEmailCheck(data.email);
    if (!emailStatus || isNullOrUndefined(error)) {
      await dispatch(registerUser(data));
      navigate("/");
    } else {
      setEmailError(
        "This email is already registered. Please use another." + error
      );
    }
  };

  const handleEmailCheck = async (email) => {
    if (email) {
      try {
        await dispatch(emailExists(email)).unwrap();
        // Check for specific error if email exists
      } catch (err) {
        console.error("Error checking email:", err);
        if (err.message) {
          setEmailError(
            "This email is already registered. Please use another."
          );
        } else {
          setEmailError("An unexpected error occurred. Please try again.");
        }
      }
    }
  };

  const onErrors = (errors) => console.error(errors);

  return (
    <div className="w-full max-w-xs my-10">
      <form
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
        onSubmit={handleSubmit(onSubmit, onErrors)}
      >
        {error && <Error>{error}</Error>}
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="username"
          >
            Username
          </label>
          <input
            type="text"
            name="username"
            id="username"
            autoComplete="username"
            {...register("username")}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          <small className="invalid-feedback text-red-500 text-xs italic">
            {errors?.username && errors.username.message}
          </small>
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="email"
          >
            Email
          </label>
          <input
            autoComplete="email"
            id="email"
            type="email"
            name="email"
            {...register("email", { required: true })}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          <small className="invalid-feedback text-red-500 text-xs italic">
            {errors?.email && errors.email.message}
          </small>
          {/* Display the email error */}
          {emailError && (
            <p className="text-red-500 text-xs italic">{emailError}</p>
          )}
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="password"
          >
            Password
          </label>
          <input
            autoComplete="new-password"
            id="password"
            name="password"
            type="password"
            {...register("password")}
            className={`form-control ${
              errors.password
                ? "is-invalid"
                : "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            }`}
          />
          <div className="invalid-feedback text-red-500 text-xs italic">
            {errors.password?.message}
          </div>
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="confirmPassword"
          >
            Confirm Password
          </label>
          <input
            name="confirmPassword"
            autoComplete="new-password"
            id="confirmPassword"
            type="password"
            {...register("confirmPassword")}
            className={`form-control ${
              errors.confirmPassword
                ? "is-invalid"
                : "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            }`}
          />
          <div className="invalid-feedback text-red-500 text-xs italic">
            {errors.confirmPassword?.message}
          </div>
        </div>

        <div className="form-group space-x-8">
          <div style={{ textAlign: "center", marginTop: "50px" }}>
            {!isVerified ? (
              <>
                <h2>Please Verify Your Email</h2>
                <VerifyEmail onVerify={handleVerification} />
              </>
            ) : (
              <>
                <p>Proceed to complete your registration.</p>
                <div className="flex space-x-4">
                  <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    disabled={loading}
                  >
                    {loading ? <Spinner /> : "Register"}
                  </button>
                  <button
                    type="button"
                    onClick={() => reset()}
                    className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  >
                    Reset
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
