import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaEdit, FaPlusCircle } from "react-icons/fa";
import { BsCartDash } from "react-icons/bs";
import {
  updateFoodItemCartItemQuantity,
  removeFromFoodItemCart,
} from "../../../redux/auth/foodItemCartSlice";
import {
  removeFromProductCart,
  updateProductCartItemQuantity,
} from "../../../redux/auth/productCartSlice";
import "./index.css"; // Import CSS for car
import { useNavigate } from "react-router-dom";

const CartPage = () => {
  const { foodItemCart } = useSelector((state) => state.foodItemCart);
  const { productCart } = useSelector((state) => state.productCart);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  if (foodItemCart.length === 0 && productCart.length === 0) {
    return (
      <div style={{ width: "60%" }}>
        <h2>Cart is empty</h2>
      </div>
    );
  }

  // Handle quantity change for food items
  const handleQuantityChange = (foodItemId, e) => {
    const newQuantity = parseInt(e.target.value, 10);
    if (newQuantity > 0) {
      dispatch(
        updateFoodItemCartItemQuantity({ foodItemId, quantity: newQuantity })
      );
    }
  };

  // Remove food item from cart
  const handleRemoveFromCart = (foodItemId) => {
    dispatch(removeFromFoodItemCart(foodItemId));
  };

  // Handle quantity change for products
  const handleProductQuantityChange = (productId, e) => {
    const newQuantity = parseInt(e.target.value, 10);
    if (newQuantity > 0) {
      dispatch(
        updateProductCartItemQuantity({ productId, quantity: newQuantity })
      );
    }
  };

  // Remove product from cart
  const handleRemoveFromProductCart = (productId) => {
    dispatch(removeFromProductCart(productId));
  };

  return (
    <div className="cart-container">
      <h2>Cart</h2>
      <ul className="cart-list">
        {foodItemCart.map((product) => (
          <li key={product.foodItemId} className="cart-item">
            <div className="cart-item-details">
              {product?.image && (
                <img
                  src={
                    product?.image?.includes("https://")
                      ? product.image
                      : `https://horeloimgs.s3.amazonaws.com/food-item/${product.image}`
                  }
                  alt={product.name}
                />
              )}
              <div className="cart-item-info">
                <h4 className="cart-item-name">{product?.name}</h4>
                <p className="cart-item-price">
                  Price: $
                  {product?.price?.basePrice
                    ? product?.price?.basePrice
                    : product?.price?.price}
                </p>
                <p className="cart-item-quantity">
                  Quantity:
                  <input
                    type="number"
                    value={product?.quantity}
                    min="1"
                    onChange={(e) =>
                      handleQuantityChange(product.foodItemId, e)
                    }
                  />
                </p>
              </div>

              <div className="cart-item-info">
                <button
                  className="remove-btn"
                  onClick={() => handleRemoveFromCart(product.foodItemId)}
                >
                  <BsCartDash className="add-icon" />
                  Remove
                </button>
                <button className="remove-btn" onClick={() => {}}>
                  <FaPlusCircle className="add-icon" /> Variant
                </button>
                <button
                  className="remove-btn"
                  onClick={() => {
                    navigate(`/cart-item-edit/${product.foodItemId}`, {
                      state: {
                        foodItemId: product.foodItemId,
                        uniqueId: product.uniqueId,
                      },
                    });
                  }}
                >
                  <FaEdit className="add-icon" /> Customize
                </button>
              </div>
              <div style={{ maxWidth: "20rem" }}>
                <h6>
                  <strong>Description</strong>
                </h6>
                <p>{product?.description}</p>
              </div>
            </div>

            <div
              className="estore-info"
              onClick={() =>
                navigate(`/store/${product?.estore?.name}`, {
                  state: { estore: product?.estore },
                })
              }
            >
              <label>{product?.estore?.name}</label>
            </div>
          </li>
        ))}
        {productCart.map((product) => (
          <li key={product.productId} className="cart-item">
            <div className="cart-item-details">
              {product?.image && (
                <img
                  src={
                    product?.image?.includes("https://")
                      ? product.image
                      : `https://horeloimgs.s3.amazonaws.com/product/${product.image}`
                  }
                  alt={product.name}
                />
              )}
              <div className="cart-item-info">
                <h3 className="cart-item-name">{product.name}</h3>
                <p className="cart-item-price">
                  Price: ${product.price.basePrice}
                </p>
                <p className="cart-item-quantity">
                  Quantity:
                  <input
                    type="number"
                    value={product.quantity}
                    min="1"
                    onChange={(e) =>
                      handleProductQuantityChange(product.productId, e)
                    }
                  />
                </p>
              </div>
              <button
                className="remove-btn"
                onClick={() => handleRemoveFromProductCart(product.productId)}
              >
                Remove
              </button>
            </div>
          </li>
        ))}
      </ul>
      <h3>
        Total: $
        {foodItemCart.reduce(
          (total, product) =>
            total + product.price.basePrice * product.quantity,
          0
        ) +
          productCart.reduce(
            (total, product) =>
              total + product.price.basePrice * product.quantity,
            0
          )}
      </h3>
      <button className="checkout-btn" onClick={() => navigate("check-out")}>
        Check-out
      </button>
    </div>
  );
};

export default CartPage;
