import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetSubCategoriesQuery } from "../../../auth/services/subcategoryService";
import {
  useGetAllCategoriesQuery,
  useGetCategoryBySubCategoryIdQuery,
} from "../../../auth/services/categoryService";
import "./index.css";
import OutOfStockCheckbox from "../OutOfStock";
import SpiceLevels from "../SpiceLevel";
import GarnishToppings from "../GarnishToppings";
import LiquidAddons from "../LiquidAddOns";
import SolidAddons from "../SolidAddons";
import Ingredients from "../Ingredients";
import {
  useUpdateFoodItemDetailsMutation,
  useGetFoodItemInfoQuery,
} from "../../../auth/services/foodItemService";
import { useGetIngredientInfoByEstoreQuery } from "../../../auth/services/ingredientService";
import FoodCourse from "../FoodCourse";
import FoodItemFileUpload from "../../utils/FoodItemUpload";
import AvailableDaysAndHoursEditor from "../../utils/AvailableDaysAndHours";
import { skipToken } from "@reduxjs/toolkit/query";
import DefaultIngredients from "../DefaultIngredients";
const EditFoodItem = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Get the food item ID from the URL

  // Retrieve userId and estoreId from Redux store
  const userId = useSelector((state) => state.auth.userInfo.id);
  const estoreId = useSelector((state) => state.auth.userInfo.defaultEstore);

  const [updateFoodItemDetails] = useUpdateFoodItemDetailsMutation();

  // Fetch existing food item details to populate the form
  const { data: foodItem, isLoading, error } = useGetFoodItemInfoQuery(id);

  //Fetch Ingredients from backend
  const { data: ingredientSource = [], isLoading: loadingIngredientSource } =
    useGetIngredientInfoByEstoreQuery();
  // State for form inputs
  const [name, setName] = useState("");
  const [cuisineType, setCuisineType] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(0);
  const [discount, setDiscount] = useState(0.0);
  const [isOutOfStock, setIsOutOfStock] = useState(false);
  const [visibility, setVisibility] = useState("PUBLIC");
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);
  const [garnishes, setGarnishes] = useState([]);
  const [foodCourses, setFoodCourses] = useState([]);
  const [liquidAddons, setLiquidAddons] = useState([]);
  const [solidAddons, setSolidAddons] = useState([]);
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [selectedDefaultIngredients, setSelectedDefaultIngredients] = useState(
    []
  );
  const [spiceLevels, setSpiceLevels] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [productData, setProductData] = useState({
    name: "",
    description: "",
    category: "",
    subCategoryId: selectedSubCategoryId,
    calories: 0,
    price: { basePrice: 0.0, discount: 0.0, currency: "USD" },
    availableDaysAndHours: {
      openPeriodsByDay: [],
    },

    estoreId,
  });

  const { data: category, isLoading: loadCategory } =
    useGetCategoryBySubCategoryIdQuery(
      foodItem?.subCategory?.subCategoryId
        ? {
            id: foodItem?.subCategory?.subCategoryId,
          }
        : skipToken
    );
  const { data: categories = [], isLoading: loadingCategories } =
    useGetAllCategoriesQuery();
  const { data: subcategories = [], isLoading: loadingSubcategories } =
    useGetSubCategoriesQuery(
      selectedCategoryId
        ? { categoryId: selectedCategoryId }
        : category?.categoryId
        ? { categoryId: category?.categoryId }
        : null,
      { skip: !selectedCategoryId && !category?.categoryId }
    );

  useEffect(() => {
    if (foodItem) {
      // Set initial data from fetched food item
      const { category, subCategory, availableDaysAndHours, ...rest } =
        foodItem;
      setProductData({
        ...rest,
        subCategory,
        category,
        availableDaysAndHours,
      });

      //alert(foodItem?.ingredients?.trim()?.split());
      setName(foodItem.name);
      setCuisineType(foodItem.cuisine_type);
      setDescription(foodItem.description);
      setPrice(foodItem.price.basePrice);
      setDiscount(foodItem.price.discount);
      setVisibility(foodItem.visibility);
      setSelectedSubCategoryId(foodItem.subCategory?.subCategoryId || null);
      // Prepare combined ingredients from foodItem
      const mapToChecked = (items) =>
        items.map((item) => ({ name: item.trim(), checked: true }));

      const {
        garnish = [],
        spiceLevels = [],
        solidAddons = [],
        liquidAddons = [],
        foodCourses = [],
        otherIngredients = [],
      } = foodItem.combinedIngredients || {};

      setGarnishes(mapToChecked(garnish));
      setSpiceLevels(mapToChecked(spiceLevels));
      setSolidAddons(mapToChecked(solidAddons));
      setLiquidAddons(mapToChecked(liquidAddons));
      setFoodCourses(mapToChecked(foodCourses));
      setSelectedIngredients(otherIngredients);
      setSelectedDefaultIngredients(foodItem?.ingredients?.split(","));
    }

    if (!loadingIngredientSource && ingredientSource.length > 0) {
      // Helper to combine foodItem ingredients with ingredientSource
      const mergeIngredients = (source, foodItems) => {
        const sourceMap = source.reduce((map, item) => {
          map[item.name] = { ...item, checked: false };
          return map;
        }, {});

        foodItems.forEach((item) => {
          const itemName = typeof item === "string" ? item : item.name; // Support string or object
          if (sourceMap[itemName]) {
            sourceMap[itemName].checked = true; // Mark as true if found in source
          } else {
            sourceMap[itemName] = { name: itemName, checked: true }; // Add missing items
          }
        });

        return Object.values(sourceMap); // Return unique, merged items
      };

      // Filter ingredientSource by subcategories
      const garnishSource = ingredientSource.filter(
        (item) => item.SubCategory.name === "Garnish/Toppings"
      );
      const spiceSource = ingredientSource.filter(
        (item) => item.SubCategory.name === "Spice Level"
      );
      const solidSource = ingredientSource.filter(
        (item) => item.SubCategory.name === "Solid AddOn"
      );
      const liquidSource = ingredientSource.filter(
        (item) => item.SubCategory.name === "Liquid AddOn"
      );
      const foodCourseSource = ingredientSource.filter(
        (item) => item.SubCategory.name === "Course Type"
      );

      // Merge sources with foodItem ingredients
      setGarnishes(
        mergeIngredients(
          garnishSource,
          foodItem?.combinedIngredients?.garnish || []
        )
      );
      setSpiceLevels(
        mergeIngredients(
          spiceSource,
          foodItem?.combinedIngredients?.spiceLevels || []
        )
      );
      setSolidAddons(
        mergeIngredients(
          solidSource,
          foodItem?.combinedIngredients?.solidAddons || []
        )
      );
      setLiquidAddons(
        mergeIngredients(
          liquidSource,
          foodItem?.combinedIngredients?.liquidAddons || []
        )
      );
      setFoodCourses(
        mergeIngredients(
          foodCourseSource,
          foodItem?.combinedIngredients?.foodCourses || []
        )
      );
      setSelectedIngredients(
        foodItem?.combinedIngredients?.otherIngredients.map((ingredient) => ({
          name: ingredient,
        })) || []
      );
    }
  }, [foodItem, loadingIngredientSource, ingredientSource]);
  useEffect(() => {
    if (foodItem && category) {
      // Set initial category and subcategory from food item details
      //setSelectedSubCategoryId(foodItem.subCategory?.subCategoryId || null);
      setSelectedCategoryId(category.categoryId);
    }
  }, [foodItem, category]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "basePrice") {
      setProductData((prevData) => ({
        ...prevData,
        price: { ...prevData.price, basePrice: parseFloat(value) },
      }));
    } else if (name === "calories") {
      setProductData((prevData) => ({
        ...prevData,
        calories: parseFloat(value),
      }));
    } else if (name === "subCategoryId") {
      setProductData((prevData) => ({
        ...prevData,
        subCategoryId: parseInt(value),
      }));
    } else {
      setProductData((prevData) => ({ ...prevData, [name]: value }));
    }
  };
  const combinedIngredients = {
    garnish: garnishes
      .filter((garnish) => garnish.checked)
      .map((garnish) => garnish.name),
    spiceLevels: spiceLevels
      .filter((spice) => spice.checked)
      .map((spice) => spice.name),
    solidAddons: solidAddons
      .filter((solid) => solid.checked)
      .map((solid) => solid.name),
    liquidAddons: liquidAddons
      .filter((liquid) => liquid.checked)
      .map((liquid) => liquid.name),
    otherIngredients: selectedIngredients.map((ingredient) => ingredient.name),
    foodCourses: foodCourses
      .filter((course) => course.checked)
      .map((course) => course.name),
  };
  const handleCategoryChange = (e) => {
    const categoryId = parseInt(e.target.value);
    setSelectedCategoryId(categoryId);
    setSelectedSubCategoryId(null); // Clear subcategory when category changes
  };

  const handleSubCategoryChange = (e) => {
    const subCategoryId = parseInt(e.target.value);
    setSelectedSubCategoryId(subCategoryId);
  };
  const handleSetSelectedDefaultIngredients = (selectedDefaultIngredients) => {
    // Update local state of selected ingredients
    setSelectedDefaultIngredients(selectedDefaultIngredients);
  };
  // Submit data to server
  const submitData = async () => {
    const data = {
      //...productData,
      subCategoryId: selectedSubCategoryId,
      calories: productData.calories,
      name,
      image: foodItem.image,
      spice_level:
        "" +
        spiceLevels
          .filter((spice) => spice.checked)
          .map((spice) => spice.name)
          .join(", "), // Format spice levels as a comma-separated string
      cuisine_type: cuisineType,
      price: {
        basePrice: price,
        discount: discount,
        currency: "USD",
      },
      description,
      //days_available: daysAvailable.join(", "), // Comma-separated days
      visibility,
      estoreId,
      userId,
      combinedIngredients,
      ingredients: selectedDefaultIngredients.join(", "), // Comma-separated ingredients

      availableDaysAndHoursDTO: productData?.availableDaysAndHours,
    };
    //http://localhost:8080/api/v1/public/auth/foodItem/[object%20Object]
    //console.log(JSON.stringify(data));
    try {
      //console.log(JSON.stringify(data));
      await updateFoodItemDetails({ id, data }).unwrap();
      console.log("Data updated successfully:");
      navigate("/dashboard");
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitData();
  };
  //
  const handleBackClick = () => {
    navigate("/dashboard");
  };

  if (isLoading || loadCategory || loadingCategories || loadingSubcategories) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error fetching food item details</div>;
  }

  return (
    <div className="edit-food-item-container">
      <button className="btn btn-secondary mb-3" onClick={handleBackClick}>
        Back to Dashboard
      </button>
      <h3>Edit Menu Item</h3>
      <FoodItemFileUpload image={productData.image} className="w-100" />
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="productCategory">Menu Item Category</label>
          <select
            id="category"
            value={selectedCategoryId || ""}
            onChange={handleCategoryChange}
          >
            <option value="">Select Category</option>
            {categories.map((cat) => (
              <option key={cat.categoryId} value={cat.categoryId}>
                {cat.name}
              </option>
            ))}
          </select>

          <label htmlFor="subcategory">Subcategory</label>
          <select
            id="subcategory"
            value={selectedSubCategoryId || ""}
            onChange={handleSubCategoryChange}
            disabled={!selectedCategoryId} // Disable until category is selected
          >
            <option value="">Select Subcategory</option>
            {subcategories.map((sub) => (
              <option key={sub.subCategoryId} value={sub.subCategoryId}>
                {sub.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="product-name">Product Name</label>
          <input
            id="product-name"
            name="product-name"
            type="text"
            className="form-control"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="cuisine-type">Cuisine Type</label>
          <input
            id="cuisine-type"
            name="cuisine-type"
            type="text"
            className="form-control"
            value={cuisineType}
            onChange={(e) => setCuisineType(e.target.value)}
            required
          />
        </div>
        <FoodCourse foodCourses={foodCourses} setFoodCourses={setFoodCourses} />
        <div className="form-group">
          <label htmlFor="description">Description</label>
          <textarea
            id="description"
            name="description"
            className="form-control"
            rows="3"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          ></textarea>
        </div>
        <DefaultIngredients
          selectedDefaultIngredients={selectedDefaultIngredients}
          setSelectedDefaultIngredients={handleSetSelectedDefaultIngredients}
          suggestions={ingredientSource}
        />
        <div className="d-flex w-100">
          <div className="form-group">
            <label htmlFor="price">Price</label>
            <input
              id="price"
              name="price"
              type="number"
              className="form-control"
              value={price}
              onChange={(e) => setPrice(parseFloat(e.target.value))}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="discount">Discount</label>
            <input
              id="discount"
              name="discount"
              type="number"
              className="form-control"
              value={discount}
              onChange={(e) => setDiscount(parseFloat(e.target.value))}
            />
          </div>
          <div className="form-group">
            <label htmlFor="calories">Calories</label>
            <input
              id="calories"
              type="number"
              className="form-control"
              name="calories"
              value={productData.calories}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <GarnishToppings garnishes={garnishes} setGarnishes={setGarnishes} />
        <LiquidAddons
          liquidAddons={liquidAddons}
          setLiquidAddons={setLiquidAddons}
        />
        <SolidAddons
          solidAddons={solidAddons}
          setSolidAddons={setSolidAddons}
        />
        <Ingredients
          selectedIngredients={selectedIngredients}
          setSelectedIngredients={setSelectedIngredients}
          suggestions={ingredientSource}
        />
        <SpiceLevels
          spiceLevels={spiceLevels}
          setSpiceLevels={setSpiceLevels}
        />
        <div className="d-flex">
          <div className="form-group me-2 w-40">
            <label htmlFor="visibility">Visibility</label>
            <select
              id="visibility"
              name="visibility"
              value={visibility}
              onChange={(e) => setVisibility(e.target.value)}
              className="form-control"
            >
              <option value="PUBLIC">Public</option>
              <option value="PRIVATE">Private</option>
              <option value="UNAVAILABLE">Unavailable</option>
            </select>
          </div>
          <div className="form-group  w-40">
            <OutOfStockCheckbox
              isOutOfStock={isOutOfStock}
              setIsOutOfStock={setIsOutOfStock}
            />
          </div>
        </div>
        <AvailableDaysAndHoursEditor
          availableDaysAndHours={
            productData?.availableDaysAndHours?.openPeriodsByDay || []
          }
          onUpdate={(newOpenDaysAndHours) =>
            setProductData((prevProductData) => ({
              ...prevProductData,
              availableDaysAndHours: { openPeriodsByDay: newOpenDaysAndHours },
            }))
          }
        />

        <button type="submit" className="btn btn-primary">
          Update
        </button>
      </form>
    </div>
  );
};

export default EditFoodItem;
