import Cookies from "js-cookie";
/* const tokens = {
  userTokens: "undefined",
  refreshToken: "undefined",
}; */
export const getCookie = (name) => {
  return Cookies.get(name); // Get cookie by name
};

export const setCookie = (name, value, days = 7) => {
  console.log("Setting cookie:", name, value);
  Cookies.set(name, value, {
    expires: days,
    secure: true,
    sameSite: "Strict",
  });
};
export const setCookieJson = (name, tokens, days = 7) => {
  Cookies.set(name, JSON.stringify(tokens), {
    expires: days,
    secure: true,
    sameSite: "Strict",
  });
};
export const getCookieJson = (name) => {
  return Cookies.get(name); // Get cookie by name
};
export const deleteCookie = (name) => {
  Cookies.remove(name); // Remove cookie by name
};
