import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useUpdateIngredientDetailsMutation,
  useGetIngredientInfoQuery,
} from "../../../auth/services/ingredientService";
import "./index.css";
import { useGetAllCategoriesQuery } from "../../../auth/services/categoryService";
import {
  useGetSubCategoryQuery,
  useGetSubCategoriesQuery,
} from "../../../auth/services/subcategoryService";
import { useGetCategoryBySubCategoryIdQuery } from "../../../auth/services/categoryService";

const EditIngredient = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { id } = useParams();
  const estoreId = useSelector((state) => state.auth.userInfo.defaultEstore);

  // Mutation hook for updating ingredient
  const [updateIngredientDetails] = useUpdateIngredientDetailsMutation();

  // Fetch ingredient details
  const {
    data: ingredient,
    isLoading: ingredientLoading,
    error: ingredientError,
  } = useGetIngredientInfoQuery(id);

  // Fetch categories and subcategories
  const { data: allCategories, isLoading: allCategoriesLoading } =
    useGetAllCategoriesQuery();

  const { data: subCategory, isLoading: subCategoryLoading } =
    useGetSubCategoryQuery(
      state?.SubCategory?.subCategoryId
        ? { id: state?.SubCategory?.subCategoryId }
        : null
    );

  const { data: currentCategory, isLoading: currentCategoryLoading } =
    useGetCategoryBySubCategoryIdQuery(
      state?.SubCategory?.subCategoryId
        ? { id: state?.SubCategory?.subCategoryId }
        : null,
      { skip: !state?.SubCategory?.subCategoryId }
    );

  const { data: subCategories, isLoading: subCategoriesLoading } =
    useGetSubCategoriesQuery(
      currentCategory?.categoryId
        ? { categoryId: currentCategory?.categoryId }
        : null,
      { skip: !currentCategory?.categoryId }
    );

  // State for form data
  const [ingredientData, setIngredientData] = useState({
    name: "",
    description: "",
    subCategoryId: 0,
    categoryId: 0,
    price: {
      basePrice: 0,
      discount: 1.1,
      currency: "USD",
    },
    estoreId: 0,
  });

  // Effect to populate the form with ingredient data
  useEffect(() => {
    if (id && ingredient && currentCategory && subCategory) {
      setIngredientData({
        name: ingredient.name,
        description: ingredient.description,
        subCategoryId: parseInt(subCategory?.subCategoryId || 0),
        categoryId: parseInt(currentCategory?.categoryId || 0),
        price: {
          basePrice: parseFloat(ingredient?.price?.basePrice),
          discount: parseFloat(ingredient?.price?.discount),
          currency: ingredient?.price?.currency || "USD",
        },
        estoreId: parseInt(estoreId),
      });
    }
  }, [id, ingredient, currentCategory, estoreId, subCategory]);

  // Handle form changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "basePrice" || name === "discount" || name === "currency") {
      setIngredientData((prevData) => ({
        ...prevData,
        price: { ...prevData.price, [name]: value },
      }));
    } else {
      setIngredientData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleBackClick = () => {
    navigate("/dashboard");
  };
  //http://localhost:8080/api/v1/public/auth/ingredient/352
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const { categoryId, ...data } = ingredientData;
      //(JSON.stringify(data));
      await updateIngredientDetails({
        id: parseInt(id),
        data: data,
      }).unwrap();
      navigate("/dashboard");
    } catch (error) {
      console.error("Error updating ingredient:", error);
    }
  };

  /*   // Loading state
  if (
    ingredientLoading ||
    allCategoriesLoading ||
    subCategoryLoading ||
    subCategory ||
    currentCategoryLoading ||
    subCategoriesLoading
  ) {
    return <div>Loading...</div>;
  } */

  if (ingredientError) {
    return <div>Error loading ingredient data</div>;
  }

  return (
    <div className="edit-ingredient-container">
      <button className="btn btn-secondary mb-3" onClick={handleBackClick}>
        Back to Dashboard
      </button>

      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Ingredient Name{id}</label>
          <input
            type="text"
            className="form-control"
            name="name"
            value={ingredientData?.name}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Ingredient Description {estoreId}</label>
          <textarea
            className="form-control"
            rows="3"
            name="description"
            value={ingredientData?.description}
            onChange={handleInputChange}
            required
          ></textarea>
        </div>

        {/* Subcategory Dropdown */}
        <div className="form-group">
          <label>Ingredient Subcategory: </label>
          <select
            name="subCategoryId"
            value={ingredientData.subCategoryId}
            onChange={handleInputChange}
            className="form-control"
          >
            {subCategories?.map((subCategory) => (
              <option
                key={subCategory.subCategoryId}
                value={subCategory.subCategoryId}
              >
                {subCategory.name}
              </option>
            ))}
          </select>
        </div>

        {/* Category Dropdown */}
        <div className="form-group">
          <label>Ingredient Category:</label>
          <select
            name="categoryId"
            value={ingredientData?.categoryId}
            onChange={handleInputChange}
            className="form-control"
          >
            <option value="" disabled>
              Select Category
            </option>
            {allCategories?.map((category) => (
              <option key={category.categoryId} value={category.categoryId}>
                {category.name}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label>Ingredient Price</label>
          <input
            type="number"
            className="form-control"
            name="basePrice"
            value={ingredientData?.price?.basePrice}
            onChange={handleInputChange}
            required
          />
        </div>

        <button type="submit" className="btn btn-primary">
          Update Ingredient
        </button>
      </form>
    </div>
  );
};

export default EditIngredient;
