import { FaBox } from "react-icons/fa";

const OrderUtil = () => {
  return (
    <div style={styles.container}>
      <FaBox style={styles.icon} />
    </div>
  );
};
export default OrderUtil;
const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative", // Ensure that child elements can be positioned relative to this container
  },
  icon: {
    fontSize: "25px", // Size of the shopping cart icon
    color: "gray", // Color of the icon
  },
  label: {
    position: "absolute", // Overlay the label on top of the icon
    top: "-16px", // Adjust to control vertical position
    left: "10px", // Adjust to control horizontal position
    fontSize: "16px", // Font size for the cart count
    color: "gray", // Text color
    fontWeight: "bold", // Bold text
  },
};
