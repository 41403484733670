import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchEstoreList } from "../../redux/auth/estoreSlice";
import { getCurrentUser, setDefaultEstore } from "../../redux/auth/userSlice"; // Assume you have an action for this
import Spinner from "../../components/Spinner";
import Company from "./Company";
import { useNavigate } from "react-router-dom";

const CompanyList = ({ refreshEstoreList }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { estoreList, isLoading, error } = useSelector((state) => state.estore);
  const { defaultEstore } = useSelector((state) => state.auth.userInfo);
  const { userInfo } = useSelector((state) => state.auth);
  const { estore } = useSelector((state) => state);

  useEffect(() => {
    if (estoreList?.length === 0 && !isLoading && !error) {
      dispatch(fetchEstoreList());
    }
  }, [dispatch, estoreList?.length, isLoading, error]);
  useEffect(() => {
    if (defaultEstore) {
      dispatch(getCurrentUser());
    }
  }, [defaultEstore, dispatch]);

  /*   // Function to refresh the list on button click
  const handleRefresh = () => {
    dispatch(fetchEstoreList());
    refreshEstoreList(); // Call the refresh function passed as prop
  }; */
  // Handle the selection of a company
  const handleView = (estoreId) => {
    navigate("/dashboard");
  };
  // Handle the selection of a company
  const handleActivate = async (estoreId) => {
    try {
      await dispatch(setDefaultEstore(estoreId)).unwrap(); // Wait for setDefaultEstore to finish
      await dispatch(getCurrentUser()).unwrap(); // Fetch updated user data
    } catch (error) {
      console.error("Failed to update default store:", error);
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="flex flex-col items-center">
      {/* <button
        onClick={handleRefresh}
        className="mb-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
        disabled={isLoading}
      >
        {isLoading ? "Loading..." : "Refresh List"}
      </button> */}
      <div className="flex flex-wrap justify-center gap-4">
        {Array.isArray(estoreList) &&
          estoreList?.map((company) => (
            <Company
              key={company.estoreId}
              company={company}
              //onSelect={handleSelect}
              onActivate={() => handleActivate(company.estoreId)}
              onView={handleView}
            />
          ))}
      </div>
    </div>
  );
};

export default CompanyList;
