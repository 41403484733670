import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; // Import slick styles
import "slick-carousel/slick/slick-theme.css"; // Import slick theme styles
import { getFoodItemInfo } from "../../../auth/services/homeService";
import { getImageUrl } from "../../utils/constants";
import CheckboxWithRadio from "../../../pages/components/CheckboxWithRadio";
import { updateCustomizationMap } from "../../../pages/components/updateCustomizationMap.js";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import {
  addToFoodItemCart,
  updateFoodItemCartItem,
} from "../../../redux/auth/foodItemCartSlice.js";
import { FaPlusCircle } from "react-icons/fa";
import "./index.css";
import ErrorDisplay from "../../../pages/components/ErrorDisplay.js";
import HoverText from "../../../pages/components/HoverText";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    border: "1px solid #ccc",
    background: "rgba(255, 255, 255, 0.5)",
  },
};

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        background: "red",
        fontSize: "2rem",
        height: "50px",
        width: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        borderRadius: "35%",
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        fontSize: "2rem",
        height: "50px",
        width: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        borderRadius: "35%",
        background: "green",
      }}
      onClick={onClick}
    />
  );
}

const CartItemEdit = () => {
  const dispatch = useDispatch();
  const { id } = useParams(); // Get foodItem ID from the URL
  const { foodItemId, uniqueId } = useLocation().state || {};
  const [foodItem, setFoodItem] = useState();
  const [chefNote, setChefNote] = useState();
  const { foodItemCart } = useSelector((state) => state.foodItemCart);
  const [error, setError] = useState();
  const [openModalId, setOpenModalId] = useState(null);
  const [customizationMap, setCustomizationMap] = useState(new Map());
  const categories = [
    { label: "Garnish", field: "garnish" },
    { label: "Spice Levels", field: "spiceLevels" },
    { label: "Solid Addons", field: "solidAddons" },
    { label: "Liquid Addons", field: "liquidAddons" },
    { label: "Other Ingredients", field: "otherIngredients" },
  ];

  const [selectedItems, setSelectedItems] = useState({
    garnish: [],
    spiceLevels: "",
    solidAddons: [],
    liquidAddons: "",
    otherIngredients: [],
  });
  let subtitle;
  const [modalContent, setModalContent] = useState({
    category: "",
    item: "",
    qty: "norm",
    side: false,
  });

  function openModal(category, item) {
    setModalContent({
      category,
      item,
      qty: getItemQty(item) || "norm", // Default quantity if not found
      side: getItemSide(item) || false, // Default side state if not found
    });
    setOpenModalId(`${category}-${item}`); // Identify the specific modal
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setOpenModalId(null); // Close all modals
  }
  useEffect(() => {
    // Find the item in the cart that matches the foodItemId and uniqueId
    const cartItem = foodItemCart.find(
      (item) => item.foodItemId === foodItemId && item.uniqueId === uniqueId
    );

    // If the item is found in the cart
    if (cartItem) {
      // Set customizationMap if the item has customizations
      if (cartItem.customizations) {
        const newCustomizationMap = new Map();
        // Set each customization in the map
        for (const [key, value] of Object.entries(cartItem.customizations)) {
          newCustomizationMap.set(key, value);
        }
        setCustomizationMap(newCustomizationMap);
      }
      // Function to truncate the items format
      const truncateGarnish = (items) => {
        // If items is a string, truncate it
        if (typeof items === "string") {
          return items.split(" (")[0]; // Remove the part after '('
        }

        // If items is an array, truncate each item in the array
        if (Array.isArray(items)) {
          return items.map((item) => item.split(" (")[0]); // Remove the part after '('
        }

        // Return empty array if items is neither string nor array
        return [];
      };
      // Set the selectedItems based on the customizations
      setSelectedItems({
        garnish: cartItem?.customizations?.garnish
          ? truncateGarnish(cartItem.customizations.garnish)
          : [],
        spiceLevels: cartItem?.customizations?.spiceLevels
          ? truncateGarnish(cartItem.customizations.spiceLevels)
          : "",
        solidAddons: cartItem?.customizations?.solidAddons
          ? truncateGarnish(cartItem.customizations.solidAddons)
          : [],
        liquidAddons: cartItem?.customizations?.liquidAddons
          ? truncateGarnish(cartItem.customizations.liquidAddons)
          : "",
        otherIngredients: cartItem?.customizations?.otherIngredients
          ? truncateGarnish(cartItem.customizations.otherIngredients)
          : [],
      });
    }
  }, [foodItemCart, foodItemId, uniqueId]);

  useEffect(() => {
    getFoodItemInfo(id)
      .then((res) => {
        if (customizationMap?.length < 1) {
          setSelectedItems({
            garnish: res?.data?.combinedIngredients?.garnish || [],
            spiceLevels: res?.data?.combinedIngredients?.spiceLevels[0] || "",
            solidAddons: res?.data?.combinedIngredients?.solidAddons || [],
            liquidAddons: res?.data?.combinedIngredients?.liquidAddons[0] || "",
            otherIngredients:
              res?.data?.combinedIngredients?.otherIngredients || [],
          });
        }
        setFoodItem(res.data);
      })
      .catch((error) => {
        setError(error.message);
      });
  }, [id, customizationMap]);

  const handleSelectionChange = (category, value) => {
    if (
      category === "otherIngredients" ||
      category === "garnish" ||
      category === "solidAddons"
    ) {
      setSelectedItems((prevSelected) => {
        const updatedItems = [...prevSelected[category]];
        if (updatedItems.includes(value)) {
          updatedItems.splice(updatedItems.indexOf(value), 1);
        } else {
          updatedItems.push(value);
        }
        return {
          ...prevSelected,
          [category]: updatedItems,
        };
      });
    } else {
      setSelectedItems((prevSelected) => ({
        ...prevSelected,
        [category]: value,
      }));
    }
  };

  const handleUpdateCustomization = (item, category, updates) => {
    // Create a new Map to avoid mutating the existing state directly
    const updatedMap = new Map(customizationMap);
    const items = [item]; // Wrap the single item in an array for `updateCustomizationMap`

    // Update the customization map with provided updates
    updateCustomizationMap(updatedMap, category, items, updates);

    // Update the state with the modified Map
    setCustomizationMap(updatedMap);
    setModalContent({ ...modalContent, updates });
  };

  // Checkbox change handler
  const handleCheckboxChange = (isChecked, item, category) => {
    // handleUpdateCustomization(item, category, { side: isChecked });
    handleUpdateCustomization(item, category, { side: isChecked });
    setModalContent((prev) => ({
      ...prev,
      side: isChecked,
    }));
  };

  // Radio change handler
  const handleRadioChange = (qty, item, category) => {
    handleUpdateCustomization(item, category, { qty });
    setModalContent((prev) => ({
      ...prev,
      qty,
    }));
    //handleUpdateCustomization(item, category, { qty });
  };
  function getItemSide(items) {
    // Check if items is an array and handle accordingly
    if (Array.isArray(items)) {
      return items?.map((item) => {
        const itemDetails = customizationMap.get(item);
        return itemDetails ? itemDetails.side : false; // Default to `false` if item is not found
      });
    } else {
      const itemDetails = customizationMap.get(items);
      return itemDetails ? itemDetails.side : false; // Default to `false` if item is not found
    }
  }
  function getItemQty(items) {
    // Check if items is an array and handle accordingly
    if (Array.isArray(items)) {
      return items?.map((item) => {
        const itemDetails = customizationMap.get(item);
        return itemDetails ? itemDetails.qty : "none"; // Default to "none" if item is not found
      });
    } else {
      const itemDetails = customizationMap.get(items);
      return itemDetails ? itemDetails.qty : "none"; // Default to "none" if item is not found
    }
  }
  // Helper function to check if the product is already in the cart
  const isItemInFoodItemCart = (productId) => {
    return foodItemCart.some((cartItem) => cartItem.foodItemId === productId);
  };
  const handleUpdateFoodItemCart = (product) => {
    const {
      selectedItems,
      customizationMap,
      foodItem,
      id,
      uniqueId,
      chefNote,
    } = product;

    // Enhance selectedItems with customization details
    const updateCustomizedSelectedItems = Object.entries(selectedItems).reduce(
      (acc, [category, items]) => {
        // Handle both arrays and single-value items
        const updatedItems = Array.isArray(items)
          ? items.map((item) => {
              const customization = customizationMap.get(item);
              return customization
                ? `${item} (qty: ${customization.qty}, side: ${customization.side})`
                : item;
            })
          : (() => {
              const customization = customizationMap.get(items);
              return customization
                ? `${items} (qty: ${customization.qty}, side: ${customization.side})`
                : items;
            })();
        return { ...acc, [category]: updatedItems };
      },
      {}
    );

    // Create an updated food item object with all details
    const updateCustomizedFoodItem = {
      ...foodItem,
      foodItemId: id,
      customizations: updateCustomizedSelectedItems,
      uniqueId,
      specialMessage: chefNote || "",
    };

    // Debug: Alert to view the updated food item
    //alert(JSON.stringify(updateCustomizedFoodItem));

    // Dispatch the update action to update the food item in the cart
    dispatch(updateFoodItemCartItem(updateCustomizedFoodItem));
  };
  // Add product foodItem to cart handler
  const handleAddToFoodItemCart = (product) => {
    const uniqueId = foodItem?.uniqueId || `${id}-${Date.now()}`;
    // Enhance selectedItems with customization details
    const customizedSelectedItems = Object.entries(selectedItems).reduce(
      (acc, [category, items]) => {
        // Handle both arrays and single-value items
        const updatedItems = Array.isArray(items)
          ? items.map((item) => {
              const customization = customizationMap.get(item);
              return customization
                ? `${item} (qty: ${customization.qty}, side: ${customization.side})`
                : item;
            })
          : (() => {
              const customization = customizationMap.get(items);
              return customization
                ? `${items} (qty: ${customization.qty}, side: ${customization.side})`
                : items;
            })();
        return { ...acc, [category]: updatedItems };
      },
      {}
    );
    const customizedFoodItem = {
      ...foodItem,
      foodItemId: id,
      customizations: customizedSelectedItems,
      uniqueId,
      specialMessage: chefNote || "",
    };
    // alert(JSON.stringify(customizedFoodItem));
    dispatch(addToFoodItemCart(customizedFoodItem)); // Dispatch the action to add product to cart
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  if (error) return <ErrorDisplay error={error} />;
  return (
    <div style={styles.container}>
      <div style={styles.headers}>
        <img src={getImageUrl(foodItem?.image, "food-item")} alt="logo" />
        <h5>
          <strong>{foodItem?.name}</strong>
        </h5>
        <p style={{ fontSize: ".8rem" }}>Customize:</p>
      </div>

      {/* Slick Carousel for Customization Categories */}
      <Slider {...settings}>
        {categories.map((category, index) => {
          const items = foodItem?.combinedIngredients[category?.field] || [];
          if (items.length === 0) return null; // Skip empty categories
          return (
            <div key={index} style={styles.carouselSlide}>
              <h6>
                <strong>{category.label}</strong>
              </h6>
              {items?.map((item, itemIndex) => (
                <div key={itemIndex} style={styles.radioLabelContainer}>
                  {["otherIngredients", "garnish", "solidAddons"].includes(
                    category.field
                  )
                    ? category?.field &&
                      item && (
                        <label key={itemIndex} style={styles.checkboxLabel}>
                          <input
                            type="checkbox"
                            name={category.field}
                            value={item}
                            style={styles.input}
                            checked={selectedItems[category.field].includes(
                              item
                            )}
                            onChange={() =>
                              handleSelectionChange(category.field, item)
                            }
                          />
                          {item}
                        </label>
                      )
                    : category?.field &&
                      item && (
                        <label key={itemIndex} style={styles.radioLabel}>
                          <input
                            type="radio"
                            name={category.field}
                            value={item}
                            style={styles.input}
                            checked={selectedItems[category.field] === item}
                            onChange={() =>
                              handleSelectionChange(category.field, item)
                            }
                          />
                          {item}
                        </label>
                      )}
                </div>
              ))}
            </div>
          );
        })}
      </Slider>

      {/* Selected Items Summary */}
      <div style={styles.selectionSummary}>
        <h6>
          <strong>Selected Customizations</strong>
        </h6>
        <ul>
          {Object.entries(selectedItems)?.map(([category, items]) => (
            <li
              key={category + (Array.isArray(items) ? items.join(",") : items)}
              style={styles.rowContainer}
            >
              {category || items || (
                <strong>
                  {category?.charAt(0).toUpperCase() +
                    category?.slice(1).toLowerCase()}
                  :
                </strong>
              )}

              {/* Handle if category is array or "otherIngredients" */}
              {/* {category === "otherIngredients" ? */}
              {Array.isArray(items) ? (
                // Handle case for "otherIngredients" which is an array
                items?.map((item) => (
                  <div key={category + items + item}>
                    <div style={styles.rowItem}>
                      <button
                        className="dark-left label"
                        onClick={() => openModal(category, item)}
                      >
                        {item}
                        {/* <HoverText originalText={item} hoverText="yes" /> */}
                      </button>
                    </div>
                    <Modal
                      isOpen={
                        openModalId === `${category}-${modalContent.item}`
                      }
                      onAfterOpen={afterOpenModal}
                      onRequestClose={closeModal}
                      style={customStyles}
                      contentLabel={`${modalContent.category} - ${modalContent.item}`}
                    >
                      <div style={styles.rowItem}>
                        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
                          {modalContent.category}
                        </h2>
                        <label className="button" onClick={closeModal}>
                          close
                        </label>
                      </div>
                      <div>
                        Selected Item: {modalContent.item}
                        <CheckboxWithRadio
                          label={modalContent.item}
                          initialQty={modalContent.qty}
                          initialSide={modalContent.side}
                          onRadioChange={(qty) =>
                            handleRadioChange(
                              qty,
                              modalContent.item,
                              modalContent.category
                            )
                          }
                          onCheckboxChange={(isChecked) =>
                            handleCheckboxChange(
                              isChecked,
                              modalContent.item,
                              modalContent.category
                            )
                          }
                        />
                      </div>
                    </Modal>
                  </div>
                ))
              ) : (
                <div>
                  <div style={styles.rowItem}>
                    <button
                      className="label dark-left"
                      onClick={() => openModal(category, items)}
                    >
                      {items}
                      {/* <HoverText originalText={items} hoverText="yes" /> */}
                    </button>
                  </div>
                  <Modal
                    isOpen={openModalId === `${category}-${modalContent.item}`}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel={`${modalContent.category} - ${modalContent.item}`}
                  >
                    <>
                      <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
                        {modalContent?.category}
                      </h2>
                      <button onClick={closeModal}>close</button>
                      <div>
                        Selected Item: {modalContent?.item}
                        <CheckboxWithRadio
                          label={modalContent.item}
                          initialQty={modalContent.qty} // Pass initial quantity
                          initialSide={modalContent.side} // Pass initial side state
                          onRadioChange={(qty) =>
                            handleRadioChange(
                              qty,
                              modalContent.item,
                              modalContent.category
                            )
                          }
                          onCheckboxChange={(isChecked) =>
                            handleCheckboxChange(
                              isChecked,
                              modalContent.item,
                              modalContent.category
                            )
                          }
                        />
                      </div>
                    </>
                  </Modal>
                </div>
              )}
            </li>
          ))}
        </ul>
        <label style={styles.label}>
          Chef Note:
          <textarea
            style={styles.textarea}
            id="specialMessage"
            name="specialMessage"
            maxlength="100"
            rows={4}
            cols={40}
            value={chefNote || ""}
            onChange={(e) => setChefNote(e.target.value)}
            placeHolder={
              " Allergies / Intolerants if any please indicate them here"
            }
          ></textarea>
        </label>
        <div style={styles.rowItem}>
          <button className="btn btn-1" style={styles.rowItem}>
            <FaPlusCircle style={{ marginRight: "8px" }} />
            cancel
          </button>
          <button className="btn btn-2" style={styles.rowItem}>
            <FaPlusCircle style={{ marginRight: "8px" }} />
            reset Item
          </button>
          {isItemInFoodItemCart(id) === true ? (
            <button
              style={styles.rowItem}
              className="btn btn-3"
              onClick={() => handleUpdateFoodItemCart(foodItem)}
              disabled={isItemInFoodItemCart(id)}
            >
              update
            </button>
          ) : (
            <button
              style={styles.rowItem}
              className="btn btn-4"
              onClick={() => handleAddToFoodItemCart(foodItem)}
              disabled={isItemInFoodItemCart(id)}
            >
              <FaPlusCircle style={{ marginRight: "8px" }} />
              save to cart
            </button>
          )}
          {/* <h3>Customization Map:</h3>
          <pre>
            {JSON.stringify(Object.fromEntries(customizationMap), null, 2)}
          </pre> */}
        </div>
      </div>
    </div>
  );
};

// Basic Styles
const styles = {
  headers: {
    justifyItems: "center",
  },
  container: {
    flexDirection: "column",
    border: "1px solid #ddd",
    borderRadius: "8px",
    padding: "20px",
    maxWidth: "60%",
  },
  carouselSlide: {
    padding: "10px",
  },
  radioLabelContainer: {
    flexDirection: "row",
    justifyItems: "flex-start",
    gap: "10px",
    width: "100%",
    marginLeft: "40px",
    alignContents: "center",
  },
  radioLabel: {
    display: "flex",
    alignContents: "center",
    marginBottom: "5px",
    justifyItems: "center",
    width: "150px",
    fontSize: "14px",
    lineHeight: "1.5",
  },
  rowContainer: {
    display: "flex", // Align the container items in a row
    alignItems: "center", // Center the items vertically
    alignContents: "center",
    alignSelf: "center",
    gap: "1px", // Add some space between items
  },
  rowItem: {
    display: "flex",
    fontSize: "11px",
    marginLeft: "5px",
    marginRight: "5px",
    flexDirection: "row",
    padding: "5px",
    justifyContent: "space-between",
  },
  checkboxLabel: {
    display: "flex",
    alignItems: "center",
    marginBottom: "5px",
    justifyItems: "center",
    width: "150px",
    fontSize: "14px",
  },
  input: {
    marginRight: "10px",
    height: "18px",
    width: "18px",
  },
  label: {
    display: "block", // Makes the label a block element, causing it to take up the full width and push the textarea below.
    marginBottom: "5px", // Adds a small space between the label and textarea.
    fontWeight: "bold", // Makes the label text bold for better visibility.
    fontSize: ".85rem",
  },
  textarea: {
    border: "1px solid black", // Adds a black border to the textarea.
    marginRight: "10px", // Adds space to the right side of the textarea.
    width: "100%", // Ensures the textarea takes up the full width of its container.
    padding: "8px", // Adds padding inside the textarea for better readability.
    boxSizing: "border-box", // Ensures the padding is included in the width of the textarea.
  },
  selectionSummary: {
    marginTop: "20px",
    paddingTop: "10px",
    borderTop: "1px solid #ddd",
  },
};

export default CartItemEdit;
