import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { verifyEmail } from "../../redux/auth/authActions";
const VerifyEmail = ({ onVerify }) => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const dispatch = useDispatch();

  // Get email verification state from Redux
  const { verificationStatus, loading, error } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (!token) {
      onVerify(false);
      return;
    }

    // Dispatch the verifyEmail thunk
    dispatch(verifyEmail({ token }));
  }, [dispatch, token, onVerify]);

  useEffect(() => {
    // Notify parent of the verification result once the status updates
    if (!loading) {
      onVerify(!error);
    }
  }, [loading, error, onVerify]);

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      {loading ? (
        <p>Verifying your email...</p>
      ) : error ? (
        <p style={{ color: "red" }}>{error}</p>
      ) : (
        <p style={{ color: "green" }}>{verificationStatus}</p>
      )}
    </div>
  );
};

export default VerifyEmail;
