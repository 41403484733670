// Import RTK Query utilities
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../../components/utils/constants";

// Create the API slice using RTK Query
export const userOrdersApi = createApi({
  reducerPath: "userOrdersApi",
  // A unique key for this API slice in the Redux store
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL, // Base URL of the backend API
    prepareHeaders: (headers, { getState }) => {
      // Retrieve token from Redux store
      const token = getState()?.auth?.userToken;
      //const userId = getState().auth.userId; // Retrieve userId if needed

      // Add the token to the headers if it exists
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
        headers.set("Content-Type", "application/json");
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    // Endpoint for fetching past user orders
    fetchUserOrders: builder.query({
      queryFn: async (_, { getState }, _extraOptions, fetchWithBQ) => {
        const { userId } = getState()?.auth?.userInfo;
        const result = await fetchWithBQ({
          url: `/client/orders/user/${userId}`,
          method: "GET",
        });
        return result.error ? { error: result.error } : { data: result.data };
      },
    }),
    // Endpoint for fetching order
    fetchOrder: builder.query({
      queryFn: async (args, { getState }, _extraOptions, fetchWithBQ) => {
        const { clientOrderId } = args;
        const result = await fetchWithBQ({
          url: `/client/orders/${clientOrderId}`,
          method: "GET",
        });
        return result.error ? { error: result.error } : { data: result.data };
      },
    }),

    // Endpoint for posting a new order
    postOrder: builder.mutation({
      queryFn: async (args, { getState }, _extraOptions, fetchWithBQ) => {
        const { userId } = getState().auth.userInfo;
        const { data } = args;
        const result = await fetchWithBQ({
          url: `/client/orders/${userId}`,
          method: "POST",
          body: data,
        });
        return result.error ? { error: result.error } : { data: result.data };
      },
    }),
  }),
});

// Export hooks for components to use
export const {
  useFetchUserOrdersQuery, // Hook for fetching past orders
  useFetchOrderQuery, // Hook for fetching order
  usePostOrderMutation, // Hook for posting a new order
} = userOrdersApi;
