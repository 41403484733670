import React from "react";

const OutOfStockCheckbox = ({ isOutOfStock, setIsOutOfStock }) => {
  const handleCheckboxChange = (event) => {
    event.preventDefault();
    setIsOutOfStock(event.target?.checked); // Update the state in the parent component
  };

  return (
    <div className="">
      <label htmlFor="out-of-stock" className="align-items-center w-100">
        <input
          type="checkbox"
          id="out-of-stock"
          name="out-of-stock"
          checked={isOutOfStock}
          onChange={(event) => {
            handleCheckboxChange(event);
          }}
          className="me-2 w-25"
        />
        Out of Stock
      </label>

      {/* Display the out of stock status */}
      {/* {isOutOfStock && (
        <p className="mt-2 text-danger">This item is currently out of stock.</p>
      )} */}
    </div>
  );
};

export default OutOfStockCheckbox;
