import { createSlice } from "@reduxjs/toolkit";
import {
  registerUser,
  userLogin,
  updateUser,
  verifyEmail,
  emailExists,
} from "./authActions";
import { isTokenExpired, refreshAccessToken } from "../../utils/decodeToken";
import {
  deleteCookie,
  getCookie,
  setCookie,
  setCookieJson,
} from "../../utils/cookie";
import { isNullOrUndefined } from "@hookform/resolvers";

// Retrieve tokens from cookies
//// Initialize userToken from local storage
const tokens = getCookie("userToken") || null;
const storedToken =
  JSON.parse(tokens)?.userTokens || !isNullOrUndefined(getCookie("userTokens"));
const refreshToken =
  JSON.parse(tokens)?.refreshToken ||
  !isNullOrUndefined(getCookie("refreshToken"));

// Function to check if a token exists and is valid
const isValidToken = (token) => token && !isTokenExpired(token);

let userToken = null;

// If the access token is valid, use it
if (isValidToken(storedToken)) {
  userToken = storedToken;
} else if (isValidToken(refreshToken)) {
  // If access token is expired but refresh token is valid, refresh the access token
  // Assuming you have a function to refresh the token using the refresh token
  userToken = refreshAccessToken(refreshToken);
}

if (!userToken) {
  // Redirect to login or show an error message if both tokens are invalid
  // redirectToLogin();
}
const initialState = {
  emailStatus: null,
  verificationStatus: null,
  loading: false,
  userInfo: null,
  userToken: userToken || null,
  refrshToken: refreshToken || null,
  error: null,
  success: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      deleteCookie("userTokens"); // Remove the user token cookie
      deleteCookie("refreshToken"); // Remove the refresh token cookie
      deleteCookie("userToken");

      state.userInfo = null;
      state.userToken = null;
      state.userRefresh = null;
      state.error = null;
    },
    setUserToken: (state, action) => {
      state.userToken = action.payload.jwtToken;
      state.userRefresh = action.payload.refreshToken;
      setCookie("userTokens", action.payload.jwtToken); // Store the user token in a cookie
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
      state.defaultEstore = action.payload?.defaultEstore;
    },
    setCredentials: (state, { payload }) => {
      state.userInfo = payload;
      state.defaultEstore = payload?.defaultEstore;
      setCookie("userTokens", payload.jwtToken); // Store the token in a cookie
      setCookie("refreshToken", payload.refreshToken); // Store the refresh token in a cookie
    },
  },
  extraReducers: (builder) => {
    builder
      // Login user
      .addCase(userLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userLogin.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.defaultEstore = payload.defaultEstore;
        state.userInfo = payload;
        state.userToken = payload.jwtToken;
        state.refreshToken = payload.refreshToken;
        setCookie("userTokens", payload.jwtToken); // Store the user token in a cookie
        setCookie("refreshToken", payload.refreshToken); // Store the refresh token in a cookie
        setCookieJson("userToken", {
          userTokens: payload.jwtToken,
          refreshToken: payload?.refreshToken,
        });
      })
      .addCase(userLogin.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      // Register user
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, { payload }) => {
        state.success = true; // Registration successful
        state.userInfo = payload;
        state.defaultEstore = payload?.defaultEstore;
        state.refreshToken = payload.refreshToken;
        state.userToken = payload.jwtToken;
        setCookie("userTokens", payload.jwtToken); // Store the user token in a cookie
        setCookie("refreshToken", payload.refreshToken); // Store the refresh token in a cookie
        setCookieJson("userToken", {
          userTokens: payload.jwtToken,
          refreshToken: payload?.refreshToken,
        });
        state.loading = false;
      })
      .addCase(registerUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      // Update user
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true; // Update successful
      })
      .addCase(updateUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      // Verify email
      .addCase(verifyEmail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(verifyEmail.fulfilled, (state, action) => {
        state.loading = false;
        state.verificationStatus = action.payload;
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      }) // email
      .addCase(emailExists.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(emailExists.fulfilled, (state, action) => {
        state.loading = false;
        state.emailStatus = action.payload;
      })
      .addCase(emailExists.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { logout, setCredentials, setUserToken, setUserInfo } =
  authSlice.actions;
export default authSlice.reducer;
