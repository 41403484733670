import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./index.css";
import { useCreateIngredientDetailsMutation } from "../../../auth/services/ingredientService";
const Ingredients = ({
  selectedIngredients,
  setSelectedIngredients,
  suggestions,
}) => {
  const [newIngredient, setNewIngredient] = useState("");
  const [filteredIngredients, setFilteredIngredients] = useState([]);
  const estoreId = useSelector((state) => state.auth.userInfo.defaultEstore);
  const [loading, setIsLoading] = useState(false);

  const [createIngredientWithName] = useCreateIngredientDetailsMutation();

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setNewIngredient(value);

    if (value.length > 0) {
      const filtered = suggestions?.filter(
        (ingredient) =>
          ingredient?.name?.toLowerCase()?.includes(value?.toLowerCase()) &&
          !selectedIngredients.some(
            (existingIngredient) =>
              existingIngredient?.name?.toLowerCase() ===
              ingredient?.name?.toLowerCase()
          )
      );
      setFilteredIngredients(filtered);
    } else {
      setFilteredIngredients([]);
    }
  };

  const handleNewAddIngredient = async (ingredient) => {
    if (!ingredient || !ingredient.name.trim()) return;

    try {
      setIsLoading(true);

      if (
        selectedIngredients?.some(
          (ing) => ing?.name?.toLowerCase() === ingredient?.name?.toLowerCase()
        )
      ) {
        setIsLoading(false);
        return;
      }
      setSelectedIngredients((prev) => [
        ...prev,
        { name: ingredient.name.trim() },
      ]);
      await createIngredientWithName({
        name: ingredient.name.trim(),
        subCategoryId: suggestions.find(
          (item) => item.SubCategory.name === "Other Ingredients"
        )?.SubCategory?.subCategoryId,
        estoreId,
      }).unwrap();

      setNewIngredient("");
      setFilteredIngredients([]);
    } catch (err) {
      console.error("Error adding ingredient:", err);
    } finally {
      setIsLoading(false);
    }
  };
  const handleAddIngredient = async (ingredient) => {
    setSelectedIngredients((prev) => [...prev, ingredient]);
    if (!ingredient || !ingredient.name.trim()) return;

    setNewIngredient("");
    setFilteredIngredients([]);
  };

  const handleRemoveIngredient = (ingredientName) => {
    setSelectedIngredients((prevSelected) =>
      prevSelected.filter(
        (ingredient) =>
          ingredient?.name?.toLowerCase() !== ingredientName?.toLowerCase()
      )
    );
  };

  return (
    <div className="form-group w-full">
      <label htmlFor="input-ingredients">Ingredients</label>
      <div className="row">
        <input
          id="input-ingredients"
          name="input-ingredients"
          type="text"
          className="form-control me-2 w-50"
          value={newIngredient}
          onChange={handleSearchChange}
          placeholder="Search for ingredients"
        />
        <button
          type="button"
          className="btn btn-primary addons-button"
          onClick={() => handleNewAddIngredient({ name: newIngredient })}
          disabled={loading || !newIngredient.trim()}
        >
          {loading ? "Adding..." : "Add Ingredient"}
        </button>
      </div>

      {newIngredient.length > 0 && filteredIngredients.length === 0 && (
        <div>No results found</div>
      )}

      {newIngredient.length > 0 && (
        <ul className="list-group mt-2">
          {filteredIngredients.map((ingredient, index) => (
            <li
              key={index}
              className="list-group-item"
              onClick={() => handleAddIngredient(ingredient)}
            >
              {ingredient?.name}
            </li>
          ))}
        </ul>
      )}

      <div className="selected-ingredients mt-2">
        <h5>Selected Ingredients</h5>
        {selectedIngredients?.map((ingredient, index) => (
          <label
            key={index}
            className="badge badge-primary mr-2"
            onClick={(e) => {
              e.preventDefault();
              handleRemoveIngredient(ingredient?.name);
            }}
          >
            {ingredient?.name} &times;
          </label>
        ))}
      </div>

      {/* <pre>{JSON.stringify(suggestions, null, 2)}</pre> */}
    </div>
  );
};
export default Ingredients;
