import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetCategoryInfoQuery,
  useCreateCategoryDetailsMutation,
  useUpdateCategoryDetailsMutation,
} from "../../../auth/services/categoryService"; // Update path accordingly
import "./index.css";
const CategoryForm = () => {
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    data: category,
    error: fetchError,
    isLoading,
  } = useGetCategoryInfoQuery({ id }, { skip: !id });
  const [createCategory] = useCreateCategoryDetailsMutation();
  const [updateCategory] = useUpdateCategoryDetailsMutation();

  useEffect(() => {
    if (category) {
      setName(category.name);
    }
  }, [category]);

  useEffect(() => {
    if (fetchError) {
      setError("Error fetching category");
    }
  }, [fetchError]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = { name };

    try {
      if (id) {
        await updateCategory({ id, data: payload }).unwrap();
      } else {
        await createCategory(payload).unwrap();
      }
      navigate("/dashboard/product/categories");
    } catch (err) {
      setError("Error saving category");
    }
  };

  if (isLoading) return <p>Loading...</p>;

  return (
    <div>
      <h1>{id ? "Edit Category" : "Add Category"}</h1>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <form onSubmit={handleSubmit}>
        <div>
          <label>Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>

        <button type="submit">Save</button>
      </form>
    </div>
  );
};

export default CategoryForm;
