/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "./index.css";
import { Link, useNavigate } from "react-router-dom";
import { useGetAllIngredientsQuery } from "../../../auth/services/ingredientService";

const IngredientList = () => {
  const navigate = useNavigate();
  // State for available ingredients and selected ingredients
  const [availableIngredients, setAvailableIngredients] = useState([]);
  const [selectedIngredients, setSelectedIngredients] = useState([]);

  // Fetch the ingredients from the backend
  const {
    data: ingredients,
    error,
    isLoading,
  } = useGetAllIngredientsQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });

  // On successful data fetch, set the available ingredients
  useEffect(() => {
    if (ingredients) {
      setAvailableIngredients(ingredients);
    }
  }, [ingredients]);

  // Handle loading and error states
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading ingredients.</div>;
  }

  // Function to add an ingredient to the selected list
  const addToSelected = (ingredient) => {
    // Remove ingredient from available list and add to selected list
    setAvailableIngredients((prevList) =>
      prevList.filter((item) => item.ingredientId !== ingredient.ingredientId)
    );
    setSelectedIngredients((prevSelected) => [...prevSelected, ingredient]);
  };

  // Function to remove an ingredient from the selected list
  const removeFromSelected = (ingredient) => {
    // Remove ingredient from selected list and add back to available list
    setSelectedIngredients((prevSelected) =>
      prevSelected.filter(
        (item) => item.ingredientId !== ingredient.ingredientId
      )
    );
    setAvailableIngredients((prevList) => [...prevList, ingredient]);
  };

  return (
    <div className="shadow mx-2 px-3 bg-light rounded-3 w-full">
      <div className="align-items-center justify-content-between p-3 mt-3">
        <h4 className="mb-0">Ingredients List</h4>
        <div className="ml-auto">
          <Link
            className="btn btn-primary"
            to="/dashboard/product/addingredient"
          >
            Add Ingredient
          </Link>
        </div>
      </div>

      {/* Available Ingredients List */}
      <div className="w-[100%] mt-1">
        <h5>Available Ingredients</h5>
        <ul className="w-[90%]">
          {availableIngredients.map((ingredient) => (
            <li key={ingredient.ingredientId} className="space-x-1 mb-1 mt-1">
              <div className="row">
                <div className="m1-0">
                  <div className="row">
                    <div className="col-auto">
                      <Link
                        to={`/dashboard/product/ingredientlist/${ingredient.ingredientId}`}
                      >
                        {ingredient?.name} {ingredient?.category}{" "}
                        {ingredient?.price?.basePrice || 0}
                      </Link>
                    </div>
                    <div className="col-auto">
                      <button
                        onClick={() => addToSelected(ingredient)}
                        className="mb-0 bg-blue-500 hover:bg-blue-600 text-white py-1 px-2 rounded"
                      >
                        Select
                      </button>
                    </div>

                    <div className="col-auto">
                      <button
                        onClick={() =>
                          navigate(
                            `/dashboard/product/ingredientlist/${ingredient.ingredientId}`
                          )
                        }
                        className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-2 rounded"
                      >
                        View
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* Selected Ingredients List */}
      <div>
        <h5>Selected Ingredients</h5>
        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {selectedIngredients.map((ingredient) => (
              <tr key={ingredient.ingredientId}>
                <td>{ingredient.name}</td>
                <td>
                  <button
                    onClick={() => removeFromSelected(ingredient)}
                    className="bg-red-500 hover:bg-red-600 text-white py-1 px-2 rounded"
                  >
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default IngredientList;
