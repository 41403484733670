import React from "react";

const OrderPolicy = ({ orderPolicy, onChange }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedPolicy = {
      ...orderPolicy,
      [name]: Number(value), // Ensure numeric values
    };
    onChange(updatedPolicy);
  };

  return (
    <div className="order-policy-container">
      <h3>Edit Order Policies</h3>
      <div className="form-group">
        <label htmlFor="defaultWaitingTime">
          Default Waiting Time (minutes):
        </label>
        <input
          type="number"
          id="defaultWaitingTime"
          name="defaultWaitingTime"
          value={orderPolicy?.defaultWaitingTime}
          onChange={handleInputChange}
          min="0"
        />
      </div>
      <div className="form-group">
        <label htmlFor="processingTime">Processing Time (minutes):</label>
        <input
          type="number"
          id="processingTime"
          name="processingTime"
          value={orderPolicy?.processingTime}
          onChange={handleInputChange}
          min="0"
        />
      </div>
      <div className="form-group">
        <label htmlFor="confirmedTime">Confirmed Time (minutes):</label>
        <input
          type="number"
          id="confirmedTime"
          name="confirmedTime"
          value={orderPolicy?.confirmedTime}
          onChange={handleInputChange}
          min="0"
        />
      </div>
    </div>
  );
};
export default OrderPolicy;
