import React, { useEffect, useState, useCallback, useMemo } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  FaTachometerAlt,
  FaClipboardList,
  FaBox,
  FaUsers,
  FaCog,
} from "react-icons/fa";
import {
  useGetEstoreInfoQuery,
  estoreApi,
} from "../../../auth/services/estoreService";
import { formatTimeToStandard } from "../../utils/formatTimeToHHMM";
import { decodeToken } from "../../../utils/decodeToken";

const imageUrl = "https://horeloimgs.s3.amazonaws.com/estore/";

const VerticalNavbar = () => {
  const token = useSelector((state) => state.auth.userToken);
  const estoreId = decodeToken(token).store;
  const id = estoreId;

  const [estoredata, setEstoreData] = useState(null);

  // Access cached estore data from the store using estoreApi's reducerPath
  const estoreDataFromCache = useSelector((state) =>
    estoreApi.endpoints.getEstoreInfo.select(id)(state)
  );

  // Fetch and cache data using RTK Query
  const { data: fetchedEstore } = useGetEstoreInfoQuery(id);

  // Memoize the data to set, avoiding unnecessary recalculations
  const dataToSet = useMemo(() => {
    return estoreDataFromCache?.data || fetchedEstore || null;
  }, [estoreDataFromCache, fetchedEstore]);

  // Update the state only when the incoming data differs from the current state
  useEffect(() => {
    if (dataToSet && JSON.stringify(dataToSet) !== JSON.stringify(estoredata)) {
      setEstoreData(dataToSet);
    }
  }, [dataToSet, estoredata]);

  // Format time to 12-hour format
  const formatTime12Hour = (time) => {
    return `${formatTimeToStandard(time)}`;
  };

  // Get the open hours for the current day
  const getTodayOpenHours = useCallback((estore) => {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const todayDayName = daysOfWeek[new Date().getDay()];

    const todaySchedule = estore?.openDaysAndHours?.openPeriodsByDay.find(
      (day) => day.dayOfWeek === todayDayName
    );

    if (todaySchedule && todaySchedule.openPeriods.length > 0) {
      return (
        <ul>
          {todaySchedule.openPeriods.map((period, index) => (
            <li key={index}>
              {`Open: ${formatTime12Hour(
                period?.openTime
              )} - ${formatTime12Hour(period?.closeTime)}`}
            </li>
          ))}
        </ul>
      );
    } else {
      return <div>Closed today</div>;
    }
  }, []);

  if (!dataToSet) {
    return <div>Loading...</div>;
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light custom-navbar">
      <ul className="navbar-nav d-flex flex-column">
        <img
          src={
            estoredata?.logo?.includes("https://")
              ? estoredata?.logo
              : `${imageUrl}${estoredata?.logo}`
          }
          alt="logo"
          className="estore-logo"
        />
        <div className="estore-name">{estoredata?.name}</div>
        <div className="open-hours">{getTodayOpenHours(estoredata)}</div>
        <li className="nav-item">
          <NavLink className="nav-link d-flex " to="/dashboard" end>
            <FaTachometerAlt className={"icon-link"} />
            <span className={"text-link text-lg md:hidden"}>Dashboard</span>
          </NavLink>
        </li>
        {/*  align-items-center */}
        <li className="nav-item">
          <NavLink className="nav-link d-flex" to="orders">
            <FaClipboardList className={"icon-link"} />
            <span className={"text-link"}>Orders</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link d-flex" to="product">
            <FaBox className={"icon-link"} />
            <span className={"text-link"}>Products</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link d-flex" to="users">
            <FaUsers className={"icon-link"} />
            <span className={"text-link"}>Users</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link d-flex" to={`settings`}>
            <FaCog className={"icon-link"} />
            <span className="text-link">Settings</span>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};
export default VerticalNavbar;
