import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetIngredientInfoQuery } from "../../../auth/services/ingredientService";
import { useGetCategoryBySubCategoryIdQuery } from "../../../auth/services/categoryService";
import "./index.css";
const IngredientDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Get the ingredient ID from the URL

  // Fetch existing ingredient details to populate the form
  const { data: ingredient, isLoading, error } = useGetIngredientInfoQuery(id);

  // Fetch category based on subCategoryId
  const { data: category, isLoading: categoryLoading } =
    useGetCategoryBySubCategoryIdQuery({
      id: parseInt(ingredient?.SubCategory?.subCategoryId || 0),
    });

  // Populate the form with fetched ingredient data
  useEffect(() => {
    if (ingredient) {
    }
  }, [ingredient, category]);

  if (isLoading || categoryLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading ingredient data</div>;

  return (
    <div className="edit-ingredient-container">
      <div className="form-group">
        <label>
          Ingredient Name: <strong>{ingredient?.name || ""}</strong>
        </label>
      </div>
      <div className="form-group">
        <label>
          Ingredient Description:<strong> {ingredient?.description}</strong>
        </label>
      </div>
      <div className="form-group">
        <label>
          Ingredient Subcategory:{" "}
          <strong>{ingredient?.SubCategory?.name || "Not available"}</strong>
        </label>
      </div>
      <div className="form-group">
        <label>
          Ingredient Category:{" "}
          <strong>{category?.name || "Not available"}</strong>
        </label>
      </div>
      <div className="form-group">
        <label>Ingredient Price: {ingredient?.price?.basePrice || 0}</label>
      </div>
      <button
        className="btn btn-primary"
        onClick={() =>
          navigate(
            `/dashboard/product/ingredientlist/edit/${ingredient.ingredientId}`,
            {
              state: { ...ingredient },
            }
          )
        }
      >
        Edit
      </button>
    </div>
  );
};

export default IngredientDetails;
