import React, { useState, useEffect, useRef } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; // Import slick styles
import "slick-carousel/slick/slick-theme.css"; // Import slick theme styles

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        background: "red",
        fontSize: "1rem",
        height: "20px",
        width: "30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        borderRadius: "35%",
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        fontSize: "1rem",
        height: "20px",
        width: "30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        borderRadius: "35%",
        background: "green",
      }}
      onClick={onClick}
    />
  );
}

const statusList = [
  { key: "received", label: "Received", icon: "fas fa-envelope", index: 1 },
  { key: "seen", label: "Seen", icon: "fas fa-eye", index: 2 },
  { key: "confirmed", label: "Clocked", icon: "fas fa-clock", index: 3 },
  { key: "queued", label: "Queued", icon: "fas fa-stream", index: 4 },
  { key: "processing", label: "Processing", icon: "fas fa-utensils", index: 5 },
  { key: "packed", label: "Packed", icon: "fas fa-box", index: 6 },
  {
    key: "readyForPickup",
    label: "Ready for Pickup",
    icon: "fas fa-truck",
    index: 7,
  },
];

const OrderStatusProgression = ({ estoreOrder, orderItem, onStatusUpdate }) => {
  const sliderRef = useRef(null);

  const [focusedIndex, setFocusedIndex] = useState(0);
  const [statuses, setStatuses] = useState(
    statusList.reduce((acc, status) => ({ ...acc, [status.key]: false }), {})
  );
  useEffect(() => {
    const findFirstNullIndex = () => {
      for (let i = 0; i < statusList.length; i++) {
        if (estoreOrder?.status?.[statusList[i].key] === null) {
          return i; // Return the index of the first null status
        }
      }
      return 0; // Default to 0 if none are null
    };

    const firstNullIndex = findFirstNullIndex();
    console.log("First null status at index:", firstNullIndex);
    setFocusedIndex(firstNullIndex);
  }, [estoreOrder]);
  useEffect(() => {
    if (sliderRef.current && focusedIndex !== null) {
      sliderRef.current.slickGoTo(focusedIndex);
      sliderRef.current.slickGoTo(focusedIndex);
    }
  }, [focusedIndex, sliderRef]); // React when the slider ref changes
  useEffect(() => {
    const updateStatuses = (source) => {
      if (source && source.status && source.status.status?.statusType) {
        // Create a copy of the statuses state
        const updatedStatuses = { ...statuses };
        let firstNullIndex = null;
        // Iterate through statusList to update status state
        statusList.forEach((status, index) => {
          const value = source.status[status.key];
          updatedStatuses[status.key] = value !== null;

          if (firstNullIndex === null && value === null) {
            firstNullIndex = index; // Set the first occurrence of null
          }
        });

        // Process statusType
        const typeKey = source.status.status.statusType.toLowerCase();
        if (statusList.some((status) => status.key === typeKey)) {
          updatedStatuses[typeKey] = true;
        }

        // Iterate through the keys of the status object and update the statuses state
        Object.keys(source.status).forEach((statusKey) => {
          if (source.status[statusKey]) {
            updatedStatuses[statusKey] = true; // Mark as true if a timestamp exists
          }
        });

        // Only update the state if the new statuses are different from the current ones
        setStatuses((prevStatuses) => {
          if (
            JSON.stringify(prevStatuses) !== JSON.stringify(updatedStatuses)
          ) {
            return updatedStatuses;
          }
          return prevStatuses; // No change, return previous state
        });
        if (firstNullIndex !== null) {
          setFocusedIndex(firstNullIndex); // Set focus to the first null status
        }
      }
    };

    // Update statuses for estoreOrder
    if (estoreOrder) {
      updateStatuses(estoreOrder);
    }

    // Update statuses for orderItem
    if (orderItem) {
      updateStatuses(orderItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderItem, estoreOrder]);
  const handleStatusChange = (key) => {
    const updatedStatuses = { ...statuses };
    let shouldUpdateBackend = false;
    let found = false;

    // Toggle the status and update preceding items accordingly
    for (const status of statusList) {
      if (!found) {
        updatedStatuses[status.key] =
          key === status.key ? !statuses[key] : updatedStatuses[status.key];
      } else {
        updatedStatuses[status.key] = false; // Uncheck items after the current one
      }

      if (status.key === key) {
        found = true;
        shouldUpdateBackend = true;
      }
    }
    // Check all statuses up to the clicked one
    for (const status of statusList) {
      if (!found) {
        updatedStatuses[status.key] = true;
      }
      if (status.key === key) {
        found = true;
        shouldUpdateBackend = true;
      }
    }

    setStatuses(updatedStatuses);

    // Notify the backend if the status changed
    if (shouldUpdateBackend && onStatusUpdate) {
      const selectedStatuses = Object.keys(updatedStatuses).filter(
        (key) => updatedStatuses[key]
      );
      onStatusUpdate(selectedStatuses);
    }
  };
  const settings = {
    infinite: false,
    slidesToShow: 1, // Show 3 icons at a time
    slidesToScroll: 1, // Scroll one icon at a time
    arrows: false, // Show arrows for navigation
    dots: false, // Disable dots navigation
    responsive: [
      {
        breakpoint: 768, // For smaller screens
        settings: {
          slidesToShow: 2, // Show 2 icons at a time on small screens
        },
      },
      {
        breakpoint: 480, // For very small screens
        settings: {
          slidesToShow: 1, // Show 1 icon at a time on extra small screens
        },
      },
    ],
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    afterChange: (current) => setFocusedIndex(current), // Update the focused index when slider changes
  };

  return (
    <Slider
      {...settings}
      style={{
        height: "50px", // Set height of the slider item
        width: "50px", // Set width of the slider item
        alignItems: "center",
        justifyContent: "center",
        left: "8px",
      }}
      ref={sliderRef}
    >
      {statusList.map((status, index) => {
        /* // Determine if the current status should be magnified
        let magnification = 1;
        if (
          (index === 0 && !statuses[status.key]) || // First item not checked
          (index === statusList.length - 1 && !statuses[status.key]) // Last item not checked
        ) {
          magnification = 1.5; // Magnify first and last item if not checked
        } else if (
          index === 1 && // Magnify the middle (second) item when focused
          statuses[status.key]
        ) {
          magnification = 1.8; // Stronger magnification for the middle item
        } */
        const isFocused = index === focusedIndex;
        const magnification = isFocused ? 2.2 : 1; // Magnify focused item
        const isActive = statuses[status.key];
        return (
          <React.Fragment key={status.key}>
            <div
              onClick={() => handleStatusChange(status.key)}
              style={{
                display: "flex",
                marginTop: "15px",
                flexDirection: "column",
                alignItems: "center",
                cursor: "pointer",
                transform: "scale(1)", // Prevent scaling the container
                transition: "all 0.3s ease",
              }}
            >
              <i
                className={status.icon}
                style={{
                  fontSize: "22px",
                  color: isActive ? "#85c1e9" : "#839192", // Green if selected, gray otherwise
                  position: "relative",
                  transform: `scale(${magnification})`, // Apply magnification
                  transition: "transform 0.3s ease", // Smooth transition for magnification
                }}
              ></i>

              <label
                style={{
                  fontSize: "9px",
                  position: "absolute",
                  top: "50%",
                  color: statuses[status.key] ? "#9c640c" : "#17202a", // Match color with icon
                  zIndex: 2, // Ensure label is above the icon
                }}
              >
                {status.label}
              </label>
            </div>
            {/* {index < statusList.length - 1 && (
            <span style={{ fontSize: "11px", color: "#888" }}>→</span>
          )} */}
          </React.Fragment>
        );
      })}
    </Slider>
  );
};

export default OrderStatusProgression;
