import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./index.css"; // Import the CSS file
import { useCreateIngredientWithNameMutation } from "../../../auth/services/ingredientService"; // Adjust the path if needed
import { useNavigate } from "react-router-dom";

const SolidAddons = ({ solidAddons, setSolidAddons }) => {
  const navigate = useNavigate();
  const [newSolidAddon, setNewSolidAddon] = useState("");
  const [createIngredientWithName] = useCreateIngredientWithNameMutation();

  // Retrieve userId and estoreId from Redux store
  const estoreId = useSelector((state) => state.auth.userInfo.defaultEstore);

  // Handle adding a new solid addon
  const addSolidAddon = async (event) => {
    event.preventDefault();
    if (newSolidAddon.trim()) {
      const updatedSolidAddons = [
        ...solidAddons,
        { name: newSolidAddon.trim(), checked: true },
      ];
      setSolidAddons(updatedSolidAddons);

      try {
        await createIngredientWithName({
          name: newSolidAddon.trim(),
          subCategoryId: solidAddons[0]?.SubCategory?.subCategoryId, // Adjust if needed
          estoreId: estoreId,
        });
        setNewSolidAddon(""); // Clear the input field
      } catch (error) {
        console.error("Error adding ingredient:", error);
        setSolidAddons((prevCourses) =>
          prevCourses.filter((course) => course.name !== newSolidAddon.trim())
        );
      }
    }
  };

  // Handle checkbox change
  const handleCheckboxChange = (index) => {
    const updatedSolidAddons = solidAddons.map((addon, i) =>
      i === index ? { ...addon, checked: !addon.checked } : addon
    );
    setSolidAddons(updatedSolidAddons);
  };

  return (
    <div className="solid-toppings-container">
      <h3 className="solid-toppings-title">Solid Add-ons</h3>
      <ul className="solid-toppings-list">
        {solidAddons.map((addon, index) => (
          <li className="solid-toppings-list-item" key={index}>
            <input
              id={`solid-addon-checkbox-${index}`} // Unique id for each checkbox
              name={`solid-addon-checkbox-${index}`}
              type="checkbox"
              checked={addon.checked}
              onChange={(e) => {
                e.preventDefault();
                handleCheckboxChange(index);
              }}
            />
            <label htmlFor={`solid-addon-checkbox-${index}`}>
              {addon.name}
            </label>
          </li>
        ))}
      </ul>
      <div>
        <input
          id={`solid-addon-input`}
          name={`solid-addon-input`}
          type="text"
          className="solid-toppings-input"
          value={newSolidAddon}
          onChange={(e) => {
            e.preventDefault();
            setNewSolidAddon(e.target.value);
          }}
          placeholder="Add new solid addon"
        />
        {solidAddons?.length > 0 ? (
          <button
            className="solid-toppings-button"
            onClick={(e) => {
              e.preventDefault();
              addSolidAddon(e);
            }}
          >
            Add
          </button>
        ) : (
          <button
            className="course-food-button"
            onClick={(e) => navigate("/dashboard/product/addingredient")}
          >
            Add Content
          </button>
        )}
      </div>
    </div>
  );
};

export default SolidAddons;
