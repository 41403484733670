import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  resetPassword,
  clearState,
} from "../../redux/auth/passwordRecoverySlice";

const ResetPassword = ({ token }) => {
  const [newPassword, setNewPassword] = useState("");
  const dispatch = useDispatch();
  const { loading, success, error } = useSelector(
    (state) => state.passwordRecovery
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(resetPassword({ token, newPassword }));
  };

  return (
    <div>
      <h2>Reset Password</h2>
      {success && <p className="success">{success}</p>}
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          placeholder="Enter new password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
        />
        <button type="submit" disabled={loading}>
          {loading ? "Resetting..." : "Reset Password"}
        </button>
      </form>
    </div>
  );
};

export default ResetPassword;
