import React, { useState } from "react";
import { useCreateAllergyDetailsMutation } from "../../auth/services/allergyService";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";

const AddAllergy = () => {
  const [createAllergy, { isLoading, error }] =
    useCreateAllergyDetailsMutation();
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    scale: 0, // To store the concatenated min and max values
  });

  // Handle text input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle range slider input and update the scale field
  const handleSliderChange = (value) => {
    const concatenatedScale = parseInt(`${value[0]}${value[1]}`); // Combine min and max
    setFormData({ ...formData, scale: concatenatedScale });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createAllergy({ data: formData }).unwrap();
      //alert("Allergy created successfully!");
      setFormData({ name: "", description: "", scale: 0 });
    } catch (err) {
      console.error("Error creating allergy:", err);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Create New Allergy</h2>
      <input
        type="text"
        name="name"
        value={formData.name}
        onChange={handleChange}
        placeholder="Allergy Name"
        required
      />
      <input
        type="text"
        name="description"
        value={formData.description}
        onChange={handleChange}
        placeholder="Description"
        required
      />
      <RangeSlider
        name="scale"
        min={0}
        max={10}
        onInput={handleSliderChange} // Use custom handler for slider
      />
      <p>
        Selected Scale: {formData.scale.toString().substring(0, 1)} to
        {" " + formData.scale.toString().substring(1)
          ? formData.scale.toString().substring(1)
          : 10}
      </p>
      <p>0 : for mild and 10 for fatal condition</p>
      <button type="submit" disabled={isLoading}>
        {isLoading ? "Creating..." : "Create Allergy"}
      </button>
      {error && <p>Error: {error.message}</p>}
    </form>
  );
};

export default AddAllergy;
