import React from "react";
import "./index.css";
import { Link } from "react-router-dom";

const Product = () => {
  return (
    <div className="flex flex-wrap gap-x-3">
      <div className="flex-wrap">
        <Link to="addproduct" className="btn">
          Add Product
        </Link>
      </div>
      <div className="flex-wrap">
        <Link to="addfooditem" className="btn">
          Add Menu Item
        </Link>
      </div>
      <div className="flex-wrap">
        <Link to="addingredient" className="btn">
          Add Ingredient
        </Link>
      </div>
      <div className="flex-wrap">
        <Link to="ingredientlist" className="btn">
          View Ingredients
        </Link>
      </div>
      <div className="flex-wrap">
        <Link to="categories/new" className="btn">
          Add Category
        </Link>
      </div>
      <div className="flex-wrap">
        <Link to="categories" className="btn">
          Categories
        </Link>
      </div>
      <div className="flex-wrap">
        <Link to="categories/subcategories/new" className="btn">
          Add Sub Category
        </Link>
      </div>
      <div className="flex-wrap">
        {/* <Link to="categories/:categoryId/subcategories" className="btn">
        Sub Categories
      </Link> */}
        <Link to="events" className="btn">
          Special Events
        </Link>
      </div>
    </div>
  );
};

export default Product;
