import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { backendURL } from "../../components/utils/constants";
import { jwtDecode } from "jwt-decode";
import {
  deleteCookie,
  getCookie,
  setCookie,
  setCookieJson,
} from "../../utils/cookie";
import { persistor } from "../store";
// Async Thunks

// Async Thunk for fetching user details
export const getUserDetails = createAsyncThunk(
  "auth/getUserDetails",
  async (_, { getState, rejectWithValue }) => {
    const userToken = getState().auth.userToken;
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      const response = await axios.get(`${backendURL}/auth/user`, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);
// Async Thunk for fetching specific user info by ID
export const getUserInfo = createAsyncThunk(
  "auth/getUserInfo",
  async (id, { getState, rejectWithValue }) => {
    const userToken = getState().auth.userToken;
    //const userId = id || getState().auth.userInfo?.userId;
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      const { data } = await axios.get(
        /*  `${backendURL}/auth/user/${userId}`, */
        `${backendURL}/auth/user`,
        config
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);
// Async Thunk for fetching specific user info by ID
export const getCurrentUser = createAsyncThunk(
  "auth/getCurrentUser",
  async (_, { getState, rejectWithValue }) => {
    const userToken = getState().auth.userToken;
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      const { data } = await axios.get(`${backendURL}/auth/user`, config);
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async Thunk for updating user info
export const updateUserInfo = createAsyncThunk(
  "auth/updateUserInfo",
  async (userInfoRequest, { getState, rejectWithValue }) => {
    const userToken = getState().auth.userToken;
    const userId = getState().auth.userInfo?.userId;
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      const { data } = await axios.put(
        `${backendURL}/auth/user/${userId}/info`,
        userInfoRequest,
        config
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Update the API call to handle both user profile data and an optional file
export const updateUserProfile = createAsyncThunk(
  "auth/updateUserProfile",
  async ({ userProfileRequest, file }, { getState, rejectWithValue }) => {
    const userToken = getState().auth.userToken;

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          // "Content-Type": "multipart/form-data", // Required for sending form data
        },
      };

      // Create a FormData object to send both user profile data and the file
      const formData = new FormData();
      formData.append(
        "userRequest",
        new Blob([JSON.stringify(userProfileRequest)], {
          type: "application/json",
        })
      );

      if (file) {
        formData.append("file", file); // Add the file only if it exists
      }

      // Send the multipart form data to the backend
      const { data } = await axios.patch(
        `${backendURL}/auth/user`,
        formData,
        config
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async Thunk for setting default estore
export const setDefaultEstore = createAsyncThunk(
  "user/setDefaultEstore",
  async (estoreId, { getState, rejectWithValue }) => {
    const userToken = getState().auth.userToken;

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          // "Content-Type": "multipart/form-data", // Required for sending form data
        },
      };
      //    alert("here" + estoreId);

      const { data } = await axios.patch(
        `${backendURL}/auth/user/estore`,
        {
          estoreId,
        },
        config
      );

      return data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Initialize userToken from local storage
const tokens = getCookie("userToken") || null;
const userToken = JSON.parse(tokens)?.userTokens;

let decodedToken = null;
let defaultEstore = null;

if (userToken) {
  try {
    decodedToken = jwtDecode(userToken);
    defaultEstore = decodedToken.store || null; // Extract defaultEstore if it exists
  } catch (error) {
    console.error("Invalid token:", error);
  }
}

const initialState = {
  loading: false,
  userInfo: null,
  userToken,
  jwtToken: null,
  error: null,
  success: false,
  defaultEstore, // Add this to keep track of default estore
};

// User slice definition
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      deleteCookie("userTokens");
      deleteCookie("refreshToken");
      deleteCookie("userToken");
      state.loading = false;
      state.userInfo = null;
      state.userToken = null;
      state.jwtToken = null;
      state.error = null;
      persistor.purge();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateUserProfile.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.userInfo = action.payload;
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(getUserInfo.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.userInfo = action.payload;
      })
      .addCase(getUserInfo.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload;
      })
      //current user
      .addCase(getCurrentUser.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.userInfo = action.payload;
      })
      .addCase(getCurrentUser.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload;
      })
      // Handle successful setDefaultEstore and update userToken and userInfo
      .addCase(setDefaultEstore.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(setDefaultEstore.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.defaultEstore = action.payload.defaultEstore;
        state.userInfo = action.payload; // Update user info

        if (action.payload.jwtToken) {
          try {
            const decodedToken = jwtDecode(action.payload.jwtToken);
            state.defaultEstore = decodedToken.store || null;

            // Update Redux state tokens
            state.jwtToken = action.payload.jwtToken;
            state.userToken = action.payload.jwtToken;

            // Store tokens in cookies
            setCookie("userTokens", action.payload.jwtToken);
            setCookie("refreshToken", action.payload.refreshToken);
            setCookieJson("userToken", {
              userTokens: action.payload.jwtToken,
              refreshToken: action.payload.refreshToken,
            });
          } catch (error) {
            console.error("Error decoding token:", error);
          }
        }
      })
      .addCase(setDefaultEstore.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload;
      });
  },
});

// Export actions and reducer
export const { logout } = userSlice.actions;
export default userSlice.reducer;
