// estoreSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiClient from "../../utils/apiClient";
export const updateFoodItemLogo = createAsyncThunk(
  "auth/foodItemUpdateLogo",
  async ({ id, file }, { getState, rejectWithValue }) => {
    // Check if the file is present
    if (!file) {
      const errorMessage = "No file provided for uploading.";
      alert(errorMessage); // Optionally alert the user
      return rejectWithValue(errorMessage); // Return an error
    }
    try {
      // Create a FormData object to hold the image file
      const formData = new FormData();
      formData.append("file", file); // The "file" key should match the server-side expectation
      // Create the config with headers

      // Make the PATCH request to update the logo using apiClient
      const { data } = await apiClient.patch(
        `/foodItem/${id}/logo`, // API endpoint
        formData // Form data with file
      );
      return data;
    } catch (error) {
      const errorMessage =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      //alert(JSON.stringify(errorMessage) + " from foodItemSlice.js");
      return rejectWithValue(errorMessage);
    }
  }
);

const initialState = {
  isLoading: false,
  isSuccess: false,
  error: null,
};

const foodItemSlice = createSlice({
  name: "foodItem",
  initialState,
  reducers: {
    resetFoodItem: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {},
});

// Export actions and reducer
export const { resetFoodItem } = foodItemSlice.actions;
export default foodItemSlice.reducer;
