import React from "react";
import "./index.css";

const HoverText = ({ originalText, hoverText }) => {
  return (
    <label className="hover-text" data-hover={hoverText}>
      {originalText}
    </label>
  );
};

export default HoverText;
