import React, { useState } from "react";
import { useFetchUserOrdersQuery } from "../../../auth/services/userOrderService"; // Action to fetch orders
import "./index.css"; // Optional CSS for styling
import { getImageUrl } from "../../utils/constants";

const UserOrdersPage = () => {
  const { data: orders = [], isLoading, isError } = useFetchUserOrdersQuery(); // Fetch orders using RTK Query
  const [filter, setFilter] = useState("all"); // Default filter: show all orders

  // Filter orders based on status
  const filteredOrders = orders.filter((order) =>
    filter === "all"
      ? true
      : order.estoreOrders.some(
          (estoreOrder) =>
            estoreOrder.status.status.statusType.toLowerCase() ===
            filter.toLowerCase()
        )
  );

  return (
    <div className="past-orders-container">
      <h2>Past Orders</h2>

      {isLoading ? (
        <p>Loading orders...</p>
      ) : isError ? (
        <p className="error">Failed to load orders. Please try again later.</p>
      ) : (
        <>
          {/* <>
            <pre>{JSON.stringify(orders, null, 2)}</pre>
          </> */}
          <div className="filters">
            <label>Filter by Status:</label>
            <select value={filter} onChange={(e) => setFilter(e.target.value)}>
              <option value="all">All</option>
              <option value="received">Received</option>
              <option value="processing">Processing</option>
              <option value="completed">Completed</option>
              <option value="cancelled">Cancelled</option>
            </select>
          </div>

          {filteredOrders.length > 0 ? (
            <ul className="order-list">
              {filteredOrders.map((order) => (
                <li key={order?.clientOrderId} className="order-item">
                  <div className="order-header">
                    <h3>Client Order ID: {order.clientOrderId}</h3>
                  </div>

                  {order.estoreOrders.map((estoreOrder) => (
                    <div
                      key={estoreOrder?.estoreOrderId}
                      className="estore-order"
                    >
                      <h4>E-Store: {estoreOrder?.estore.name}</h4>
                      <img
                        src={getImageUrl(estoreOrder.estore.logo, "estore")}
                        alt={`${estoreOrder?.estore.name} logo`}
                        className="estore-logo"
                      />
                      {estoreOrder?.status?.status?.statusType && (
                        <p>
                          <strong>Status:</strong>{" "}
                          {estoreOrder?.status?.status?.statusType}
                        </p>
                      )}

                      <p>
                        <strong>Completion Date:</strong>{" "}
                        {new Date(
                          estoreOrder.completionDateTime
                        ).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          timeZone: "America/Chicago",
                          timeZoneName: "short",
                        })}
                      </p>

                      {/* Order Items */}
                      {estoreOrder.orderItems && (
                        <details>
                          <summary>View Items</summary>
                          <ul>
                            {estoreOrder.orderItems.map((item) => (
                              <li key={item.orderItemId}>
                                {item.name} (x{item.quantity}) - $
                                {item.unitPrice.toFixed(2)}
                                {" -> "}
                                {item?.status?.status?.statusType}
                              </li>
                            ))}
                          </ul>
                        </details>
                      )}
                    </div>
                  ))}
                </li>
              ))}
            </ul>
          ) : (
            <p>No orders found for the selected filter.</p>
          )}
        </>
      )}
    </div>
  );
};

export default UserOrdersPage;
