import React from "react";
//
import { specialEventURL } from "../../utils/constants";

import SpecialEventsFileUpload from "../../utils/SpecialEventsFileUpload";
const SpecialEventFormEdit = ({ form, onSubmit, setForm }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePriceChange = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({
      ...prev,
      price: {
        ...prev.price,
        [name]: value,
      },
    }));
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(form);
      }}
    >
      <SpecialEventsFileUpload id={form.id} />
      {form.image && (
        <img
          src={
            form.image.startsWith("https://")
              ? form.image
              : `${specialEventURL}${form.image}`
          }
          alt="Product"
          className="product-image"
        />
      )}
      <div className="form-group">
        <label>Name</label>
        <input
          type="text"
          className="form-control"
          value={form.name}
          name="name"
          onChange={handleInputChange}
          required
        />
      </div>
      <div className="form-group">
        <label>Description</label>
        <textarea
          className="form-control"
          value={form.description}
          name="description"
          onChange={handleInputChange}
          required
        />
      </div>
      <div className="form-group">
        <label>Date</label>
        <input
          type="date"
          className="form-control"
          value={form.eventDate}
          name="eventDate"
          onChange={handleInputChange}
          required
        />
      </div>
      <div className="form-group">
        <label>Start DateTime</label>
        <input
          type="datetime-local"
          className="form-control"
          value={form.startTime}
          name="startTime"
          onChange={handleInputChange}
          required
        />
      </div>
      <div className="form-group">
        <label>End DateTime</label>
        <input
          type="datetime-local"
          className="form-control"
          value={form.endTime}
          name="endTime"
          onChange={handleInputChange}
          required
        />
      </div>
      <div className="form-group">
        <label>Base Price</label>
        <input
          type="number"
          className="form-control"
          value={form.price.basePrice}
          name="basePrice"
          onChange={handlePriceChange}
          required
        />
      </div>
      <div className="form-group">
        <label>Discount</label>
        <input
          type="number"
          className="form-control"
          value={form.price.discount}
          name="discount"
          onChange={handlePriceChange}
        />
      </div>
      <div className="form-group">
        <label>Currency</label>
        <input
          type="text"
          className="form-control"
          value={form.price.currency}
          name="currency"
          onChange={handlePriceChange}
          required
        />
      </div>
      <div className="form-group">
        <label>Event Image URL</label>
        <input
          type="text"
          className="form-control"
          value={form.image}
          name="image"
          onChange={handleInputChange}
        />
      </div>
      <button type="submit" className="btn btn-primary">
        {form.id ? "Update Event" : "Add Event"}
      </button>
    </form>
  );
};

export default SpecialEventFormEdit;
