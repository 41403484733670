export const createCartItem = ({
  quantity = 1,
  unitPrice = 0,
  specialMessage = "",
  uniqueId = "",
  customizations = "",
  foodItemId = null,
  productId = null,
} = {}) => ({
  quantity,
  unitPrice,
  specialMessage,
  uniqueId,
  customizations,
  foodItemId,
  productId,
});

export const normalizeCartItem = (foodItem) => {
  return createCartItem({
    name: foodItem?.name,
    quantity: foodItem.quantity || 1,
    unitPrice:
      (foodItem.price?.basePrice || 0) - (foodItem.price?.discount || 0),
    specialMessage: foodItem.speacialMessage || "",
    uniqueId: foodItem?.uniqueId || null,
    customizations: foodItem?.customizations || null,
    foodItemId: foodItem?.foodItemId || null,
    productId: foodItem?.productId || null,
  });
};

/* const addToCartWithValidation = (cartType, item, dispatch) => {
  const normalizedItem = normalizeFoodItemToCartItem(item);

  if (validateCartItem(normalizedItem)) {
    if (cartType === "product") {
      dispatch(addProductToCart(normalizedItem));
    } else if (cartType === "foodItem") {
      dispatch(addFoodItemToCart(normalizedItem));
    }
  } else {
    console.error("Invalid cart item", item);
  }
}; */

export const validateCartItem = (item) => {
  return (
    typeof item.quantity === "number" &&
    typeof item.unitPrice === "number" &&
    typeof item.specialMessage === "string" &&
    typeof item.uniqueId === "string" &&
    typeof item.customizations === "string" &&
    (item.foodItemId === null || typeof item.foodItemId === "number") &&
    (item.productId === null || typeof item.productId === "number")
  );
};
export const deepIterate = (item) => {
  if (item instanceof Map) {
    item.forEach((value, key) => {
      console.log(`${key}:`);
      deepIterate(value); // Recursively handle Map or nested structures
    });
  } else if (typeof item === "object" && item !== null) {
    for (let key in item) {
      console.log(`${key}:`);
      deepIterate(item[key]); // Recursively handle nested objects
    }
  } else {
    console.log(item); // Base case for primitive values
  }
};
export function generatePayload({
  updatedStatuses,
  currentTime,
  completionDateTime,
  orderItems,
}) {
  const payload = {};

  // Conditionally add status if updatedStatuses is provided
  if (updatedStatuses) {
    const status = {};
    updatedStatuses.forEach((statusKey) => {
      status[statusKey] = currentTime; // Add current timestamp for each status
    });
    payload.status = status;
  }

  // Conditionally add completionDateTime
  if (completionDateTime) {
    payload.completionDateTime = new Date(completionDateTime).toISOString();
  }

  // Conditionally add orderItems
  if (orderItems) {
    payload.orderItems = orderItems.map((orderItemId) => {
      // Assign the status from updatedStatuses to each orderItemId
      const status = {};
      updatedStatuses.forEach((statusKey) => {
        status[statusKey] = currentTime; // Add current timestamp for each status
      });

      return {
        orderItemId,
        status,
      };
    });
  }

  return payload;
}
export function generateOrderIemPayload({ updatedStatuses, currentTime }) {
  const payload = {};

  // Conditionally add status if updatedStatuses is provided
  if (updatedStatuses) {
    const status = {};
    updatedStatuses.forEach((statusKey) => {
      status[statusKey] = currentTime; // Add current timestamp for each status
    });
    payload.status = status;
  }

  return payload;
}
