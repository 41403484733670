import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getImageUrl } from "../../utils/constants";
import { fetchSpecialEvent } from "../../../auth/services/homeService";
const CartEvent = () => {
  const { id } = useParams();
  const [itemData, setItemData] = useState();
  const [error, setError] = useState();
  // Fetch Event item data using the provided ID

  useEffect(() => {
    fetchSpecialEvent(id)
      .then((response) => {
        setItemData(response?.data);
      })
      .catch((er) => setError(er.message));
  }, [id]);

  // Handle errors
  if (error) {
    return <p>Error loading Event item details{id}.</p>;
  }
  return (
    <div style={styles.container}>
      {/* <pre>{JSON.stringify(itemData, null, 2)}</pre> */}
      <h2>{itemData?.name}</h2>
      {itemData?.image && (
        <img
          src={getImageUrl(itemData?.image, "specialEvents")}
          alt={itemData?.name}
          style={styles.image}
        />
      )}

      <div style={styles.infoSection}>
        {itemData?.price?.basePrice > 0 && (
          <p>
            <strong>Price:</strong> $
            {itemData?.price?.basePrice > 0 && itemData?.price?.basePrice}{" "}
            {itemData?.price?.discount > 0 &&
              `Discount: $${itemData?.price?.discount}`}{" "}
            {itemData?.price?.currency}
          </p>
        )}
        <p>
          <strong>Description:</strong> {itemData?.description}
        </p>

        {/* Estore and User Info */}
        <div style={styles.additionalInfo}>
          <p>
            <strong>Estore:</strong> {itemData?.estore?.name}
          </p>
        </div>
      </div>
    </div>
  );
};

// Basic Styles
const styles = {
  container: {
    border: "1px solid #ddd",
    borderRadius: "8px",
    padding: "20px",
    width: "80vw",
    margin: "20px auto",
  },
  image: {
    width: "80%",
    height: "60%",
    objectFit: "fill",
    borderRadius: "8px",
    marginBottom: "15px",
  },
  infoSection: {
    fontSize: "16px",
    lineHeight: "1.6",
  },

  additionalInfo: {
    marginTop: "20px",
  },
};

export default CartEvent;
