import React from "react";
import { formatPhoneNumber } from "./formatPhoneNumber";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
const Address = ({ address, phoneNumber, email }) => {
  return (
    <div style={{ marginBottom: "16px" }}>
      <p style={{ margin: "4px 5px", fontSize: ".85rem" }}>
        <FontAwesomeIcon icon={faEnvelope} /> {email}
        <br />
        <FontAwesomeIcon icon={faPhone} /> {formatPhoneNumber(phoneNumber)}
        <br />
        <strong>
          {address?.street} <br />
          {address?.city}, {address?.state}, {address?.country}
          <br />
          {address?.zipCode}
          <br />
          {address?.email}
          <br />
        </strong>
      </p>
    </div>
  );
};

export default Address;
