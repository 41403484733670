import React, { useState, useEffect } from "react";
import { getAllFoodItemsAndProducts } from "../../../auth/services/homeService";
import { useLocation } from "react-router-dom";
import SpecialEvent from "../../components/SpecialEvent";
import ErrorDisplay from "../../components/ErrorDisplay";
const Events = () => {
  const location = useLocation();
  const { estoreId } = location.state.estore || {};
  // State for combined data and loading/error management
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [combinedData, setCombinedData] = useState({
    foodItems: [],
    products: [],
    specialEvents: [],
  });
  useEffect(() => {
    // Fetch data from the API
    getAllFoodItemsAndProducts(estoreId)
      .then((response) => {
        if (response.error) {
          setError(response.error);
        } else {
          //console.log("API Response:", response.data.foodItems.content[0].name); // Debug API response
          setCombinedData(response.data);
        }
      })
      .catch((err) => setError(err.message))
      .finally(() => setLoading(false));
  }, [estoreId]);
  if (!combinedData || combinedData?.specialEvents?.length === 0) {
    return <div>No Upcoming available.</div>;
  }
  /* if (!estoreId) {
    return (
      <div>Error: Store ID not found!{JSON.stringify(location.state)}</div>
    );http://localhost:8080/api/v1/home/special-items/102
  } */
  if (loading) return <div>Loading products...</div>;
  if (error) return <ErrorDisplay error={error} />;
  return (
    <>
      <div className="main">
        <p>Coming Events</p>
        <span className="product-container">
          {combinedData?.specialEvents?.content?.map((event, i) => (
            <SpecialEvent key={event.specialEventId + i} event={event} />
          ))}
        </span>
      </div>
    </>
  );
};
export default Events;
