import { FaShoppingCart, FaPlus, FaMinus } from "react-icons/fa";
import styled from "styled-components";

// IconWrapper with styled-components
const IconWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-bottom: 1.1rem;

  .cart-icon {
    font-size: 24px; /* Base size of the shopping cart icon */
    color: #787885;
    font-weight: 500;
    cursor: pointer; /* Make the cart icon clickable */
  }

  .overlay-icon {
    position: absolute;
    font-size: 12px; /* Smaller size for the overlay icon */
    color: #fff;
    background-color: #787885;
    border-radius: 50%;
    padding: 2px;
    top: 0;
    right: 0;
    cursor: pointer; /* Make the overlay icon clickable */
  }
`;

const ShoppingCartPlus = ({ onAdd }) => (
  <IconWrapper>
    <FaShoppingCart className="cart-icon" onClick={onAdd} />
    <FaPlus className="overlay-icon" onClick={onAdd} />
  </IconWrapper>
);

const ShoppingCartMinus = ({ onRemove }) => (
  <IconWrapper>
    <FaShoppingCart className="cart-icon" onClick={onRemove} />
    <FaMinus className="overlay-icon" onClick={onRemove} />
  </IconWrapper>
);

export { ShoppingCartPlus, ShoppingCartMinus };
