// src/components/FileUpload.js
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateEstoreLogo } from "../../redux/auth/estoreSlice";
import "./FileUpload.css";
const EstoreFileUpload = () => {
  const dispatch = useDispatch();
  const { defaultEstore } = useSelector((state) => state.auth.userInfo);
  const [file, setFile] = useState();
  const [imgUrl, setImgUrl] = useState();
  function handleFileChange(e) {
    console.log(e.target.files);
    setFile(e.target.files[0]);
    setImgUrl(URL.createObjectURL(e.target.files[0]));
  }
  const submitData = async () => {
    if (file) dispatch(updateEstoreLogo({ id: defaultEstore, file }));
  };

  return (
    <div className="file-upload-container w-full">
      <label>Logo</label>
      <input
        type="file"
        onChange={(e) => handleFileChange(e)}
        className={"w-50"}
      />
      <img src={imgUrl} alt="logo" />

      <button onClick={submitData}>Update Logo</button>
    </div>
  );
};

export default EstoreFileUpload;
