import React, { useState } from "react";
import "./index.css";
import {
  useFetchEstoreOrdersQuery,
  useUpdateEstoreOrderMutation,
  useUpdateOrderItemMutation,
} from "../../../auth/services/estoreOrderService";
import CompletionDateTimePicker from "../../utils/CompletionDateTime";
import { FaClock, FaSms } from "react-icons/fa";
import OrderStatusProgression from "../../utils/OrderStatusProgression";
import { deepIterate, generatePayload } from "../../utils/cartNormalization";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { isNullOrUndefined } from "@hookform/resolvers";
import Modal from "react-modal";
import { formatPhoneNumber } from "../../../pages/components/formatPhoneNumber";
const EstoreOrderList = () => {
  const [expandedOrderId, setExpandedOrderId] = useState(null);
  const [updateOrderItem] = useUpdateOrderItemMutation();
  const [completionDateTime, setCompletionDateTime] = useState(null);
  const [openModalId, setOpenModalId] = useState(null);
  const {
    data: estoreOrders,
    error,
    isLoading,
  } = useFetchEstoreOrdersQuery({ page: 0, size: 20 });
  const [updateEstoreOrder] = useUpdateEstoreOrderMutation();
  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading data.</div>;
  const openModal = (orderId) => {
    setOpenModalId(orderId); // Set the specific order ID for the modal
  };

  const closeModal = () => {
    setOpenModalId(null); // Close the modal
  };

  const handleSaveTime = ({ selectedTime, order }) => {
    setCompletionDateTime(selectedTime); // Set the formatted time string
    handleEstoreOrderStatusUpdate({
      estoreOrderId: order.estoreOrderId,
      completionDateTime: selectedTime,
    });

    closeModal(); // Close modal after saving
  };
  const toggleOrder = (orderId) => {
    setExpandedOrderId((prev) => (prev === orderId ? null : orderId));
  };
  const handleEstoreOrderStatusUpdate = async ({
    estoreOrderId,
    updatedStatuses,
    completionDateTime,
    orderItems = [],
  }) => {
    const currentTime = new Date().toISOString();
    //const completionDateTime = new Date().toISOString();
    //const orderItems = [];
    /* alert(
      JSON.stringify({
        updatedStatuses,
        currentTime,
        completionDateTime,
        orderItems,
      })
    ); */
    const dynamicPayload = generatePayload({
      updatedStatuses,
      currentTime,
      completionDateTime,
      orderItems,
    });

    console.log("Generated Payload:", dynamicPayload);
    //alert(JSON.stringify({ estoreOrderId, data: dynamicPayload }));
    // Example API call
    await updateEstoreOrder({ estoreOrderId, data: dynamicPayload })
      .unwrap()
      .then((response) => {
        console.log("Estore Order update response:", response);
      })
      .catch((error) => {
        console.error("Error updating Estore Order:", error);
      });
  };
  const handleOrderItemStatusUpdate = async ({
    orderItemId,
    updatedStatuses,
  }) => {
    /* 
    const currentTime = new Date().toISOString();
    const payload = generateOrderIemPayload({
      updatedStatuses,
      currentTime,
    });

    try {
      const response = await updateOrderItem({
        orderItemId: orderItemId,
        data: payload,
      }).unwrap();
      console.log("Order Item Update Successful:", response);
    } catch (error) {
      console.error("Error updating order item status:", error);
    } */
  };
  return (
    <div className="mx-auto p-4">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
        <div className="bg-white p-4 rounded-lg shadow-md">
          <h5 className="text-lg font-semibold">Orders Pending</h5>
          <p className="text-2xl font-bold">
            {estoreOrders?.pendingCount || 0}
          </p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-md">
          <h5 className="text-lg font-semibold">Orders Total</h5>
          <p className="text-2xl font-bold">{estoreOrders?.totalCount || 0}</p>
        </div>
      </div>

      <ul className="list-none divide-y w-full">
        {estoreOrders?.content?.map((order, index) => (
          <li
            key={order.estoreOrderId}
            className="p-4 rounded-lg shadow-md"
            style={{
              backgroundColor: index % 2 === 0 ? "white" : "grey",
              color: index % 2 === 0 ? "black" : "white",
            }}
          >
            <div className="flex justify-between items-center h-20">
              <div>
                <h3
                  className="text-lg font-bold"
                  style={{
                    backgroundColor: index % 2 === 0 ? "white" : "grey",
                    color: index % 2 === 0 ? "black" : "white",
                  }}
                >
                  Order #{order.estoreOrderId}
                </h3>
                <p
                  className="text-600"
                  style={{
                    backgroundColor: index % 2 === 0 ? "white" : "grey",
                    color: index % 2 === 0 ? "black" : "white",
                  }}
                >
                  {order.clientOrder?.name} -{" "}
                  {formatPhoneNumber(order?.clientOrder?.phone)}
                </p>
              </div>
              <div>
                <button
                  className="text-white-500 text-sm rounded"
                  onClick={() => toggleOrder(order.estoreOrderId)}
                >
                  {expandedOrderId === order.estoreOrderId
                    ? "Hide Items"
                    : "Show Items"}
                </button>
                <>
                  {!isNullOrUndefined(order?.completionDateTime) ? (
                    <OrderStatusProgression
                      onStatusUpdate={(updatedStatuses) => {
                        const orderItemIds = order.orderItems.map(
                          (item) => item?.orderItemId
                        );
                        handleEstoreOrderStatusUpdate({
                          estoreOrderId: order.estoreOrderId,
                          updatedStatuses,
                          orderItems: orderItemIds,
                        });
                      }}
                      estoreOrder={order}
                    />
                  ) : (
                    <>
                      <p
                        onClick={() => openModal(order.estoreOrderId)}
                        className="mt-2 ml-4"
                      >
                        {/* Pickup Time:{" "}
                        {new Date(
                          order?.completionDateTime != null
                        )?.toLocaleString()} */}
                        <FaClock />
                      </p>
                    </>
                  )}
                </>
              </div>
            </div>
            <Modal
              isOpen={openModalId === order.estoreOrderId}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel={`Order ${order.estoreOrderId}`}
            >
              <CompletionDateTimePicker
                onSave={({ selectedTime, order }) =>
                  handleSaveTime({
                    selectedTime,
                    order,
                  })
                }
                value={completionDateTime || ""}
                order={order} // Pass specific order to the modal
                onClose={closeModal}
              />
              {/* <button onClick={closeModal}>Close</button> */}
            </Modal>
            {expandedOrderId === order.estoreOrderId && (
              <div
                className={`mt-4 border-t pt-4 overflow-hidden transition-all duration-300 ${
                  expandedOrderId === order.estoreOrderId
                    ? "max-h-screen"
                    : "max-h-0"
                }`}
              >
                {order.orderItems.map((item) => (
                  <div
                    key={item.orderItemId}
                    className="p-2 border rounded-md mb-2"
                  >
                    <h4 className="font-semibold">
                      {item.name} (x{item.quantity})
                    </h4>
                    {/* <OrderStatusProgression
                      onStatusUpdate={(updatedStatuses) => {
                        handleOrderItemStatusUpdate({
                          orderItemId: item.orderItemId,
                          updatedStatuses,
                          orderItem: item,
                        });
                      }}
                      orderItem={item}
                    /> */}
                    <p className="text-600">Unit Price: ${item.unitPrice}</p>
                    {item?.specialMessage && (
                      <p className="text-600">
                        Note: {item?.specialMessage || "None"}
                      </p>
                    )}

                    {item?.customization && (
                      <p className="text-600">
                        Customization:{" "}
                        {deepIterate(item?.customization) || "None"}
                      </p>
                    )}
                  </div>
                ))}

                <p
                  className="mt-2 text-sm flex flex-wrap gap-2"
                  onClick={() => openModal(order.estoreOrderId)}
                >
                  Pickup: {new Date(order.completionDateTime).toLocaleString()}{" "}
                  <FaClock className="text-xl" />
                </p>
                <p className="mt-2 text-sm">
                  <FaSms className="text-3xl" />
                </p>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default EstoreOrderList;
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    border: "1px solid #ccc",
  },
};
