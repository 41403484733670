const bs = "https://api.horelo.com";
//const bs = "http://localhost:8080";
export const backendURL = `${bs}/api/v1/public`;
export const API_BASE_URL = `${bs}/api/v1/home`;

export const baseURL = `${bs}/api/v1/public/auth`;
export const foodItemURL = "https://horeloimgs.s3.amazonaws.com/food-item/";
export const estoreURL = "https://horeloimgs.s3.amazonaws.com/estore/";
export const productURL = "https://horeloimgs.s3.amazonaws.com/product/";
export const specialEventURL =
  "https://horeloimgs.s3.amazonaws.com/specialEvents/";

export const getImageUrl = (image, type) =>
  image?.startsWith("https://")
    ? image
    : image?.startsWith("http://")
    ? image
    : image?.startsWith("www")
    ? image
    : `https://horeloimgs.s3.amazonaws.com/${type}/${image}`;
