import { FaHome } from "react-icons/fa";
const HomeUtil = () => {
  return (
    <div style={styles.container}>
      <FaHome style={styles.icon} />
    </div>
  );
};

export default HomeUtil;

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative", // Ensure that child elements can be positioned relative to this container
  },
  icon: {
    fontSize: "25px", // Size of the shopping cart icon
    color: "gray", // Color of the icon
  },
};
