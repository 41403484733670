import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./index.css"; // Import the CSS file
import { useCreateIngredientWithNameMutation } from "../../../auth/services/ingredientService"; // Adjust the path if needed

const SpiceLevels = ({ spiceLevels, setSpiceLevels }) => {
  const [newSpiceLevel, setNewSpiceLevel] = useState("");
  const [createIngredientWithName] = useCreateIngredientWithNameMutation();

  // Retrieve userId and estoreId from Redux store
  const estoreId = useSelector((state) => state.auth.userInfo.defaultEstore);

  // Handle adding a new spice level
  const addSpiceLevel = async () => {
    if (newSpiceLevel.trim()) {
      // Optimistically add the new spice level to the list
      const updatedSpiceLevels = [
        ...spiceLevels,
        { name: newSpiceLevel.trim(), checked: true },
      ];
      setSpiceLevels(updatedSpiceLevels); // Immediately update state

      try {
        // Add to backend
        await createIngredientWithName({
          name: newSpiceLevel.trim(),
          subCategoryId: spiceLevels[0]?.SubCategory?.subCategoryId, // Adjust if needed
          estoreId: estoreId,
        }).unwrap();

        setNewSpiceLevel(""); // Clear the input field
      } catch (error) {
        console.error("Error adding spice level:", error);
        setSpiceLevels((prevCourses) =>
          prevCourses.filter((course) => course.name !== newSpiceLevel.trim())
        );
        // Optionally handle failure case (e.g., show an error message)
      }
    }
  };

  // Handle checkbox change
  const handleCheckboxChange = (index) => {
    const updatedSpiceLevels = spiceLevels.map((level, i) =>
      i === index ? { ...level, checked: !level.checked } : level
    );
    setSpiceLevels(updatedSpiceLevels);
  };

  return (
    <div className="spice-levels-container">
      <h3 className="spice-levels-title">Spice Levels</h3>
      <ul className="spice-levels-list">
        {spiceLevels?.map((level, index) => (
          <li className="spice-levels-list-item" key={index}>
            <input
              id={`spice-checkbox-${index}`} // Unique id for each checkbox
              name={`spice-checkbox-${index}`}
              type="checkbox"
              checked={level.checked}
              onChange={(e) => {
                e.preventDefault();
                handleCheckboxChange(index);
              }}
            />
            <label htmlFor={`spice-checkbox-${index}`}>{level.name}</label>
          </li>
        ))}
      </ul>
      <div>
        <input
          id={`spice-checkbox`}
          name={`spice-checkbox`}
          type="text"
          className="spice-levels-input"
          value={newSpiceLevel}
          onChange={(e) => {
            e.preventDefault();
            setNewSpiceLevel(e.target.value);
          }}
          placeholder="Add new spice level"
        />
        <button
          className="spice-levels-button"
          onClick={(e) => {
            e.preventDefault();
            addSpiceLevel(); // Add spice level
          }}
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default SpiceLevels;
