import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../../components/utils/constants";
import { decodeToken } from "../../utils/decodeToken";
import { getCookie } from "../../utils/cookie";
//http://localhost:8080/api/v1/public/auth/estore/user?estoreId=103
//http://localhost:8080/api/v1/public/auth/estore/user?estoreId=103
const tokens = getCookie("userToken") || null;
const storedToken = JSON.parse(tokens)?.userTokens;
let defaultEstore = decodeToken(storedToken)?.store;
//
export const estoreUserApi = createApi({
  reducerPath: "estoreUserApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState()?.auth?.userToken;
      if (token) {
        defaultEstore = decodeToken(token)?.store || defaultEstore;

        headers.set("authorization", `Bearer ${token}`);
      }
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  tagTypes: ["EstoreUser"],
  endpoints: (builder) => ({
    getAllEstoreUsers: builder.query({
      query: (_) => {
        return `/estore/user/estore/${defaultEstore}`;
      },
      providesTags: ["EstoreUser"],
    }),
    getAllRoles: builder.query({
      query: () => {
        return `/estore/user/role`;
      },
      providesTags: ["EstoreUser"],
    }),
    //http://localhost:8080/api/v1/public/auth/estore/user/
    updateEstoreUserRole: builder.mutation({
      query: ({ email, roleId }) => {
        return {
          url: `/estore/user/`,
          method: "PATCH",
          body: {
            roleId,
            estoreId: defaultEstore,
            email,
          },
        };
      },
    }),

    deleteEstoreUserRole: builder.mutation({
      query: ({ email }) => {
        let estoreId = defaultEstore;
        return {
          url: `/estore/user/${email}/${estoreId}`,
          method: "DELETE",
        };
      },
    }),

    getUserRole: builder.query({
      query: ({ userId }) => {
        return `/estore/user/${userId}/${defaultEstore}`;
      },
    }),

    getAllRolesForEstore: builder.query({
      query: () => {
        return `/estore/user/${defaultEstore}`;
      },
      //providesTags: ["EstoreUser"],
    }),

    createEstoreUser: builder.mutation({
      queryFn: async (
        { data },
        { dispatch, getState },
        extraOptions,
        baseQuery
      ) => {
        const result = await baseQuery({
          url: `/estore/user/assign-role`,
          method: "POST",
          body: { ...data, estoreId: defaultEstore },
        });
        return result.error ? { error: result.error } : { data: result.data };
      },
      invalidatesTags: ["EstoreUser"],
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useGetAllEstoreUsersQuery,
  useUpdateEstoreUserRoleMutation,
  useDeleteEstoreUserRoleMutation,
  useGetUserRoleQuery,
  useGetAllRolesForEstoreQuery,
  useCreateEstoreUserMutation,
  useGetAllRolesQuery,
} = estoreUserApi;
