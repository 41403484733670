import React, { useState, useEffect } from "react";
import { getImageUrl } from "../utils/constants";

const MyLogo = ({ storeLogoUrl }) => {
  const [imgUrl, setImgUrl] = useState("");
  useEffect(() => {
    if (storeLogoUrl?.length > 0) {
      setImgUrl(storeLogoUrl);
    }
  }, [storeLogoUrl]);
  return (
    <div>
      {storeLogoUrl !== undefined &&
      storeLogoUrl !== "" &&
      storeLogoUrl != null &&
      storeLogoUrl?.length > 0 ? (
        <img
          src={getImageUrl(imgUrl, "estore")}
          alt="store-logo"
          style={{ width: "40px", height: "40px", objectFit: "contain" }}
        />
      ) : (
        <img
          src="/horelo.jpg"
          alt="logo"
          style={{ width: "40px", height: "40px", objectFit: "contain" }}
        />
      )}
    </div>
  );
};
export default MyLogo;
