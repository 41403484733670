import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCreateIngredientDetailsMutation } from "../../../auth/services/ingredientService"; // Adjust the path if needed
import { useGetSubCategoriesQuery } from "../../../auth/services/subcategoryService"; // Adjust the path if needed
import { useGetCategoryByNameQuery } from "../../../auth/services/categoryService"; // Adjust the path if needed
import "./index.css";

const AddIngredient = () => {
  const navigate = useNavigate();
  const estoreId = useSelector((state) => state.auth.userInfo.defaultEstore);

  const name = "Ingredients"; // Category name for "Ingredients"

  // Fetch the "Ingredients" category directly on page load
  const { data: category, isLoading: loadingCategories } =
    useGetCategoryByNameQuery({ name, estoreId }, { skip: !estoreId });

  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [ingredientData, setIngredientData] = useState({
    name: "",
    description: "", // Optional field now
    category: "",
    subCategoryId: null,
    price: {
      basePrice: 0,
      discount: 0.0,
      currency: "USD",
    },
    estoreId,
  });

  // Fetch subcategories based on the "Ingredients" category
  const { data: subcategories = [], isLoading: loadingSubcategories } =
    useGetSubCategoriesQuery(
      { categoryId: category ? category.categoryId : null },
      { skip: !category }
    );

  const [createIngredientDetails] = useCreateIngredientDetailsMutation();

  // Set the category and subcategories once the category data is fetched
  useEffect(() => {
    if (category && category.categoryId) {
      setSelectedCategoryId(category.categoryId);
      setIngredientData((prevData) => ({
        ...prevData,
        category: category.categoryId, // Set the category in ingredient data
      }));
    }
  }, [category]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "basePrice") {
      setIngredientData((prevData) => ({
        ...prevData,
        price: { ...prevData?.price, basePrice: parseFloat(value) || 0 },
      }));
    } else if (name === "subCategoryId") {
      setIngredientData((prevData) => ({
        ...prevData,
        subCategoryId: parseInt(value),
      }));
    } else {
      setIngredientData((prevData) => ({ ...prevData, [name]: value || "" }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Submit the ingredient data
      await createIngredientDetails(ingredientData).unwrap();
      navigate("/dashboard/product/ingredientlist"); // Redirect after adding
    } catch (error) {
      console.error("Error adding ingredient:", error);
    }
  };

  const handleBackClick = () => {
    navigate("/dashboard/product");
  };

  return (
    <div className="add-ingredient-container">
      <button className="btn btn-secondary mb-3" onClick={handleBackClick}>
        Back to Dashboard
      </button>

      <form onSubmit={handleSubmit}>
        {loadingCategories ? (
          <p>Loading category...</p>
        ) : (
          <div className="form-group">
            <label htmlFor="ingredientSubcategory">
              Ingredient Subcategory
            </label>
            <select
              id="ingredientSubcategory"
              className="form-control"
              name="subCategoryId"
              value={ingredientData.subCategoryId || ""}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Sub Category</option>
              {loadingSubcategories ? (
                <option disabled>Loading...</option>
              ) : (
                subcategories.map((subcat) => (
                  <option
                    key={subcat.subCategoryId}
                    value={subcat.subCategoryId}
                  >
                    {subcat.name}
                  </option>
                ))
              )}
            </select>
          </div>
        )}

        <div className="form-group">
          <label>Ingredient Name</label>
          <input
            type="text"
            className="form-control"
            name="name"
            value={ingredientData.name}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label>Ingredient Description (Optional)</label>
          <textarea
            className="form-control"
            rows="3"
            name="description"
            value={ingredientData.description}
            onChange={handleInputChange}
          ></textarea>
        </div>

        <div className="form-group">
          <label>Ingredient Price</label>
          <input
            type="number"
            className="form-control"
            name="basePrice"
            value={ingredientData.price.basePrice}
            onChange={handleInputChange}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Add Ingredient
        </button>
      </form>
    </div>
  );
};

export default AddIngredient;
