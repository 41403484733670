import { useState, useRef, useEffect } from "react";
import { formatRoles } from "../../../components/utils/formatRoles";

const RoleSelect = ({ roles, onRoleSelect, selectedRole }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredRole, setHoveredRole] = useState(null);
  const [displayRole, setDisplayRole] = useState("");

  const dropdownRef = useRef(null);

  // Update displayRole when selectedRole changes
  useEffect(() => {
    if (selectedRole) {
      setDisplayRole(formatRoles(selectedRole.roleName));
    } else {
      setDisplayRole("Select Role");
    }
  }, [selectedRole]);

  const handleSelect = (role) => {
    setDisplayRole(formatRoles(role.roleName));
    onRoleSelect(role);
    setIsOpen(false); // Close dropdown after selection
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div
      ref={dropdownRef}
      style={{ position: "relative", display: "inline-block", width: "200px" }}
    >
      {/* Selected option trigger */}
      <div
        style={{
          padding: "8px",
          border: "1px solid #ccc",
          borderRadius: "4px",
          cursor: "pointer",
          backgroundColor: "#fff",
          width: "100%",
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        {displayRole}
      </div>

      {/* Dropdown menu */}
      {isOpen && (
        <ul
          style={{
            position: "absolute",
            listStyle: "none",
            padding: "0",
            margin: "5px 0 0",
            border: "1px solid #ccc",
            borderRadius: "4px",
            backgroundColor: "#fff",
            width: "100%",
            boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
            zIndex: 1000,
          }}
        >
          {roles?.map((rol) => (
            <li
              key={rol.roleId}
              onMouseEnter={() => setHoveredRole(rol)}
              onMouseLeave={() => setHoveredRole(null)}
              onClick={() => handleSelect(rol)}
              style={{
                padding: "8px",
                cursor: "pointer",
                backgroundColor: hoveredRole === rol ? "#f0f0f0" : "#fff",
              }}
            >
              {formatRoles(rol.roleName)}
            </li>
          ))}
        </ul>
      )}

      {/* Tooltip for role description */}
      {hoveredRole && (
        <div
          style={{
            position: "absolute",
            left: "210px",
            top: "50%",
            color: "skyBlue",
            transform: "translateY(-50%)",
            backgroundColor: "#fff",
            padding: "8px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
            whiteSpace: "wrap",
            zIndex: 1000,
          }}
        >
          {hoveredRole.description}
        </div>
      )}
    </div>
  );
};

export default RoleSelect;
