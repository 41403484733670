import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Error from "../components/Error";
import Spinner from "../components/Spinner";
import { useForm } from "react-hook-form";
import { userLogin } from "../redux/auth/authActions";
import { useEffect, useMemo, useCallback } from "react";
import { jwtDecode } from "jwt-decode"; // Import jwt-decode for decoding JWT
import { getUserInfo } from "../redux/auth/userSlice";
import { useGetUserDetailsQuery } from "../auth/services/authService";
import { setCredentials } from "../redux/auth/authSlice";
export default function Login() {
  const { loading, userToken, userInfo, error } = useSelector(
    (state) => state.auth
  );
  const { register, handleSubmit, reset } = useForm();
  const { data, isFetching } = useGetUserDetailsQuery(undefined, {
    skip: !userToken,
    // perform a refetch every 15mins
    pollingInterval: 900000,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Memoize the decoded token to avoid recalculating it unnecessarily
  const decodedToken = useMemo(() => {
    if (userToken) {
      try {
        return jwtDecode(userToken);
      } catch (error) {
        console.error("Error decoding token", error);
        return null;
      }
    }
    return null;
  }, [userToken]);

  // Memoize the navigation logic to prevent function recreation
  const handleNavigation = useCallback(() => {
    if (decodedToken) {
      if (decodedToken.store) {
        try {
          dispatch(getUserInfo(decodedToken.id));
          if (userInfo) {
            navigate("/dashboard"); // Navigate to the dashboard if `store` exists
          }
        } catch {}
      } else {
        navigate("/"); // Navigate to home if `store` doesn't exist
      }
    } else {
      navigate("/login"); // Redirect to login if the token is invalid
    }
  }, [decodedToken, navigate, userInfo, dispatch]);

  useEffect(() => {
    reset(); // Reset form on mount
    handleNavigation(); // Handle navigation based on token
  }, [reset, handleNavigation]);
  useEffect(() => {
    if (data) dispatch(setCredentials(data));
  }, [data, dispatch]);
  const onSubmit = (data) => {
    dispatch(userLogin(data));
  };
  if (isFetching || (!userInfo && userToken)) return <div>Loading...</div>;

  return (
    <div className="w-full max-w-xs my-10">
      <form
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        {error && <Error>{error}</Error>}
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="email"
          >
            Email
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="email"
            id="email"
            type="email"
            placeholder="Email"
            {...register("email")}
          />
        </div>
        <div className="mb-6">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="password"
          >
            Password
          </label>
          <input
            className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            name="password"
            id="password"
            type="password"
            placeholder="******************"
            {...register("password")}
          />
          <p className="text-red-500 text-xs italic">
            Please enter a password.
          </p>
        </div>
        <div className="flex items-center justify-between">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
            disabled={loading}
          >
            {loading ? <Spinner /> : "Login"}
          </button>
          <a
            href="/forgot-password"
            className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
          >
            Forgot Password?
          </a>
        </div>
      </form>
      <button
        onClick={() => navigate("/signup")}
        className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-12 rounded focus:outline-none focus:shadow-outline"
      >
        Sign up
      </button>
    </div>
  );
}
