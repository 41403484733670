// Helper function to format time
export const formatTimeToStandard = (time) => {
  const [hours, minutes] = time.split(":").map(Number);
  const suffix = hours >= 12 ? "PM" : "AM";
  const standardHours = hours % 12 || 12; // Convert 0 to 12 for midnight
  return `${standardHours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")} ${suffix}`;
};
//src\components\utils\formatTimeToHHMM.js
