import React, { useEffect, useState } from "react";
import "./OpenDaysAndHoursEditor.css";

const DAYS_OF_WEEK = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const AvailableDaysAndHoursEditor = ({
  availableDaysAndHours,
  onUpdate,
  canAddPeriod = true,
}) => {
  const [daysAndHours, setDaysAndHours] = useState(
    DAYS_OF_WEEK.map((day, index) => ({
      id: 352 + index, // Generate initial IDs starting from 352
      dayOfWeek: day,
      openPeriods: [],
    }))
  );

  useEffect(() => {
    if (availableDaysAndHours?.length) {
      // Sync with parent-provided data
      setDaysAndHours(availableDaysAndHours);
    }
  }, [availableDaysAndHours]);

  const handleChange = (dayIndex, periodIndex, field, value) => {
    const updatedDaysAndHours = daysAndHours.map((day, i) => {
      if (i === dayIndex) {
        const updatedPeriods = day.openPeriods.map((period, j) =>
          j === periodIndex ? { ...period, [field]: value } : period
        );
        return { ...day, openPeriods: updatedPeriods };
      }
      return day;
    });
    setDaysAndHours(updatedDaysAndHours);
    onUpdate(updatedDaysAndHours); // Notify parent of updates
  };

  const handleToggleIsOpen = (dayIndex, periodIndex) => {
    const updatedDaysAndHours = daysAndHours.map((day, i) => {
      if (i === dayIndex) {
        const updatedPeriods = day.openPeriods.map((period, j) =>
          j === periodIndex ? { ...period, open: !period.open } : period
        );
        return { ...day, openPeriods: updatedPeriods };
      }
      return day;
    });
    setDaysAndHours(updatedDaysAndHours);
    onUpdate(updatedDaysAndHours); // Notify parent of updates
  };

  const handleAddPeriod = (dayIndex) => {
    const updatedDaysAndHours = daysAndHours.map((day, i) => {
      if (i === dayIndex) {
        return {
          ...day,
          openPeriods: [
            ...day.openPeriods,
            { id: null, openTime: "", closeTime: "", open: false },
          ],
        };
      }
      return day;
    });
    setDaysAndHours(updatedDaysAndHours);
    onUpdate(updatedDaysAndHours); // Notify parent of updates
  };

  return (
    <div>
      <h2>Edit Available Days and Hours</h2>
      <div className="days-container">
        {daysAndHours.map((day, dayIndex) => (
          <div key={day.id} className="day-card  w-full">
            <h3>{day.dayOfWeek}</h3>
            {day.openPeriods.map((period, periodIndex) => (
              <div key={periodIndex} className="period-inputs  w-full">
                <label>
                  Open Time:
                  <input
                    type="time"
                    value={period.openTime}
                    onChange={(e) =>
                      handleChange(
                        dayIndex,
                        periodIndex,
                        "openTime",
                        e.target.value
                      )
                    }
                  />
                </label>
                <label>
                  Close Time:
                  <input
                    type="time"
                    value={period.closeTime}
                    onChange={(e) =>
                      handleChange(
                        dayIndex,
                        periodIndex,
                        "closeTime",
                        e.target.value
                      )
                    }
                  />
                </label>
                <label>
                  Open:
                  <input
                    type="checkbox"
                    checked={period.open}
                    onChange={() => handleToggleIsOpen(dayIndex, periodIndex)}
                  />
                </label>
              </div>
            ))}
            {canAddPeriod && (
              <button
                type="button"
                onClick={() => handleAddPeriod(dayIndex)}
                className="rounded"
              >
                Add Period
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AvailableDaysAndHoursEditor;
