import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { baseURL } from "../components/utils/constants";
import { setCookie } from "./cookie";

export const refreshAccessToken = async (refreshToken) => {
  try {
    const response = await axios.post(`${baseURL}/refresh-token`, {
      refreshToken,
    });
    setCookie("userTokens", response.data.accessToken);
    return response.data.accessToken;
  } catch (error) {
    console.error("Error refreshing token:", error);
    throw error;
  }
};
export const isTokenExpired = (token) => {
  try {
    const decoded = jwtDecode(token);
    //alert(decoded);
    if (!decoded) return true;

    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    return decoded.exp < currentTime; // Check if expired
  } catch (error) {
    return true; // Invalid token
  }
};

export const decodeToken = (token) => {
  try {
    return jwtDecode(token);
  } catch (error) {
    return null; // Invalid token
  }
};
// Method to extract the "store" value from the token
export const getStoreFromToken = (token) => {
  try {
    const decoded = jwtDecode(token);
    return decoded?.store || null; // Safely retrieve the "store" field
  } catch (error) {
    return null; // Invalid token or "store" field not present
  }
};
export const decodeTokenStore = (token) => {
  try {
    return jwtDecode(token)?.store;
  } catch (error) {
    return null; // Invalid token
  }
};
