import React, { useState, useEffect, useCallback } from "react";
import {
  getEstoreInfo,
  getEstoreOpenStatus,
} from "../../auth/services/homeService"; // Assuming you have a product API service
import { useDispatch, useSelector } from "react-redux";
import { addToProductCart } from "../../redux/auth/productCartSlice"; // Import cart actions
import { addToFoodItemCart } from "../../redux/auth/foodItemCartSlice"; // Import cart actions
import "./index.css";
import FoodItem from "../components/FoodItem";
import Product from "../components/Product";
import SpecialEvent from "../components/SpecialEvent";
import ErrorDisplay from "../components/ErrorDisplay";
import { useLocation /*  useNavigate */ } from "react-router-dom";
import OpenDaysHours from "../components/OpenDaysHours";
import Address from "../components/Address";
import { getImageUrl } from "../../components/utils/constants";
import StatusIndicator from "../components/StatusIndicator";
import {
  validateCartItem,
  createCartItem,
} from "../../components/utils/cartNormalization";
import SearchFilter from "../../components/utils/SearchFilter";
import { useLazyGetAllFoodItemsAndProductsQuery } from "../../auth/services/homeRTKService";

const EstoreFrontPage = () => {
  const location = useLocation();
  const { estoreId } = location.state.estore || {}; // Retrieve estoreId from navigation state
  //const navigate = useNavigate();
  const dispatch = useDispatch();
  const { foodItemCart } = useSelector((state) => state.foodItemCart);
  const { productCart } = useSelector((state) => state.productCart);
  // State for combined data and loading/error management
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [estoreOpenStatus, setEstoreOpenStatus] = useState();
  const [estoreInfo, setEstoreInfo] = useState();
  const [filterOptions, setFilterOptions] = useState({
    searchText: "",
    categoryId: undefined,
    subcategoryId: undefined,
    estoreId: estoreId || undefined, // Optional estoreId
  });

  const [fetchAllItems, { data }] = useLazyGetAllFoodItemsAndProductsQuery({
    estoreId,
  });

  // Fetch products when filters change
  const fetchProducts = useCallback(() => {
    fetchAllItems(filterOptions);
    //console.log(JSON.stringify(data));
  }, [filterOptions, fetchAllItems]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const [isSidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    const fetchStoreStatus = async () => {
      try {
        const status = await getEstoreOpenStatus(estoreId);
        setEstoreOpenStatus(status);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    fetchStoreStatus();
    getEstoreInfo(estoreId).then((response) => {
      if (response.error) {
        setError(response.error);
      } else {
        setEstoreInfo(response.data);
      }
    });
  }, [estoreId]);
  // Helper function to check if the product is already in the cart
  const isItemInFoodItemCart = (productId) => {
    return foodItemCart.some((cartItem) => cartItem.foodItemId === productId);
  };
  // Helper function to check if the product is already in the cart
  const isItemInProductCart = (productId) => {
    return productCart.some((cartItem) => cartItem.productId === productId);
  };
  // Add product foodItem to cart handler
  const handleAddToFoodItemCart = (product) => {
    const normalizedItem = createCartItem(product);
    if (validateCartItem(normalizedItem)) {
      dispatch(addToFoodItemCart(product)); // Dispatch the action to add product to cart
    } else alert.error("not supported");
  };
  // Add product to cart handler
  const handleAddToProductCart = (product) => {
    const normalizedItem = createCartItem(product);
    if (validateCartItem(normalizedItem)) {
      dispatch(addToProductCart(product)); // Dispatch the action to add product to cart
    } else alert.error("not supported");
  };

  if (!estoreId) {
    return <div>Error: Store ID not found!</div>;
  }
  if (loading) return <div>Loading products...</div>;
  if (error) return <ErrorDisplay error={error} />;

  return (
    <>
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
      <div className="w-full">
        <div className="header">
          <img
            src={`${getImageUrl(estoreInfo?.logo, "estore")}`}
            /* src={"/horelo.jpg"} */
            alt="logo"
            className="logo"
          />
          <StatusIndicator
            isOpen={`${estoreOpenStatus?.isOpen}`}
            currentOpenTime={`${estoreOpenStatus?.currentOpenTime}`}
            currentCloseTime={`${estoreOpenStatus?.currentCloseTime}`}
          />
          <div className="header-content">
            <p>{estoreInfo?.note}</p>

            <h1>
              <span style={{ color: "#ffff" }}>
                {estoreInfo?.name.charAt(0).toUpperCase() +
                  estoreInfo?.name.slice(1)}
              </span>
            </h1>
          </div>
        </div>

        <div className="sticky-header right">
          {/* Sidebar Toggle Button */}
          <button className="menu-button" onClick={() => setSidebarOpen(true)}>
            ☰ Menu
          </button>

          {/* <a href="#">Home</a>
          <a href="#" className="right">
            About
          </a>
          <a href="#">Contact</a> */}
        </div>
        <div className="row">
          <div className="side">
            <div className="">
              {/* <button
                className="nav-link d-flex"
                onClick={() =>
                  navigate(`events`, {
                    state: { estore: estoreId },
                  })
                }
              >
                
                <span className={"text-link"}>Events</span>
              </button> */}
              <OpenDaysHours
                openDaysAndHours={estoreInfo?.openDaysAndHours}
                estoreId={estoreId}
              />
              <div>
                <h6>
                  <strong>Contact</strong>
                </h6>
                <Address
                  address={estoreInfo?.primaryAddress}
                  phoneNumber={estoreInfo?.phoneNumber}
                  email={estoreInfo?.email}
                />
              </div>
            </div>
          </div>
          {/* Sidebar as a Modal */}
          {isSidebarOpen && (
            <div className="side sidebar-modal">
              <div className="modal-content">
                <button
                  className="close-button w-50"
                  onClick={() => setSidebarOpen(false)}
                >
                  ✖
                </button>
                <OpenDaysHours
                  openDaysAndHours={estoreInfo?.openDaysAndHours}
                />
                <div>
                  <h6>
                    <strong>Contact</strong>
                  </h6>
                  <Address
                    address={estoreInfo?.primaryAddress}
                    phoneNumber={estoreInfo?.phoneNumber}
                    email={estoreInfo?.email}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="main">
            <SearchFilter
              onFilterChange={setFilterOptions}
              estoreId={estoreId}
            />

            <span className="product-container">
              {data?.foodItems?.map((foodItem, i) => (
                <FoodItem
                  key={foodItem.foodItemId + i}
                  foodItem={foodItem}
                  handleAddToFoodItemCart={handleAddToFoodItemCart}
                  isItemInFoodItemCart={isItemInFoodItemCart}
                />
              ))}
              {data?.products?.map((product, i) => (
                <Product
                  key={product.productId + i}
                  product={product}
                  handleAddToProductCart={handleAddToProductCart}
                  isItemInProductCart={isItemInProductCart}
                />
              ))}
              {data?.specialEvents?.map((event, i) => (
                <SpecialEvent key={event.specialEventId + i} event={event} />
              ))}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default EstoreFrontPage;
