import React, { useState } from "react";
import "./index.css";

const DefaultIngredients = ({
  selectedDefaultIngredients,
  setSelectedDefaultIngredients,
  suggestions = [],
}) => {
  const [newIngredient, setNewIngredient] = useState("");
  const [filteredIngredients, setFilteredIngredients] = useState([]);

  // Normalize ingredient to always return the name as a string
  const normalizeIngredient = (ingredient) =>
    typeof ingredient === "string"
      ? ingredient.trim()
      : ingredient?.name?.trim();

  const handleSearchChange = (e) => {
    const value = e.target.value.trim();
    setNewIngredient(value);

    if (value.length > 0) {
      const filtered = suggestions.filter(
        (ingredient) =>
          normalizeIngredient(ingredient)
            ?.toLowerCase()
            .includes(value.toLowerCase()) &&
          !selectedDefaultIngredients.some(
            (existingIngredient) =>
              normalizeIngredient(existingIngredient)?.toLowerCase() ===
              normalizeIngredient(ingredient)?.toLowerCase()
          )
      );
      setFilteredIngredients(filtered);
    } else {
      setFilteredIngredients([]);
    }
  };

  const handleAddIngredient = (ingredient) => {
    const ingredientName = normalizeIngredient(ingredient);

    if (
      selectedDefaultIngredients.some(
        (existingIngredient) =>
          normalizeIngredient(existingIngredient)?.toLowerCase() ===
          ingredientName.toLowerCase()
      )
    ) {
      return; // Prevent duplicates
    }

    setSelectedDefaultIngredients((prev) => [
      ...prev,
      { name: ingredientName },
    ]);
    setNewIngredient("");
    setFilteredIngredients([]);
  };

  const handleRemoveIngredient = (ingredientName) => {
    setSelectedDefaultIngredients((prevSelected) =>
      prevSelected.filter(
        (ingredient) =>
          normalizeIngredient(ingredient)?.toLowerCase() !==
          ingredientName.toLowerCase()
      )
    );
  };

  return (
    <div className="form-group w-full">
      <label htmlFor="input-default-ingredients">Default Serving</label>
      <div className="d-flex">
        <input
          id="input-default-ingredients"
          name="input-default-ingredients"
          type="text"
          className="form-control w-full"
          value={newIngredient}
          onChange={handleSearchChange}
          placeholder="Search or add a new ingredient"
        />
      </div>

      {newIngredient.length > 0 && filteredIngredients.length === 0 && (
        <div>No results found</div>
      )}

      {newIngredient.length > 0 && filteredIngredients.length > 0 && (
        <ul className="list-group mt-2">
          {filteredIngredients.map((ingredient, index) => (
            <li
              key={index}
              className="list-group-item"
              onClick={() => handleAddIngredient(ingredient)}
            >
              {normalizeIngredient(ingredient)}
            </li>
          ))}
        </ul>
      )}

      <div className="selected-ingredients mt-2">
        <h5>Listed Items</h5>
        {selectedDefaultIngredients.map((ingredient, index) => (
          <label
            key={index}
            className="badge badge-primary mr-2"
            onClick={(e) => {
              e.preventDefault();
              handleRemoveIngredient(normalizeIngredient(ingredient));
            }}
          >
            {normalizeIngredient(ingredient)} &times;
          </label>
        ))}
      </div>
    </div>
  );
};

export default DefaultIngredients;
