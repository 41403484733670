import React, { useState, useEffect } from "react";
import { debounce } from "lodash";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import "./index.css";
import { API_BASE_URL, getImageUrl } from "../constants";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

function SearchBar() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    if (searchTerm.trim() === "") {
      setSuggestions([]);
    } else {
      fetchSuggestions(searchTerm);
    }
  }, [searchTerm]);

  const fetchSuggestions = async (searchTerm) => {
    try {
      // Replace 'YOUR_API_ENDPOINT' with the actual endpoint of your API

      const url = `${API_BASE_URL}/search/estore?name=${searchTerm}`;
      const response = await axios.get(url);
      //alert(JSON.stringify(response));

      setSuggestions(response?.data?.content); // Assuming the API returns an array of suggestions
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };
  // Debounce the fetchSuggestions function
  const debouncedFetchSuggestions = debounce(fetchSuggestions, 300);
  const handleOnSearch = (string) => {
    setSearchTerm(string);
    //fetchSuggestions(string);
    debouncedFetchSuggestions(string);
  };

  const handleOnSelect = (item) => {
    navigate(`/store/${item?.name}`, {
      state: { estore: item },
    });
  };

  const handleOnHover = (item) => {
    console.log("Hovered:", item);
  };

  const handleOnFocus = () => {
    console.log("The search input is focused");
  };

  const handleOnClear = () => {
    console.log("The search input is cleared");
    setSuggestions([]);
  };
  const formatResult = (item) => {
    return (
      <>
        <Link
          className="flex flex-row"
          to={{
            pathname: `/store/${item?.name}`,
            state: { estore: item },
          }}
        >
          <span
            style={{
              display: "block",
              textAlign: "left",
              width: "30px",
              height: "30px",
            }}
          >
            <img
              src={getImageUrl(item?.logo, "estore")}
              alt="logo"
              style={{ objectFit: "cover", maxWidth: "100%", height: "auto" }}
            />
          </span>
          <span style={{ display: "block", textAlign: "left" }}>
            {item.name}
          </span>
        </Link>
      </>
    );
  };
  return (
    <div className={"search-bar-container"}>
      <ReactSearchAutocomplete
        items={suggestions}
        inputDebounce={300}
        onSearch={handleOnSearch}
        onSelect={handleOnSelect}
        onHover={handleOnHover}
        onFocus={handleOnFocus}
        onClear={handleOnClear}
        formatResult={formatResult}
        placeholder="Type to search"
      />
    </div>
  );
}

export default SearchBar;
