import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { backendURL } from "../../components/utils/constants";
import { getCookie, setCookie, setCookieJson } from "../../utils/cookie";

export const registerUser = createAsyncThunk(
  "auth/register",
  async ({ username, email, password }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      await axios.post(
        `${backendURL}/auth/register`,
        { username, email, password },
        config
      );
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const userLogin = createAsyncThunk(
  "auth/login",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        `${backendURL}/auth/login`,
        { email, password },
        config
      );

      setCookie("userTokens", data.jwtToken);
      setCookie("refreshToken", data.refreshToken);
      setCookieJson("userToken", {
        userTokens: data.jwtToken,
        refreshToken: data.refreshToken,
      });
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const profileUser = createAsyncThunk(
  "auth/user",
  async ({ rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.get(`${backendURL}/auth/user`, config);
      return data;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const updateUser = createAsyncThunk(
  "auth/user/update",
  async ({ rejectWithValue }) => {
    try {
      const userToken = getCookie("userToken")?.userTokens;
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(userToken)}`,
        },
      };
      const { data } = await axios.post(`${backendURL}/auth/user`, config);
      return data;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const verifyEmail = createAsyncThunk(
  "auth/verifyEmail",
  async ({ token }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.get(
        `${backendURL}/auth/verify-email?token=${token}`,
        config
      );
      return data; // Email verification successful response
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data); // Handle backend error message
      }
      return rejectWithValue(error.message); // Handle network or other errors
    }
  }
);
export const emailExists = createAsyncThunk(
  "auth/emailExists",
  async (email, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${backendURL}/auth/check-email?email=${email}`
      );
      return data; // Email verification successful response
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data); // Handle backend error message
      }
      return rejectWithValue(error.message); // Handle network or other errors
    }
  }
);
