import React from "react";
import { useNavigate } from "react-router-dom";
import "./index.css"; // Custom styles if needed
import { getImageUrl } from "../../components/utils/constants";

const SpecialEvent = ({ event }) => {
  const navigate = useNavigate();

  return (
    <div key={event?.id} className="product-card">
      {event?.image && (
        <img
          style={{ width: "100vw", height: "80vh" }}
          onClick={() => navigate(`/cart-event/${event?.id}`)}
          src={getImageUrl(event?.image, "specialEvents")}
          alt="special event"
        />
      )}
      <p className="event-info">Description: {event?.description}</p>
      <div className="price-and-cart">
        {event?.price?.basePrice > 0 && <p>${event?.price?.basePrice || 0}</p>}
      </div>
      {event?.estore?.logo && (
        <div
          className="estore-info"
          onClick={() =>
            navigate(`/store/${event?.estore?.name}`, {
              state: { estore: event?.estore },
            })
          }
        >
          <img
            src={getImageUrl(event?.estore?.logo, "estore")}
            alt="logo"
            className="small-image"
          />
          <div className="estore-name">{event?.estore?.name}</div>
        </div>
      )}
    </div>
  );
};

export default SpecialEvent;
