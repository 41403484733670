import React, { useState } from "react";
import "./index.css"; // Import the CSS file
import { useCreateIngredientWithNameMutation } from "../../../auth/services/ingredientService";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
//

const FoodCourse = ({ foodCourses, setFoodCourses }) => {
  const navigate = useNavigate();
  const [newCourse, setNewCourse] = useState("");
  const estoreId = useSelector((state) => state.auth.userInfo.defaultEstore);
  const [createIngredientWithName] = useCreateIngredientWithNameMutation();

  // Handle adding a new course
  const addCourse = async () => {
    if (newCourse.trim()) {
      const newFoodCourse = { name: newCourse.trim(), checked: true };

      setFoodCourses((prevCourses) => {
        const updated = [...prevCourses, newFoodCourse];
        //console.log("Updated courses:", updated);
        return updated;
      });

      try {
        await createIngredientWithName({
          name: newCourse.trim(),
          subCategoryId: foodCourses[0]?.SubCategory?.subCategoryId,
          estoreId: estoreId,
        }).unwrap();
        setNewCourse("");
      } catch (error) {
        console.error("Error adding ingredient:", error);
        setFoodCourses((prevCourses) =>
          prevCourses.filter((course) => course.name !== newCourse.trim())
        );
      }
    }
  };

  return (
    <div className="course-food-container">
      <h3 className="course-food-title">Course Type</h3>
      <ul className="course-food-list">
        {foodCourses.map((course, index) => (
          <div key={course.name} className="food-course-option">
            <input
              id={`course-checkbox-${index}`}
              type="checkbox"
              checked={course.checked}
              onChange={() =>
                setFoodCourses((prevCourses) =>
                  prevCourses.map((c, i) =>
                    i === index ? { ...c, checked: !c.checked } : c
                  )
                )
              }
              className="food-course-checkbox" // Optional: Add a class for styling
            />
            <label
              htmlFor={`course-checkbox-${index}`}
              className="food-course-label"
            >
              {course.name}
            </label>
          </div>
        ))}
        {/* {foodCourses.map((course, index) => (
          <li className="course-food-list-item" key={index}>
            <input
              id={`course-checkbox-${index}`} // Unique id for each checkbox
              name={`course-checkbox-${index}`}
              type="checkbox"
              checked={course.checked}
              onChange={() => handleCheckboxChange(index)}
            />
            <label htmlFor={`course-checkbox-${index}`}>{course.name}</label>
          </li>
        ))} */}
      </ul>
      <div>
        <input
          id={`course-checkbox}`} // Unique id for each checkbox
          name={`course-checkbox`}
          type="text"
          className="course-food-input w-full"
          value={newCourse}
          onChange={(e) => setNewCourse(e.target.value)}
          placeholder="Add new course"
        />
        {foodCourses?.length > 0 ? (
          <button
            className="course-food-button"
            onClick={(e) => {
              e.preventDefault();
              addCourse();
            }}
          >
            Add
          </button>
        ) : (
          <button
            className="course-food-button"
            onClick={(e) => navigate("/dashboard/product/addingredient")}
          >
            Add Content
          </button>
        )}
      </div>
    </div>
  );
};

export default FoodCourse;
