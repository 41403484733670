import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  useGetAllCategoriesQuery,
  useDeleteCategoryMutation,
} from "../../../auth/services/categoryService"; // Update path accordingly

const Category = () => {
  const { data: categories, error, isLoading } = useGetAllCategoriesQuery();
  const [deleteCategory, { isLoading: isDeleting, error: deleteError }] =
    useDeleteCategoryMutation();
  const navigate = useNavigate();

  useEffect(() => {
    // Any additional logic you want to perform on mount, like fetching data
  }, []);

  const handleDelete = async (id) => {
    try {
      await deleteCategory(id).unwrap();
    } catch (err) {
      console.error("Failed to delete category:", err);
    }
  };

  if (isLoading) return <p>Loading...</p>;

  return (
    <div>
      <h1>Category Management</h1>
      <button onClick={() => navigate("new")}>Add New Category</button>
      {error && (
        <p style={{ color: "red" }}>
          {error.message || "Error fetching categories"}
        </p>
      )}
      {deleteError && (
        <p style={{ color: "red" }}>
          {deleteError.message || "Error deleting category"}
        </p>
      )}
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {categories?.map(
            (category) =>
              category.name.toLowerCase() !== "ingredients" && (
                <>
                  <tr key={category.categoryId}>
                    <td>{category.categoryId}</td>
                    <td>{category.name}</td>
                    <td className="action-column">
                      {category.name.toLowerCase() !== "ingredients" && (
                        <>
                          <button
                            onClick={() =>
                              navigate(`${category.categoryId}/subcategories`)
                            }
                          >
                            View
                          </button>

                          <button
                            onClick={() => navigate(`${category.categoryId}`)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                      <button
                        onClick={() => handleDelete(category.categoryId)}
                        disabled={isDeleting}
                      >
                        {isDeleting ? "Deleting..." : "Delete"}
                      </button>
                    </td>
                  </tr>
                </>
              )
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Category;
