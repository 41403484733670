import React, { useState, useEffect } from "react";
import {
  useCreateEstoreUserMutation,
  useUpdateEstoreUserRoleMutation,
  useDeleteEstoreUserRoleMutation,
  useGetAllEstoreUsersQuery,
  useGetAllRolesQuery,
} from "../../../auth/services/estoreUserService";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import RoleSelect from "../../../pages/components/RoleSelect";
import { formatRoles } from "../../utils/formatRoles";
import { emailExists } from "../../../redux/auth/authActions";
const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
const EstoreUser = () => {
  const { data: users, error, isLoading } = useGetAllEstoreUsersQuery();
  const { data: roles } = useGetAllRolesQuery();
  const { emailStatus } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [createEstoreUser] = useCreateEstoreUserMutation();
  const [updateEstoreUserRole] = useUpdateEstoreUserRoleMutation();
  const [deleteEstoreUserRole] = useDeleteEstoreUserRoleMutation();
  const [emailError, setEmailError] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    setEmailError("");
  }, []);
  const onEdit = (user) => {
    setSelectedUser(user);
    setEmail(user.email);
    setRole(roles?.find((r) => r.roleName === user.roleName) || null);
  };
  const onRemove = async (e) => {
    e.preventDefault();
    if (!email) return alert("User is required!");
    try {
      if (selectedUser) {
        // Update existing user role
        await deleteEstoreUserRole({
          email: selectedUser?.email,
        }).unwrap();
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };
  const handleEmailCheck = async (email) => {
    if (email) {
      try {
        await dispatch(emailExists(email)).unwrap();
        // Check for specific error if email exists
      } catch (err) {
        console.error("Error checking email:", err);
        if (err.message) {
          setEmailError(
            "This email is already registered. Please use another."
          );
        } else {
          setEmailError("An unexpected error occurred. Please try again.");
        }
      }
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email || !role) return setEmailError("Email and role are required!");
    if (email && !emailRegex.test(email)) {
      setEmailError("Invalid email format.");
      return;
    }
    try {
      if (selectedUser) {
        // Update existing user role
        await updateEstoreUserRole({
          email: email,
          roleId: role.roleId,
        });
      } else {
        // Add new user
        await handleEmailCheck(email);
        if (emailStatus)
          await createEstoreUser({ data: { email, roleId: role.roleId } });
        else {
          setEmailError("This email does not exist");
        }
      }
      setEmail("");
      setRole(null);
      setSelectedUser(null);
    } catch (err) {
      console.error("Error:", err);
    }
  };

  if (isLoading) return <p>Loading users...</p>;
  if (error) return <p>Error loading users</p>;

  return (
    <div>
      <h2>Users</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {users?.map((user, idx) => (
          <div
            key={user?.id || idx * user?.id}
            className="bg-white shadow-md rounded-lg p-4 border border-gray-200"
          >
            <h3 className="text-lg font-semibold text-gray-800">
              {user.name || "N/A"}
            </h3>

            <p className="text-gray-600">
              <span className="font-medium">Email:</span> {user?.email}
            </p>
            <p className="text-gray-600">
              <span className="font-medium">Phone:</span> {user?.phone || "N/A"}
            </p>
            <div className="flex items-center space-x-4">
              <p className="text-gray-700 bg-gray-100 px-3 py-1 rounded-md flex-1">
                <span className="font-semibold">Role:</span>{" "}
                {formatRoles(user?.roleName)}
              </p>
              <div
                onClick={() => onEdit(user)}
                className="cursor-pointer text-blue-500 hover:text-blue-700"
              >
                <FontAwesomeIcon icon={faEdit} className="w-5 h-5" />
              </div>
            </div>
          </div>
        ))}
      </div>

      <h4>{selectedUser ? "Edit User Role" : "Add New User"}</h4>
      <form onSubmit={handleSubmit}>
        <input
          style={{ width: "40vw", height: "30px" }}
          type="email"
          placeholder="User Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={!!selectedUser} // Disable email input in edit mode
        />
        {/* Display the email error */}
        {emailError && (
          <p className="text-red-500 text-xs italic">{emailError}</p>
        )}
        <RoleSelect roles={roles} onRoleSelect={setRole} selectedRole={role} />
        <div className="flex space-x-3 mt-4">
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-600 transition-all"
          >
            {selectedUser ? "Update Role" : "Add User"}
          </button>

          {selectedUser && (
            <button
              type="button"
              onClick={(e) => onRemove(e)}
              className="bg-red-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-red-600 transition-all"
            >
              Remove
            </button>
          )}

          {selectedUser && (
            <button
              type="button"
              onClick={() => {
                setSelectedUser(null);
                setEmail("");
                setRole(null);
              }}
              className="bg-gray-400 text-white px-4 py-2 rounded-lg shadow-md hover:bg-gray-500 transition-all"
            >
              Cancel
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default EstoreUser;
