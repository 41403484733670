import React from "react";
import "./StatusIndicator.css";
import { getTimeToClose } from "../getTimeToClose";

const StatusIndicator = ({ isOpen, currentOpenTime, currentCloseTime }) => {
  return (
    <div className="status-indicator">
      <div
        className={`status-circle ${isOpen !== "false" ? "open" : "closed"}`}
        title={isOpen !== "false" ? `Open` : "Closed"}
      ></div>
      <p className="status-text">
        {isOpen !== "false"
          ? `Open ${getTimeToClose(currentOpenTime, currentCloseTime)}`
          : "Closed"}
      </p>
    </div>
  );
};

export default StatusIndicator;
